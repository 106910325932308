import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { getModel } from "../Infrastructure/Vet.reducer";
import { UpdateVetInfo, GetVetInfo } from "../Infrastructure/Vet.presenter";
import FlatButton from "../../DesignSystem/FlatButton";
import BasicForm from "../../DesignSystem/BasicForm";
import BasicCard from "../../DesignSystem/BasicCard.ui";
import InputTextOutlined from "../../DesignSystem/InputTextOutlined";
import { validateName, validatePhone } from "../../User/Domain/User.model";
import { uploadPhoto } from "../../Provider.new";
import img from "../../assets/paw_finger_green.svg";
import greenCamera from "../../assets/green_camera.svg";
import Alert from "../../DesignSystem/BasicModal";
import { MAX_IMAGE_SIZE, MAX_IMAGE_SIZE_TEXT } from "../../constants";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    width: "100%",
    [theme.breakpoints.up("lg")]: {
      flexDirection: "row",
      width: "250%",
    },
  },
  containerPhoto: {
    marginTop: "20px",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    alignItems: "center",
  },
  iconButton: {
    width: "60px",
    height: "60px",
    position: "absolute",
    bottom: 5,
    left: 60,
    top: 180,
    border: "none",
    background: "none",
    borderRadius: "50%",
    opacity: 0.8,
    "&:hover": {
      opacity: 1,
    },
    "&:active": {
      opacity: 0.9,
      transform: "scale(0.95)",
    },
  },
  vetPhoto: {
    height: "220px",
    width: "220px",
    objectFit: "cover",
    marginLeft: "50px",
    marginRight: "50px",
    marginBottom: "35px",
    borderRadius: "100% 50% 100% 50%",
  },
}));

export default function VetUpdateUI() {
  const classes = useStyles();
  const [values, setValues] = useState({
    address: "",
    license: "",
    name: "",
    phone: "",
    email: "",
    profilePicture: "",
  });

  const [errors, setErrors] = useState({
    name: { error: false, helper: "" },
    phone: { error: false, helper: "" },
  });
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [photo, setPhoto] = useState(null);
  const [photoPreview, setPhotoPreview] = useState(img);
  const [clicked, setClicked] = useState(false);
  const history = useHistory();
  const vet = useSelector(getModel);

  function titleCase(cadena) {
    const regExpr =
      /(^|[^A-Za-zÁÉÍÓÚÜÑáéíóúüñ])(?:([a-záéíóúüñ])|([A-ZÁÉÍÓÚÜÑ]))|([A-ZÁÉÍÓÚÜÑ]+)/gu;
    return cadena.replace(
      regExpr,
      (m, caracterPrevio, minuscInicial, mayuscInicial, mayuscIntermedias) => {
        const locale = ["es", "gl", "ca", "pt", "en"];
        if (mayuscIntermedias) {
          return mayuscIntermedias.toLocaleLowerCase(locale);
        }
        return (
          caracterPrevio +
          (minuscInicial
            ? minuscInicial.toLocaleUpperCase(locale)
            : mayuscInicial)
        );
      }
    );
  }

  const [alert, setAlert] = useState({
    open: false,
  });
  function closeAlert() {
    setAlert({ ...alert, open: false });
  }
  function showAlert(text, title = "Ocurrió un error", type = "error") {
    setAlert({
      title,
      text,
      open: true,
      type,
    });
  }

  useEffect(async () => {
    if (vet) {
      setValues({
        address: vet.address,
        license: vet.license,
        name: vet.name,
        phone: vet.phone,
        email: vet.email,
        profilePicture: vet.profilePicture,
      });
    } else {
      history.push("/vet/home");
    }
  }, []);

  useEffect(() => {
    if (values.name !== "" && !errors.name.error) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [values]);

  async function handleUpdate(e) {
    e.preventDefault();
    setLoading(true);
    if (photo != null) {
      try {
        await uploadPhoto("vets", localStorage.getItem("id"), photo);
      } catch (err) {
        showAlert("Ocurrió un error al actualizar tu foto");
      }
    }

    try {
      const { valid, info } = await UpdateVetInfo(
        values.address.trim(),
        values.license.trim(),
        titleCase(values.name).trim(),
        values.phone.trim()
      );

      if (valid) {
        await GetVetInfo();
      } else {
        showAlert(info);
      }

      history.push("/vet/profile");
    } catch (error) {
      showAlert("Ocurrió un error al actualizar tu información");
    }
    setLoading(false);
  }

  const handleInputChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });

    switch (e.target.name) {
      case "name": {
        const { valid, info } = validateName(e.target.value, "vet");
        setErrors({
          ...errors,
          [e.target.name]: { error: !valid, helper: info },
        });
        break;
      }
      case "phone": {
        const { valid, info } = validatePhone(e.target.value);
        setErrors({
          ...errors,
          [e.target.name]: { error: !valid, helper: info },
        });
        break;
      }
      default:
        break;
    }
  };

  async function handlePhoto(e) {
    const file = e.target.files[0];
    const allowedExtensions = /(.jpg|.jpeg|.png)$/i;
    if (!allowedExtensions.exec(file.name)) {
      showAlert("Elige un formato de imagen válido (.jpg, .png, .jpeg)");
    } else if (file?.size / 1024 > MAX_IMAGE_SIZE) {
      showAlert(MAX_IMAGE_SIZE_TEXT);
    } else {
      setClicked(true);
      setPhoto(file);
      setPhotoPreview(URL.createObjectURL(file));
    }
  }

  function selectPhoto() {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = ".png,.jpg,.jpeg";
    fileInput.addEventListener("change", (event) => {
      handlePhoto(event);
    });
    fileInput.click();
    fileInput.remove();
  }

  return (
    <BasicCard>
      <BasicForm onSubmit={handleUpdate}>
        <>
          <div className={classes.root}>
            <div className={classes.containerPhoto}>
              <img
                className={classes.vetPhoto}
                src={
                  clicked || values.profilePicture === ""
                    ? photoPreview
                    : values.profilePicture
                }
                alt="photoVet"
              />
              <button
                className={classes.iconButton}
                type="button"
                onClick={selectPhoto}
              >
                <img src={greenCamera} alt="" />
              </button>
              <InputTextOutlined
                roleUser="vet"
                type="text"
                label="Nombre completo"
                name="name"
                placeholder="Ingresa tu nombre completo"
                value={values.name}
                onChange={handleInputChange}
                helperText={errors.name.helper}
                widthField="300px"
              />
            </div>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                pt: 1,
                alignItems: "center",
              }}
            >
              <InputTextOutlined
                roleUser="vet"
                type="text"
                label="Cédula profesional"
                name="license"
                placeholder="Ingresa tu número de cédula"
                value={values.license}
                onChange={handleInputChange}
                widthField="300px"
              />

              <InputTextOutlined
                roleUser="vet"
                type="text"
                label="Teléfono"
                name="phone"
                placeholder="Ingresa tu teléfono"
                value={values.phone}
                onChange={handleInputChange}
                helperText={values.phone === "" ? "" : errors.phone.helper}
                widthField="300px"
              />

              <InputTextOutlined
                roleUser="vet"
                type="text"
                label="Dirección"
                name="address"
                placeholder="Ingresa tu dirección"
                multiline
                value={values.address}
                onChange={handleInputChange}
                widthField="300px"
              />

              <Box sx={{ display: "flex", flexDirection: "row", pt: 1 }}>
                <FlatButton
                  title="Cancelar"
                  widthBtn="140px"
                  roleUser="vet"
                  onClick={() => {
                    history.push("/vet/profile");
                  }}
                  loading={loading}
                />
                <FlatButton
                  title="Actualizar"
                  widthBtn="140px"
                  roleUser="vet"
                  typeButton="submit"
                  isDisabled={disabled}
                  loading={loading}
                />
              </Box>
            </Box>
            <Alert alert={alert} handleClose={closeAlert} />
          </div>
        </>
      </BasicForm>
    </BasicCard>
  );
}
