import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { getPetModel } from "../../Pet/Infrastructure/Pet.reducer";
import { getOwnerEmailSearched } from "../../Vet/Infrastructure/Vet.reducer";
import { GetVaccinationInfo } from "../Infrastructure/Vaccination.presenter";
import FlatButton from "../../DesignSystem/FlatButton";
import BasicCard from "../../DesignSystem/BasicCard.ui";
import greenPaw from "../../assets/paw_finger_green.svg";
import bluePaw from "../../assets/paw_finger.svg";
import LoadingSpinner from "../../DesignSystem/LoadingSpinner";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    position: "relative",
    marginTop: "1px",
    width: "95%",
    flexDirection: "column",
    alignItems: "right",
    justifyContent: "space-evenly",
  },
  photo: {
    borderRadius: "100% 50% 100% 50%",
    objectFit: "cover",
    marginBottom: 5,
    maxHeight: 50,
    minHeight: 50,
    height: "50px",
    width: "50px",
    [theme.breakpoints.down("lg")]: {
      top: "0px",
      right: "-10px",
    },
  },
  body: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    marginBottom: 30,
  },
  bodyOwner: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    marginBottom: 30,
  },
  bodyPhoto: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  itemPhoto: ({ roleUser }) => ({
    width: 80,
    height: 80,
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up("lg")]: {
      marginRight: "10px",
    },
    [theme.breakpoints.down("lg")]: {
      height: "50px",
      width: "50px",
      marginRight: 20,
      marginLeft: roleUser === "vet" ? 5 : 20,
    },
    [theme.breakpoints.down("md")]: {
      height: "50px",
      width: "50px",
      marginRight: 20,
      marginLeft: 10,
    },
  }),
  itemVet: {
    [theme.breakpoints.down("lg")]: {
      marginRight: "45px",
    },
  },
  itemOwner: {
    width: "200px",
    [theme.breakpoints.down("lg")]: {
      width: "300px",
    },
  },
  itemPet: { width: "200px" },
  group: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
  },
  itemCons1: {
    width: "200px",
  },
  itemCons2: {
    width: "400px",
    paddingRight: "5px",
    [theme.breakpoints.down("lg")]: {
      width: "100%",
    },
  },
  info: ({ roleUser }) => ({
    color:
      roleUser === "vet"
        ? theme.palette.primary.dark
        : theme.palette.secondary.main,
    marginTop: 20,
    marginBottom: 10,
    [theme.breakpoints.down("lg")]: {
      margin: "40px 0px 40px",
    },
  }),
  supertitle: ({ roleUser }) => ({
    color:
      roleUser === "vet"
        ? theme.palette.primary.dark
        : theme.palette.secondary.main,
    marginTop: 20,
    marginBottom: 10,
    fontWeight: 700,
    fontSize: "1.2rem",
  }),
  title: ({ roleUser }) => ({
    color:
      roleUser === "vet"
        ? theme.palette.primary.main
        : theme.palette.secondary.dark,
    marginTop: 20,
    marginBottom: 10,
    fontWeight: 400,
    fontSize: "1.125rem",
  }),
  titlePhoto: ({ roleUser }) => ({
    color:
      roleUser === "vet"
        ? theme.palette.primary.main
        : theme.palette.secondary.dark,
    fontWeight: 400,
    fontSize: "1.125rem",
  }),
  subtitle: {
    fontWeight: 400,
    fontSize: "1.125rem",
    color: "#BABABA",
  },
}));

export default function VaccinationInfoUI() {
  const history = useHistory();
  const roleUser = localStorage.getItem("role");
  const classes = useStyles({ roleUser });
  const { id } = useParams();
  const pet = useSelector(getPetModel);
  const ownerEmail =
    roleUser === "vet"
      ? useSelector(getOwnerEmailSearched)
      : localStorage.getItem("email");
  const imgUs = roleUser === "vet" ? greenPaw : bluePaw;
  const [loading, setLoading] = useState(null);
  const [profilePictureVet, setProfilePictureVet] = useState(imgUs);
  const [profilePictureOwner, setProfilePictureOwner] = useState(imgUs);
  const [profilePicturePet, setProfilePicturePet] = useState(imgUs);
  const [vaccination, setVaccination] = useState({
    name: "",
    date: "",
    next_date: "",
    dosis: "",
    brand: "",
    serial: "",
    effects: "",
    recommendations: "",
    ownerName: "",
    ownerEmail: "",
    ownerPhoto: "",
    petName: "",
    petAge: "",
    petWeight: "",
    vetName: "",
    vetLicense: "",
    vetPhone: "",
    vetEmail: "",
    vetPhoto: "",
  });

  useEffect(async () => {
    if (!pet) {
      history.push(`/${roleUser}/pets/vaccinations`);
    } else {
      setLoading(true);
      const { valid, info } = await GetVaccinationInfo(ownerEmail, pet.id, id);
      if (valid) {
        setVaccination({
          ...vaccination,
          name: info.name,
          date: info.date,
          next_date: info.next_date,
          dosis: info.dosis,
          brand: info.brand,
          serial: info.serial,
          effects: info.effects,
          recommendations: info.recommendations,
          ownerName: info.owner.name,
          ownerEmail: info.owner.email,
          ownerPhoto: info.owner.profile_photo,
          petName: pet.name,
          petAge: info.pet.age,
          petWeight: info.pet.weight,
          vetName: info.vet.name,
          vetLicense: info.vet.license,
          vetPhone: info.vet.phone_number,
          vetEmail: info.vet.email,
          vetPhoto: info.vet.profile_photo,
        });

        if (info.vet.profile_photo !== "") {
          setProfilePictureVet(info.vet.profile_photo);
        }

        if (pet.picture !== "") {
          setProfilePicturePet(pet.picture);
        }

        if (info.owner.profile_photo !== "") {
          setProfilePictureOwner(info.owner.profile_photo);
        }
        setLoading(false);
      } else {
        // TODO showAlert
      }
    }
  }, []);

  if (loading || loading === null) {
    return <LoadingSpinner roleUser={roleUser} />;
  }

  return (
    <>
      <BasicCard>
        <>
          <div className={classes.root}>
            <div className={classes.body}>
              <h1 className={classes.info}>{vaccination.petName}</h1>
              <div className={classes.bodyPhoto}>
                <div className={classes.itemPhoto}>
                  <img
                    className={classes.photo}
                    src={profilePicturePet}
                    alt="photoPet"
                  />
                  <h4 className={classes.titlePhoto}>Mascota</h4>
                </div>
                {roleUser === "vet" && (
                  <div className={classes.itemPhoto}>
                    <img
                      className={classes.photo}
                      src={profilePictureOwner}
                      alt="photoOwner"
                    />
                    <h4 className={classes.titlePhoto}>Dueño</h4>
                  </div>
                )}
                <div className={classes.itemPhoto}>
                  <img
                    className={classes.photo}
                    src={profilePictureVet}
                    alt="photoVet"
                  />
                  <h4 className={classes.titlePhoto}>Veterinario</h4>
                </div>
              </div>
            </div>

            <h3 className={classes.supertitle}>Detalles del Veterinario</h3>
            <div className={classes.body}>
              <div className={classes.itemVet}>
                <h4 className={classes.title}>Nombre</h4>
                <h4 className={classes.subtitle}>{vaccination.vetName}</h4>
              </div>
              <div className={classes.itemVet}>
                <h4 className={classes.title}>Cédula Profesional</h4>
                <h4 className={classes.subtitle}>
                  {vaccination.vetLicense === ""
                    ? "Sin datos"
                    : vaccination.vetLicense}
                </h4>
              </div>
              <div className={classes.itemVet}>
                <h4 className={classes.title}>Teléfono</h4>
                <h4 className={classes.subtitle}>
                  {vaccination.vetPhone === ""
                    ? "Sin datos"
                    : vaccination.vetPhone}
                </h4>
              </div>
              <div className={classes.itemVet}>
                <h4 className={classes.title}>Correo Electrónico</h4>
                <h4 className={classes.subtitle}>{vaccination.vetEmail}</h4>
              </div>
            </div>

            {roleUser === "vet" && (
              <div>
                <h3 className={classes.supertitle}>Detalles del Dueño</h3>
                <div className={classes.bodyOwner}>
                  <div className={classes.itemOwner}>
                    <h4 className={classes.title}>Nombre</h4>
                    <h4 className={classes.subtitle}>
                      {vaccination.ownerName}
                    </h4>
                  </div>
                  <div className={classes.itemOwner}>
                    <h4 className={classes.title}>Correo Electrónico</h4>
                    <h4 className={classes.subtitle}>
                      {vaccination.ownerEmail}
                    </h4>
                  </div>
                </div>
              </div>
            )}

            <h3 className={classes.supertitle}>Detalles de la Vacuna</h3>
            <div className={classes.body}>
              <div className={classes.group}>
                <div className={classes.itemCons1}>
                  <h4 className={classes.title}>Nombre de la vacuna</h4>
                  <h4 className={classes.subtitle}>{vaccination.name}</h4>
                </div>

                <div className={classes.itemCons1}>
                  <h4 className={classes.title}>Fecha de aplicación</h4>
                  <h4 className={classes.subtitle}>{vaccination.date}</h4>
                </div>

                <div className={classes.itemCons1}>
                  <h4 className={classes.title}>Edad</h4>
                  <h4 className={classes.subtitle}>{vaccination.petAge}</h4>
                </div>

                <div className={classes.itemCons1}>
                  <h4 className={classes.title}>Peso</h4>
                  <h4 className={classes.subtitle}>
                    {vaccination.petWeight === ""
                      ? "Sin datos"
                      : vaccination.petWeight.concat("kg")}
                  </h4>
                </div>

                <div className={classes.itemCons1}>
                  <h4 className={classes.title}>Dosis </h4>
                  <h4 className={classes.subtitle}>
                    {vaccination.dosis === "" ? "Sin datos" : vaccination.dosis}
                  </h4>
                </div>
                <div className={classes.itemCons1}>
                  <h4 className={classes.title}>Próxima dosis</h4>
                  <h4 className={classes.subtitle}>
                    {vaccination.next_date === "Invalid date"
                      ? "Sin datos"
                      : vaccination.next_date}
                  </h4>
                </div>
                <div className={classes.itemCons1}>
                  <h4 className={classes.title}>Marca </h4>
                  <h4 className={classes.subtitle}>
                    {vaccination.brand === "" ? "Sin datos" : vaccination.brand}
                  </h4>
                </div>
                <div className={classes.itemCons1}>
                  <h4 className={classes.title}>Número de serie</h4>
                  <h4 className={classes.subtitle}>
                    {vaccination.serial === ""
                      ? "Sin datos"
                      : vaccination.serial}
                  </h4>
                </div>
              </div>

              <div className={classes.group}>
                <div className={classes.itemCons2}>
                  <h4 className={classes.title}>Efectos secundarios</h4>
                  <h4 className={classes.subtitle}>
                    {vaccination.effects === ""
                      ? "Sin datos"
                      : vaccination.effects}
                  </h4>
                </div>

                <div className={classes.itemCons2}>
                  <h4 className={classes.title}>Recomendaciones</h4>
                  <h4 className={classes.subtitle}>
                    {vaccination.recommendations === ""
                      ? "Sin datos"
                      : vaccination.recommendations}
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 1 }}>
            <FlatButton
              title="Regresar"
              widthBtn="120px"
              roleUser={roleUser}
              onClick={() => {
                history.push(`/${roleUser}/pets/vaccinations`);
              }}
            />
          </Box>
        </>
      </BasicCard>
    </>
  );
}
