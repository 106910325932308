import React from "react";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { useHistory } from "react-router-dom";

import MuiAppBar from "@mui/material/AppBar";
import {
  Box,
  Drawer,
  Toolbar,
  IconButton,
  ListItem,
  MenuItem,
  List,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

import logo from "../assets/white_paw.svg";
import profileIcon from "../assets/menuIcon_profile.svg";
import petsIcon from "../assets/menuIcon_mascotas.svg";
import consultationsIcon from "../assets/menuIcon_consultas.svg";
import logoutIcon from "../assets/menuIcon_logout.svg";
import { Logout } from "../Auth/Infrastructure/Auth.presenter";

const useStyles = makeStyles((theme) => ({
  logo: {
    width: "60px",
    height: "60px",
    padding: "10px",
  },
  navItemIcon: {
    marginRight: "20px",
  },
  navItem: {
    display: "none !important",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      display: "flex !important",
    },
    height: "60px !important",
    marginLeft: "40px !important",
    [theme.breakpoints.up("lg")]: {
      marginLeft: "80px !important",
    },
  },
  navItemLogout: { position: "absolute !important", right: "20px" },
  toolbar: {
    display: "flex",
    justifyContent: "start",
    minHeight: "60px !important",
  },
  drawerItem: ({ role }) => ({
    // marginBottom: "3px solid #fff !important",
    color: "#fff !important",
    borderBottom: "3px solid #fff !important",
    background:
      role === "vet"
        ? `${theme.palette.primary.main} !important`
        : `${theme.palette.secondary.main} !important`,
    "&:hover": {
      background:
        role === "vet"
          ? `${theme.palette.primary.dark} !important`
          : `${theme.palette.secondary.dark} !important`,
    },
  }),
  drawer: ({ role }) => ({
    zIndex: 1,
    marginTop: "60px",
    width: "240px",
    flexShrink: 0,
    "& .MuiDrawer-paper": {
      width: "240px",
      height: "fit-content",
      background:
        role === "vet"
          ? `${theme.palette.primary.main} !important`
          : `${theme.palette.secondary.main} !important`,
    },
  }),
  drawerHeader: {
    padding: 0,
  },
  drawerList: {
    width: "100%",
    padding: 0,
  },
}));

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme }) => ({
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  zIndex: 5,
  height: "60px",
  background:
    localStorage.getItem("role") === "vet"
      ? theme.palette.primary.main
      : theme.palette.secondary.main,
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  position: "relative",
  display: "flex",
  alignItems: "center",
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

export default function Navbar() {
  const role = localStorage.getItem("role");
  const [open, setOpen] = React.useState(false);

  const classes = useStyles({ role });
  const history = useHistory();

  const handleResizeLoad = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth > 768) {
      setOpen(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleResizeLoad);
    window.addEventListener("load", handleResizeLoad);
    return () => {
      window.removeEventListener("resize", handleResizeLoad);
      window.removeEventListener("load", handleResizeLoad);
    };
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  function link(route) {
    history.push(`/${role}/${route}`);
    setOpen(false);
  }

  function logout() {
    Logout();
    history.push("/index");
  }

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar position="fixed">
        <Toolbar className={classes.toolbar}>
          <MenuItem
            style={{ margin: 0, padding: 0 }}
            onClick={() => {
              link("home");
            }}
          >
            <img className={classes.logo} alt="home" src={logo} />
          </MenuItem>

          <MenuItem
            className={classes.navItem}
            onClick={() => {
              link("profile");
            }}
          >
            <img
              className={classes.navItemIcon}
              alt="perfil"
              src={profileIcon}
            />
            Perfil
          </MenuItem>
          <MenuItem
            className={classes.navItem}
            onClick={() => {
              link("pets/list");
            }}
          >
            <img
              className={classes.navItemIcon}
              alt="mascotas"
              src={petsIcon}
            />
            {role === "vet" ? "Pacientes" : "Mascotas"}
          </MenuItem>
          {role === "vet" && (
            <MenuItem
              className={classes.navItem}
              onClick={() => {
                link("consultations");
              }}
            >
              <img
                className={classes.navItemIcon}
                alt="consultas"
                src={consultationsIcon}
              />
              Consultas
            </MenuItem>
          )}
          <MenuItem
            className={`${classes.navItem} ${classes.navItemLogout}`}
            onClick={logout}
          >
            <img
              className={classes.navItemIcon}
              alt="logout"
              src={logoutIcon}
            />
            Salir
          </MenuItem>
          <IconButton
            style={{ position: "absolute", right: "40px" }}
            sx={{ display: { md: "none" } }}
            color="inherit"
            edge="end"
            onClick={open ? handleDrawerClose : handleDrawerOpen}
          >
            {open ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
        </Toolbar>
      </AppBar>

      <Main open={open}>
        <DrawerHeader />
      </Main>

      <Drawer
        className={classes.drawer}
        classes={{
          paper: classes.drawer,
        }}
        variant="temporary"
        anchor="right"
        open={open}
        onBackdropClick={handleDrawerClose}
      >
        <DrawerHeader className={classes.drawerHeader}>
          <List className={classes.drawerList}>
            <ListItem
              button
              className={classes.drawerItem}
              onClick={() => {
                link("profile");
              }}
            >
              <ListItemIcon>
                <img alt="perfil" src={profileIcon} />
              </ListItemIcon>
              <ListItemText primary="Perfil" />
            </ListItem>

            <ListItem
              button
              className={classes.drawerItem}
              onClick={() => {
                link("pets/list");
              }}
            >
              <ListItemIcon>
                <img alt="mascotas" src={petsIcon} />
              </ListItemIcon>
              <ListItemText
                primary={role === "vet" ? "Pacientes" : "Mascotas"}
              />
            </ListItem>
            {role === "vet" && (
              <ListItem
                button
                className={classes.drawerItem}
                onClick={() => {
                  link("consultations");
                }}
              >
                <ListItemIcon>
                  <img alt="consultas" src={consultationsIcon} />
                </ListItemIcon>
                <ListItemText primary="Consultas" />
              </ListItem>
            )}
            <ListItem button className={classes.drawerItem} onClick={logout}>
              <ListItemIcon>
                <img alt="logout" src={logoutIcon} />
              </ListItemIcon>
              <ListItemText primary="Salir" />
            </ListItem>
          </List>
        </DrawerHeader>
      </Drawer>
    </Box>
  );
}
