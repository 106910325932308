import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "vet",

  initialState: {
    model: null,
    ownerEmailSearched: "",
  },

  reducers: {
    setModel: (state, { payload: model }) => ({
      ...state,
      model,
    }),
    setOwnerEmailSearched: (state, {payload: ownerEmailSearched}) => ({
      ...state,
      ownerEmailSearched
    }),
  },
});

export const { setModel, setOwnerEmailSearched } = slice.actions;

export default slice.reducer;

export const getModel = (store) => store.vet.model;
export const getOwnerEmailSearched = (store) => store.vet.ownerEmailSearched;
