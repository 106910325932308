class PetModel{
    constructor(id, name, ownerId, kind, breed, birth, allergies, picture, neutered, gender, surgeries){
        this.id = id;
        this.name = name; 
        this.ownerId = ownerId; 
        this.kind = kind;
        this.breed = breed; 
        this.birth = birth; 
        this.allergies = allergies; 
        this.picture = picture;
        this.neutered = neutered; 
        this.gender = gender;
        this.surgeries = surgeries;
    }
}

export default PetModel;