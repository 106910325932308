import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    footerImage: {
      [theme.breakpoints.down("lg")]: {
        display: "block",
        width: "100%",
        backgroundSize: "cover",
        backgroundPosition: "-0px -100px",
      },
      [theme.breakpoints.up("lg")]: {
        display: "none",
      },
    }
  }));
  
export default function FooterImage({fimage}) {
  const classes = useStyles();
    
    return(
        <img className={classes.footerImage} 
        src={fimage}
        alt="mascota" />       
    );
}


FooterImage.defaultProps = {
};

FooterImage.propTypes = {
  fimage: PropTypes.string.isRequired,
};