import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@mui/styles";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";

const useStyles = makeStyles((theme) => ({
  icon: {
    width: 25,
    height: 25,
  },
  btnStyles: ({ roleUser, evently }) => ({
    display: "flex",
    justifyContent: `${evently === true ? "space-evenly" : "space-around"}`,
    alignItems: "center",
    background: "#fff",
    borderRadius: 30,
    width: 280,
    height: 50,
    color:
      roleUser === "vet"
        ? theme.palette.primary.main
        : theme.palette.secondary.main,
    border: `2px solid ${
      roleUser === "vet"
        ? theme.palette.primary.main
        : theme.palette.secondary.main
    }`,
    fontWeight: 500,
    fontSize: "1.25rem",
    "&:hover": {
      cursor: "pointer",
      background:
        roleUser === "vet"
          ? theme.palette.primary.light
          : theme.palette.secondary.light,
    },
  }),
}));

export default function MenuButton({
  title,
  onClick,
  roleUser,
  typeButton,
  srcImg,
  altText,
  evently,
}) {
  const classes = useStyles({ roleUser, evently });
  return (
    <button
      className={classes.btnStyles}
      onClick={onClick}
      // eslint-disable-next-line react/button-has-type
      type={typeButton}
    >
      <img className={classes.icon} src={srcImg} alt={altText} />

      {title}
      <KeyboardArrowRightRoundedIcon />
    </button>
  );
}

MenuButton.defaultProps = {
  typeButton: "button",
  onClick: () => null,
  altText: "Icon default",
  evently: false,
};

MenuButton.propTypes = {
  roleUser: PropTypes.string.isRequired,
  typeButton: PropTypes.string,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  srcImg: PropTypes.string.isRequired,
  altText: PropTypes.string,
  evently: PropTypes.bool,
};
