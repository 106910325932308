/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { FormControl, TextField } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import { getModel } from "../../Owner/Infrastructure/Owner.reducer";
import {
  getModel as getVetModel,
  getOwnerEmailSearched,
} from "../../Vet/Infrastructure/Vet.reducer";
import { GetOwnerInfo } from "../../Owner/Infrastructure/Owner.presenter";
import { GetPetListOwner } from "../Infrastructure/Pet.presenter";
import PetCard from "../../DesignSystem/PetCard";
import sadFace from "../../assets/blue_sad.svg";
import watermark from "../../assets/green_watermark.svg";
import BasicCard from "../../DesignSystem/BasicCard.ui";
import LoadingSpinner from "../../DesignSystem/LoadingSpinner";
import InputTextOutlined from "../../DesignSystem/InputTextOutlined";
import FlatButton from "../../DesignSystem/FlatButton";
import { GetVetInfo } from "../../Vet/Infrastructure/Vet.presenter";
import Alert from "../../DesignSystem/BasicModal";

const useStyles = makeStyles((theme) => ({
  petListContainer: {
    display: "grid",
    placeItems: "center",
    gap: 35,
    width: "100%",
    height: "auto",
    paddingLeft: 24,
    paddingRight: 24,
    [theme.breakpoints.down("md")]: {
      paddingTop: 30,
      paddingBottom: 30,
      gridTemplateColumns: "1fr",
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: 50,
      paddingBottom: 50,
      gridTemplateColumns: "1fr 1fr",
    },
    [theme.breakpoints.up("lg")]: {
      paddingTop: 30,
      paddingBottom: 30,
      gridTemplateColumns: "repeat(3, 1fr)",
    },
  },
  headerContainer: ({ role }) => ({
    color:
      role === "vet"
        ? theme.palette.primary.main
        : theme.palette.secondary.main,
    fontSize: "1.5rem",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      gridColumn: "1/-1",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      gap: 15,
    },
    [theme.breakpoints.up("md")]: {
      gridColumn: "1/-1",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      fontSize: "2.25rem",
      padding: "0 20px",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "0 50px",
    },
  }),
  pagination: {
    gridColumn: "1/-1",
    display: "flex",
    justifyContent: "center",
    paddingBottom: 10,
  },
  btnStyles: ({ role }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 15,
    background: "#fff",
    border: "2px solid #00B1E0",
    borderRadius: 30,
    width: 275,
    height: 50,
    color: "#00B1E0",
    fontWeight: 500,
    fontSize: "1.25rem",
    "&:hover": {
      cursor: "pointer",
      background:
        role === "vet"
          ? theme.palette.primary.light
          : theme.palette.secondary.light,
    },
  }),
  inputSearch: {
    backgroundColor: "white",
    height: 40,
    borderRadius: 12,
    border: "2px solid #71E4D1",
    paddingLeft: "8px",
  },
  flex: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  cardStyle: {
    margin: "30px",
    position: "relative",
  },
  watermark: {
    position: "absolute",
    transform: "translateY(-50px)",
  },
  instructions: {
    marginTop: "30px",
    marginBottom: "10px",
    textAlign: "center",
    maxWidth: "380px",
  },
  emptyPets: {
    gap: 60,
    padding: "0 10px",
  },
  h3Style: ({ role }) => ({
    textAlign: "center",
    color:
      role === "vet"
        ? theme.palette.primary.main
        : theme.palette.secondary.main,
    fontSize: "2.3rem",
  }),
  h4Style: {
    fontSize: "1.4rem",
    color: theme.palette.primary.main,
    textAlign: "center",
  },
}));

export default function PetListUI() {
  const role = localStorage.getItem("role");
  const classes = useStyles({ role });
  const history = useHistory();
  const [pets, setPets] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [ownerEmail, setOwnerEmail] = useState("");
  const [page, setPage] = React.useState(1);
  const [searchDisabled, setSearchDisabled] = useState(false);
  const [searchError, setSearchError] = useState(false);
  const [searchErrorText, setSearchErrorText] = useState("");
  const [loading, setLoading] = useState(false);
  const owner = useSelector(getModel);
  const vet = useSelector(getVetModel);
  const emailSearched = useSelector(getOwnerEmailSearched);

  let response = {};
  const handlePaginationChange = async (event, numberPage) => {
    if (role === "vet") {
      if (emailSearched !== "") {
        response = await GetPetListOwner(emailSearched, numberPage);
      } else {
        response = await GetPetListOwner(ownerEmail, numberPage);
      }
    } else {
      response = await GetPetListOwner(owner.email, numberPage);
    }
    setPets(response.info);
    setPage(numberPage);
  };

  useEffect(async () => {
    if (role === "owner") {
      if (!owner) {
        await GetOwnerInfo();
      }
      const { valid, info } = await GetPetListOwner(
        localStorage.getItem("email")
      );
      if (valid) {
        setPets(info);
      } else {
        setPets({ items: null });
      }
    } else {
      if (!vet) {
        await GetVetInfo();
      }
      if (Object.entries(emailSearched).length > 0) {
        const { valid, info } = await GetPetListOwner(emailSearched);
        if (valid) {
          setPets(info);
        } else {
          setPets({ items: null });
        }
      } else {
        setPets({ items: null });
      }
    }
    setPage(1);
  }, []);

  useEffect(() => {
    if (searchValue !== "") {
      setSearchDisabled(false);
    } else {
      setSearchDisabled(true);
    }
  }, [searchValue]);

  const handleChangeInputSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const [alert, setAlert] = useState({
    open: false,
  });
  function closeAlert() {
    setAlert({ ...alert, open: false });
  }
  function showAlert(text, title = "Ocurrió un error", type = "error") {
    setAlert({
      title,
      text,
      open: true,
      type,
    });
  }

  const handleSubmitSearch = async () => {
    setLoading(true);
    const { valid, info } = await GetPetListOwner(searchValue);
    if (valid) {
      setOwnerEmail(searchValue);
      setPets(info);
      setPage(1);
      setSearchValue("");
    } else {
      if (pets.items !== null) {
        showAlert(info?.message);
      }
      setSearchErrorText(info?.message);
      setSearchError(true);
    }
    setLoading(false);
  };

  // Si no existen mascotas
  if (pets.items === null) {
    return (
      <div>
        {role === "vet" ? (
          <BasicCard>
            <>
              <img
                className={classes.watermark}
                src={watermark}
                alt="Marca de agua"
              />
              <div className={`${classes.cardStyle} ${classes.flex}`}>
                <h4 className={classes.h4Style}>Busca una mascota por dueño</h4>
                <div className={classes.instructions}>
                  <p>
                    Para poder ver la lista de mascotas tienes que buscar por el
                    correo de un dueño.
                  </p>
                </div>
                <InputTextOutlined
                  roleUser="vet"
                  type="text"
                  label="Correo"
                  name=""
                  placeholder="ejemplo@gmail.com"
                  value={searchValue}
                  onChange={handleChangeInputSearch}
                  widthField="250px"
                  error={searchError}
                  helperText={searchErrorText}
                />
                <br />
                <FlatButton
                  title="Buscar"
                  widthBtn="138px"
                  roleUser="vet"
                  onClick={handleSubmitSearch}
                  isDisabled={searchDisabled}
                  loading={loading}
                />
              </div>
            </>
          </BasicCard>
        ) : (
          <div className={`${classes.emptyPets} ${classes.flex}`}>
            <img src={sadFace} alt="sad cat face" />
            <h3 className={classes.h3Style}>No existen mascotas</h3>
            <button
              type="button"
              className={classes.btnStyles}
              onClick={() => history.push("/owner/pets/register")}
            >
              Agregar una mascota
              <AddIcon />
            </button>
          </div>
        )}
      </div>
    );
  }

  if (Object.entries(pets).length === 0) {
    return <LoadingSpinner roleUser={role} />;
  }

  return (
    <div style={{ width: "100%", minHeight: "100vh", paddingTop: 40 }}>
      <div className={classes.headerContainer}>
        <h3 className={classes.title}>
          {role === "vet" ? "Pacientes" : "Mis Mascotas"}
        </h3>
        {role === "vet" ? (
          <FormControl
            sx={{
              minWidth: { xs: 280, md: 365 },
              width: "auto",
              maxWidth: 400,
              display: "flex",
              justifyContent: "center",
              alignItems: { xs: "center", md: "baseline" },
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <TextField
              fullWidth
              variant="standard"
              placeholder="Buscar mascota por dueño"
              onChange={handleChangeInputSearch}
              id="busqueda"
              value={searchValue}
              className={classes.inputSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: "#C4C4C4" }} />
                  </InputAdornment>
                ),
                disableUnderline: true,
                classes: { root: classes.inputSearch },
                sx: { fontSize: ".9rem" },
              }}
            />
            <FlatButton
              onClick={handleSubmitSearch}
              title="Buscar"
              widthBtn="100px"
              roleUser="vet"
              isDisabled={searchDisabled}
              loading={loading}
            />
          </FormControl>
        ) : (
          <button
            type="button"
            className={classes.btnStyles}
            onClick={() => history.push("/owner/pets/register")}
          >
            Agregar una mascota
            <AddIcon />
          </button>
        )}
      </div>
      <div className={classes.petListContainer}>
        {pets.items.map((pet) => (
          <PetCard
            key={pet.id}
            id={pet.id}
            name={pet.name}
            kind={pet.kind}
            breed={pet.breed}
            profileImage={pet.photo}
          />
        ))}
        <Pagination
          className={classes.pagination}
          color={role === "vet" ? "primary" : "secondary"}
          count={pets.total_pages}
          page={page}
          onChange={handlePaginationChange}
        />
      </div>
      <Alert alert={alert} handleClose={closeAlert} />
    </div>
  );
}
