import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import Pagination from "@mui/material/Pagination";
import AddIcon from "@mui/icons-material/Add";
import LicenseModal from "../../DesignSystem/LicenseModal";
import {
  getModel,
  getOwnerEmailSearched,
} from "../../Vet/Infrastructure/Vet.reducer";
import { getPetModel } from "../../Pet/Infrastructure/Pet.reducer";
import { GetConsultationList } from "../Infrastructure/Consultation.presenter";
import ConsultationCard from "../../DesignSystem/ConsultationCard";
import FlatButton from "../../DesignSystem/FlatButton";
import LoadingSpinner from "../../DesignSystem/LoadingSpinner";
import blueFace from "../../assets/blue_sad.svg";
import greenFace from "../../assets/green_sad.svg";

const useStyles = makeStyles((theme) => ({
  consultationListContainer: {
    display: "grid",
    placeItems: "center",
    gap: 35,
    width: "100%",
    height: "auto",
    paddingLeft: 24,
    paddingRight: 24,
    [theme.breakpoints.down("md")]: {
      paddingTop: 30,
      paddingBottom: 30,
      gridTemplateColumns: "1fr",
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: 50,
      paddingBottom: 50,
      gridTemplateColumns: "1fr 1fr",
    },
    [theme.breakpoints.up("lg")]: {
      paddingTop: 30,
      paddingBottom: 30,
      gridTemplateColumns: "repeat(3, 1fr)",
    },
  },
  headerContainer: ({ role }) => ({
    color:
      role === "vet"
        ? theme.palette.primary.main
        : theme.palette.secondary.main,
    fontSize: "1.5rem",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      gridColumn: "1/-1",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      gap: 15,
    },
    [theme.breakpoints.up("md")]: {
      gridColumn: "1/-1",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      fontSize: "2.25rem",
      padding: "0 50px",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "0 24px",
    },
  }),
  pagination: {
    gridColumn: "1/-1",
    display: "flex",
    justifyContent: "center",
    paddingBottom: 10,
  },
  btnStyles: ({ role }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 15,
    background: "#fff",
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 30,
    width: 275,
    height: 50,
    color: theme.palette.primary.main,
    fontWeight: 500,
    fontSize: "1.25rem",
    "&:hover": {
      cursor: "pointer",
      background:
        role === "vet"
          ? theme.palette.primary.light
          : theme.palette.secondary.light,
    },
  }),
  emptyConsultations: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: 20,
    padding: 10,
  },
  h3Style: ({ role }) => ({
    textAlign: "center",
    color:
      role === "vet"
        ? theme.palette.primary.main
        : theme.palette.secondary.main,
    fontSize: "2.3rem",
  }),
}));

export default function ConsultationPetList() {
  const role = localStorage.getItem("role");
  const history = useHistory();
  const classes = useStyles({ role });
  const [loading, setLoading] = useState(true);
  const [consultations, setConsultations] = useState({});
  const [page, setPage] = React.useState(1);
  const sadFace = role === "vet" ? greenFace : blueFace;
  const pet = useSelector(getPetModel);
  const ownerEmail =
    role === "vet"
      ? useSelector(getOwnerEmailSearched)
      : localStorage.getItem("email");
  const vet = useSelector(getModel);

  useEffect(async () => {
    if (!pet) {
      history.push(`/${role}/home`);
    } else {
      setLoading(true);
      const { info } = await GetConsultationList(ownerEmail, pet.id);
      setConsultations(info);
      setLoading(false);
      setPage(1);
    }
  }, []);

  const handlePaginationChange = async (event, numberPage) => {
    const { info } = await GetConsultationList(ownerEmail, pet.id, numberPage);
    setConsultations(info);
    setPage(numberPage);
  };

  const [licenseAlert, setLicenseAlert] = useState(false);
  function closeAlert() {
    setLicenseAlert(false);
  }
  function checkLicense() {
    if (!vet) {
      history.push("/vet/pets/list");
    } else if (vet.license) {
      history.push("/vet/pets/consultations/register");
    } else {
      setLicenseAlert(true);
    }
  }

  // Si  no existen consultas
  if (consultations.items === null) {
    return (
      <div className={classes.emptyConsultations}>
        <img src={sadFace} alt="sad cat face" />
        <div className={classes.emptyConsultations}>
          <h3 className={classes.h3Style}>La mascota no tiene consultas</h3>
          {role === "vet" && (
            <>
              <button
                type="button"
                className={classes.btnStyles}
                onClick={checkLicense}
              >
                Agregar una consulta
                <AddIcon />
              </button>
            </>
          )}
          <FlatButton
            title="Atrás"
            widthBtn="120px"
            roleUser={role}
            onClick={() => {
              history.push(`/${role}/pets/menu/${pet.id}`);
            }}
          />
        </div>
        {role === "vet" && (
          <LicenseModal isOpen={licenseAlert} handleClose={closeAlert} />
        )}
      </div>
    );
  }

  if (loading) {
    return <LoadingSpinner roleUser={role} />;
  }

  return (
    <div style={{ width: "100%", minHeight: "100vh", paddingTop: 40 }}>
      <div className={classes.headerContainer}>
        <h3 className={classes.title}>Consultas de {pet.name}</h3>
        {role === "vet" && (
          <button
            type="button"
            className={classes.btnStyles}
            onClick={checkLicense}
          >
            Agregar una consulta
            <AddIcon />
          </button>
        )}
      </div>
      <div className={classes.consultationListContainer}>
        {consultations.items.map((consultation) => (
          <ConsultationCard
            id={consultation.id}
            key={consultation.id}
            petId={pet.id}
            date={consultation.date}
            petName={consultation.pet.name}
            petVetName={consultation.vet.name}
            review={consultation.review}
          />
        ))}
        <Pagination
          className={classes.pagination}
          color={role === "vet" ? "primary" : "secondary"}
          count={consultations.total_pages}
          page={page}
          onChange={handlePaginationChange}
        />
      </div>
      {role === "vet" && (
        <LicenseModal isOpen={licenseAlert} handleClose={closeAlert} />
      )}
    </div>
  );
}
