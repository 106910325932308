import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@mui/styles";

import { useSelector } from "react-redux";
import { Logout } from "../../Auth/Infrastructure/Auth.presenter";
import { getModel as ownerModel } from "../../Owner/Infrastructure/Owner.reducer";
import { getModel as vetModel } from "../../Vet/Infrastructure/Vet.reducer";
import { GetOwnerInfo } from "../../Owner/Infrastructure/Owner.presenter";
import { GetVetInfo } from "../../Vet/Infrastructure/Vet.presenter";

import MenuButton from "../../DesignSystem/MenuButton";
import LoadingSpinner from "../../DesignSystem/LoadingSpinner";

import imagoTipo from "../../assets/Imagotipo.svg";
import greenSearchIcon from "../../assets/greenSearchIcon.svg";
import blueIconPets from "../../assets/blue_pets.svg";
import greenIconConsult from "../../assets/menuIcon_green_consultas.svg";
import greenIconProfile from "../../assets/menuIcon_green_profile.svg";
import blueIconProfile from "../../assets/menuIcon_blue_profile.svg";
import blueCatFace from "../../assets/blue_cathome.svg";
import greenCatFace from "../../assets/green_cathome.svg";
import imgVet from "../../assets/paw_finger_green.svg";
import imgOwner from "../../assets/paw_finger.svg";

const useStyles = makeStyles((theme) => ({
  welcomeContainer: {
    width: "100%",
    minHeight: "100vh",
    [theme.breakpoints.down("md")]: {
      padding: "60px 0",
    },
    [theme.breakpoints.up("md")]: {
      display: "grid",
      gap: 15,
      gridTemplateColumns: ".89fr 1fr",
      placeItems: "center",
      paddingTop: 60,
    },
  },
  imagesContainer: {
    display: "flex",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      gap: 40,
      marginBottom: 55,
    },
    [theme.breakpoints.up("md")]: {
      gap: 0,
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      justifySelf: "end",
    },
    [theme.breakpoints.up("lg")]: {
      alignSelf: "end",
      gap: 50,
    },
  },
  containerImages:{
    display: "flex",
    position: "relative",   
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  textStyle: ({ role }) => ({
    color:
      role === "vet"
        ? theme.palette.primary.main
        : theme.palette.secondary.main,
    fontSize: "2.5rem",
    fontWeight: 700,
    textAlign: "center",
  }),
  welcomeTextDesktop: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  welcomeTextMobile: {
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  vetechIcon: {
    width: 220,
    height: 139,
    [theme.breakpoints.down("sm")]: {
      width: "50%",
    },
  },
  catFaceIcon: {
    height: 435,
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "auto",
    },
    [theme.breakpoints.up("md")]: {
      width: 375,
      height: 453,
    },
    [theme.breakpoints.up("lg")]: {
      width: 435,
    },
  },
  infoContainer: {
    height: "auto",
    display: "flex",
    gap: 40,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      gap: 30,
    },
    [theme.breakpoints.up("md")]: {
      gap: 20,
      marginBottom: "40px",
    },
  },
  photoDesktop: {
    height: "118px",
    width: "120.68px",
    objectFit: "cover",
    borderRadius: "100% 50% 100% 50%",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  photoMobile: {
    height: "139px",
    width: "142.15px",
    objectFit: "cover",
    borderRadius: "100% 50% 100% 50%",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      marginTop: "50px",
    },
    [theme.breakpoints.down("md")]: {
      display: "flex",
      position: "absolute",
      top: "0",
      left: "calc(50% - 70px)",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

export default function Home() {
  const role = localStorage.getItem("role");
  const [loading, setLoading] = useState(null);
  const classes = useStyles({ role });
  const history = useHistory();
  const imgDef = role === "vet" ? imgVet : imgOwner;
  const model =
    role === "vet" ? useSelector(vetModel) : useSelector(ownerModel);

  useEffect(async () => {
    setLoading(true);
    if (localStorage.getItem("role") === "vet") {
      const { info } = await GetVetInfo();
      if (info === "Network Error") {
        Logout();
        history.push("/login");
      }
    } else {
      const { info } = await GetOwnerInfo();
      if (info === "Network Error") {
        Logout();
        history.push("/login");
      }
    }
    setLoading(false);
  }, []);

  if(loading || loading === null){
    return <LoadingSpinner roleUser={role}/>
  }

  return model ? (
    <div className={classes.welcomeContainer}>
      <div className={classes.imagesContainer}>
        <img className={classes.vetechIcon} src={imagoTipo} alt="logo Vetech" />
        <h3 className={`${classes.textStyle} ${classes.welcomeTextMobile}`}>
          ¡Bienvenido {model.name}!
        </h3>
        <div className={classes.containerImages}>
          <img
            className={classes.photoMobile}
            src={model.profilePicture === "" ? imgDef : model.profilePicture}
            alt="photoUser"
          />

          <img
            className={classes.catFaceIcon}
            src={role === "vet" ? greenCatFace : blueCatFace}
            alt="cat face"
          />
        </div>
      </div>
      <div className={classes.infoContainer}>
        <h3 className={`${classes.textStyle} ${classes.welcomeTextDesktop}`}>
          ¡Bienvenido {model.name}!
        </h3>

        <img
          className={classes.photoDesktop}
          src={model.profilePicture === "" ? imgDef : model.profilePicture}
          alt="photoUser"
        />

        <MenuButton
          title={role === "vet" ? "Buscar pacientes" : "Mi perfil"}
          roleUser={role}
          typeButton="button"
          onClick={
            role === "vet"
              ? () => history.push(`/${role}/pets/list`)
              : () => history.push(`/${role}/profile`)
          }
          srcImg={role === "vet" ? greenSearchIcon : blueIconProfile}
          altText="Icon pets or profile"
          evently={role === "vet"}
        />

        <MenuButton
          title={role === "vet" ? "Consultas" : "Mis mascotas"}
          roleUser={role}
          typeButton="button"
          onClick={
            role === "vet"
              ? () => history.push(`/${role}/consultations`)
              : () => history.push(`/${role}/pets/list`)
          }
          srcImg={role === "vet" ? greenIconConsult : blueIconPets}
          altText="Icon consultations or pets"
          evently={role === "owner"}
        />

        {role === "vet" && (
          <MenuButton
            title="Mi perfil"
            roleUser={role}
            typeButton="button"
            onClick={() => history.push(`/${role}/profile`)}
            srcImg={greenIconProfile}
            altText="Icon profile"
          />
        )}
      </div>
    </div>
  ) : (
    <></>
  );
}
