import {
  GetConsultationInfoFromHistoryRepository,
  GetConsultationInfoRepository,
} from "../Infrastructure/Consultation.repository";

export async function GetConsultationInfoFromHistoryLogic(id, consultationId) {
  return GetConsultationInfoFromHistoryRepository(id, consultationId);
}
export async function GetConsultationInfoLogic(petId, consultationId) {
  return GetConsultationInfoRepository(petId, consultationId);
}
