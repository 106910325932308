class ConsultationModel{
    constructor(id, petId, petName, petOwnerName, petVetName, date, weight, temperature, review, diagnosis, treatment, indications, nextDate){
        this.id = id;
        this.petId = petId;
        this.petName = petName;
        this.petOwnerName = petOwnerName;
        this.petVetName = petVetName;
        this.date = date;
        this.weight = weight;
        this.temperature = temperature;
        this.review = review; 
        this.diagnosis = diagnosis; 
        this.treatment = treatment; 
        this.indications = indications; 
        this.nextDate = nextDate;
    }
}

export default ConsultationModel;