import { UpdateVetInfoRepository } from "../Infrastructure/Vet.repository";

export default async function UpdateVetInfoLogic(
  id,
  address,
  license,
  name,
  phone
) {
  return UpdateVetInfoRepository(id, address, license, name, phone);
}
