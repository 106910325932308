const ThemeComponents = {
  MuiTextField: {
    styleOverrides: {
      root: {
        marginTop: "20px",
      },
    },
  },
  MuiLoadingButton: {
    styleOverrides: {
      root: {
        margin: "10px",
        padding: "20px",
        borderRadius: "30px",
        position: "relative",
        fontSize: "1rem",
        fontWeight: 770,
        color: "white",
        textTransform: "none",
      },
    },
  },
  // Button custom variants
  MuiButton: {
    variants: [
      {
        props: { variant: "error" },
        style: {
          backgroundColor: "#F20F0F",
          "&:hover": {
            backgroundColor: "#BF0B0B",
          },
        },
      },
      {
        props: { variant: "success" },
        style: {
          backgroundColor: "#419F00",
          "&:hover": {
            background: "#5AB507",
          },
        },
      },
    ],
  },
};

export default ThemeComponents;
