import { RegisterVaccinationRepository } from "../Infrastructure/Vaccination.repository";
import VaccinationModel from "../Domain/Vaccination.model";

export default async function RegisterVaccinationLogic(
  vetId,
  petId,
  ownerEmail,
  name,
  dosis,
  weight,
  brand,
  serial,
  effects,
  recommendations,
  nextDate
) {
  const vaccination = new VaccinationModel(
    "",
    name,
    "",
    nextDate,
    dosis,
    weight,
    brand,
    serial,
    effects,
    recommendations
  );
  return RegisterVaccinationRepository(vaccination, vetId, petId, ownerEmail);
}
