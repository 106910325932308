import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { GetConsultationInfoFromHistory } from "../Infrastructure/Consultation.presenter";
import FlatButton from "../../DesignSystem/FlatButton";
import BasicCard from "../../DesignSystem/BasicCard.ui";
import LoadingSpinner from "../../DesignSystem/LoadingSpinner";
import greenPaw from "../../assets/paw_finger_green.svg";
import store from "../../redux/store";
import { setOwnerEmailSearched } from "../../Vet/Infrastructure/Vet.reducer";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    position: "relative",
    marginTop: "1px",
    width: "95%",
    flexDirection: "column",
    alignItems: "right",
    justifyContent: "space-evenly",
  },
  photo: {
    borderRadius: "100% 50% 100% 50%",
    objectFit: "cover",
    marginBottom: 5,
    maxHeight: 50,
    minHeight: 50,
    height: "50px",
    width: "50px",
    [theme.breakpoints.down("lg")]: {
      top: "0px",
      right: "-10px",
    },
  },
  body: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    marginBottom: 30,
  },
  bodyOwner: {
    display: "flex",
    flexWrap: "wrap",
    marginBottom: 30,
    [theme.breakpoints.up("lg")]: {
      justifyContent: "flex-start",
    },
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
    },
  },
  bodyPet: {
    display: "flex",
    flexWrap: "wrap",
    marginBottom: 30,
  },
  bodyPhoto: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  itemPhoto: {
    width: 80,
    height: 80,
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up("lg")]: {
      marginRight: "10px",
    },
    [theme.breakpoints.down("lg")]: {
      height: "50px",
      width: "50px",
      marginRight: 20,
      marginLeft: 5,
    },
    [theme.breakpoints.down("md")]: {
      height: "50px",
      width: "50px",
      marginRight: 20,
      marginLeft: 10,
    },
  },
  itemVet: {
    width: "200px",
    [theme.breakpoints.down("lg")]: {
      marginRight: "90px",
    },
  },
  itemOwner: {
    width: "200px",

    [theme.breakpoints.down("lg")]: {
      marginRight: "90px",
    },
  },
  itemPet: { width: "200px" },
  group: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
  },
  itemCons1: {
    [theme.breakpoints.up("lg")]: {
      width: "200px",
    },
    [theme.breakpoints.down("lg")]: {
      width: "150px",
      marginRight: "50px",
    },
  },
  itemCons2: {
    width: "400px",
    paddingRight: "5px",
    [theme.breakpoints.down("lg")]: {
      width: "100%",
    },
  },
  info: {
    color: theme.palette.primary.dark,
    marginTop: 20,
    marginBottom: 10,
    [theme.breakpoints.down("lg")]: {
      margin: "40px 0px 40px",
    },
  },
  supertitle: {
    color: theme.palette.primary.dark,
    marginTop: 20,
    marginBottom: 10,
    fontWeight: 700,
    fontSize: "1.2rem",
  },
  title: {
    color: theme.palette.primary.main,
    marginTop: 20,
    marginBottom: 10,
    fontWeight: 400,
    fontSize: "1.125rem",
  },
  titlePhoto: {
    color: theme.palette.primary.main,
    fontWeight: 400,
    fontSize: "1.125rem",
  },
  subtitle: {
    fontWeight: 400,
    fontSize: "1.125rem",
    color: "#BABABA",
  },
}));

export default function OwnerProfileUI() {
  const history = useHistory();
  const classes = useStyles();
  const { id } = useParams();
  const [loading, setLoading] = useState(null);
  const [profilePictureOwner, setProfilePictureOwner] = useState(greenPaw);
  const [profilePicturePet, setProfilePicturePet] = useState(greenPaw);
  const [consult, setConsult] = useState({
    date: "",
    review: "",
    weight: "",
    next_date: "",
    diagnostic: "",
    temperature: "",
    treatment: "",
    indications: "",
    petName: "",
    petBreed: "",
    petGender: "",
    petAllergies: "",
    petKind: "",
    petBirth: "",
    petNeutered: "",
    petSurgeries: "",
    petPhoto: "",
    ownerName: "",
    ownerEmail: "",
    ownerPhoto: "",
    vetPhoto: "",
    vetName: "",
    vetLicense: "",
    vetPhone: "",
    vetEmail: "",
  });

  useEffect(async () => {
    setLoading(true);
    const { valid, info } = await GetConsultationInfoFromHistory(
      localStorage.getItem("id"),
      id
    );
    if (valid) {
      setConsult({
        ...consult,
        date: info.date,
        review: info.review,
        weight: info.weight,
        next_date: info.next_date,
        diagnostic: info.diagnosis,
        temperature: info.temperature,
        treatment: info.treatment,
        indications: info.indications,
        petId: info.pet.id,
        petName: info.pet.name,
        petBreed: info.pet.breed,
        petGender: info.pet.gender,
        petAllergies: info.pet.allergies,
        petKind: info.pet.kind,
        petBirth: info.pet.birth,
        petNeutered: info.pet.neutered,
        petSurgeries: info.pet.surgeries,
        petPhoto: info.pet.photo,
        ownerName: info.owner.name,
        ownerEmail: info.owner.email,
        ownerPhoto: info.owner.profile_photo,
        vetPhoto: info.vet.profile_photo,
        vetName: info.vet.name,
        vetLicense: info.vet.license,
        vetPhone: info.vet.phone_number,
        vetEmail: info.vet.email,
      });
      store.dispatch(setOwnerEmailSearched(info.owner.email));
      if (info.owner.profile_photo !== "") {
        setProfilePictureOwner(info.owner.profile_photo);
      }

      if (info.pet.photo !== "") {
        setProfilePicturePet(info.pet.photo);
      }

      setLoading(false);
    }
  }, []);

  // validar si la respuesta es vacía

  if (loading || loading === null) {
    return <LoadingSpinner roleUser="vet" />;
  }

  return (
    <>
      <BasicCard>
        <>
          <div className={classes.root}>
            <div className={classes.body}>
              <h1 className={classes.info}>Información</h1>
              <div className={classes.bodyPhoto}>
                <div className={classes.itemPhoto}>
                  <img
                    className={classes.photo}
                    src={profilePicturePet}
                    alt="photoPet"
                  />
                  <h4 className={classes.titlePhoto}>Mascota</h4>
                </div>
                <div className={classes.itemPhoto}>
                  <img
                    className={classes.photo}
                    src={profilePictureOwner}
                    alt="photoOwner"
                  />
                  <h4 className={classes.titlePhoto}>Dueño</h4>
                </div>
              </div>
            </div>
            <div>
              <h3 className={classes.supertitle}>Detalles del Dueño</h3>
              <div className={classes.bodyOwner}>
                <div className={classes.itemOwner}>
                  <h4 className={classes.title}>Nombre</h4>
                  <h4 className={classes.subtitle}>{consult.ownerName}</h4>
                </div>
                <div className={classes.itemOwner}>
                  <h4 className={classes.title}>Correo Electrónico</h4>
                  <h4 className={classes.subtitle}>{consult.ownerEmail}</h4>
                </div>
              </div>
            </div>

            <h3 className={classes.supertitle}>Detalles de la Mascota</h3>
            <div className={classes.bodyPet}>
              <div className={classes.itemPet}>
                <h4 className={classes.title}>Nombre</h4>
                <h4 className={classes.subtitle}>{consult.petName}</h4>
              </div>
              <div className={classes.itemPet}>
                <h4 className={classes.title}>Raza</h4>
                <h4 className={classes.subtitle}>{consult.petBreed}</h4>
              </div>
              <div className={classes.itemPet}>
                <h4 className={classes.title}>Sexo</h4>
                <h4 className={classes.subtitle}>{consult.petGender}</h4>
              </div>
              <div className={classes.itemPet}>
                <h4 className={classes.title}>Alergias</h4>
                <h4 className={classes.subtitle}>
                  {consult.petAllergies.trim() === ""
                    ? "Sin Datos"
                    : consult.petAllergies}
                </h4>
              </div>
              <div className={classes.itemPet}>
                <h4 className={classes.title}>Especie</h4>
                <h4 className={classes.subtitle}>{consult.petKind}</h4>
              </div>
              <div className={classes.itemPet}>
                <h4 className={classes.title}>Fecha de Nacimiento</h4>
                <h4 className={classes.subtitle}>{consult.petBirth}</h4>
              </div>
              <div className={classes.itemPet}>
                <h4 className={classes.title}>Esterilizado</h4>
                <h4 className={classes.subtitle}>
                  {consult.petNeutered ? "Sí" : "No"}
                </h4>
              </div>
              <div className={classes.itemPet}>
                <h4 className={classes.title}>Cirugías</h4>
                <h4 className={classes.subtitle}>
                  {consult.petSurgeries.trim() === ""
                    ? "Sin Datos"
                    : consult.petSurgeries}
                </h4>
              </div>
            </div>

            <h3 className={classes.supertitle}>Detalles de la Consulta</h3>
            <div className={classes.body}>
              <div className={classes.group}>
                <div className={classes.itemCons1}>
                  <h4 className={classes.title}>Fecha</h4>
                  <h4 className={classes.subtitle}>{consult.date}</h4>
                </div>
                <div className={classes.itemCons1}>
                  <h4 className={classes.title}>Peso</h4>
                  <h4 className={classes.subtitle}>
                    {consult.weight === ""
                      ? "Sin Datos"
                      : consult.weight.concat("kg")}
                  </h4>
                </div>
                <div className={classes.itemCons1}>
                  <h4 className={classes.title}>Temperatura</h4>
                  <h4 className={classes.subtitle}>
                    {consult.temperature === ""
                      ? "Sin Datos"
                      : consult.temperature.concat("°")}
                  </h4>
                </div>
                <div className={classes.itemCons1}>
                  <h4 className={classes.title}>Próxima Consulta</h4>
                  <h4 className={classes.subtitle}>
                    {consult.next_date === "Invalid date" ? "Sin Datos" : consult.next_date}
                  </h4>
                </div>
              </div>

              <div className={classes.group}>
                <div className={classes.itemCons2}>
                  <h4 className={classes.title}>Reseña </h4>
                  <h4 className={classes.subtitle}>
                    {consult.review === "" ? "Sin Datos" : consult.review}
                  </h4>
                </div>
                <div className={classes.itemCons2}>
                  <h4 className={classes.title}>Diagnóstico</h4>
                  <h4 className={classes.subtitle}>
                    {consult.diagnostic === ""
                      ? "Sin Datos"
                      : consult.diagnostic}
                  </h4>
                </div>
              </div>

              <div className={classes.group}>
                <div className={classes.itemCons2}>
                  <h4 className={classes.title}>Tratamiento</h4>
                  <h4 className={classes.subtitle}>
                    {consult.treatment === "" ? "Sin Datos" : consult.treatment}
                  </h4>
                </div>
                <div className={classes.itemCons2}>
                  <h4 className={classes.title}>Indicaciones</h4>
                  <h4 className={classes.subtitle}>
                    {consult.indications === ""
                      ? "Sin Datos"
                      : consult.indications}
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 1 }}>
            <FlatButton
              title="Regresar"
              widthBtn="120px"
              roleUser="vet"
              onClick={() => {
                history.push("/vet/consultations");
              }}
            />
            <FlatButton
              title="Ver mascota"
              widthBtn="150px"
              roleUser="vet"
              onClick={() => {
                history.push(`/vet/pets/menu/${consult.petId}`);
              }}
            />
          </Box>
        </>
      </BasicCard>
    </>
  );
}
