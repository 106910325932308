import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Modal from "@mui/material/Modal";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import Alert from "./BasicModal";
import FlatButton from "./FlatButton";
import InputTextOutlined from "./InputTextOutlined";
import { UpdateVetInfo, GetVetInfo } from "../Vet/Infrastructure/Vet.presenter";
import { getModel } from "../Vet/Infrastructure/Vet.reducer";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minHeight: "300px",
    borderRadius: "30px",
    background: "#fff",
    boxShadow:
      "0px 4px 4px rgba(0, 0, 0, 0.25), 4px 0px 5px rgba(0, 0, 0, 0.25);",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    textAlign: "center",
    flexDirection: "column",
    padding: "20px",
    paddingTop: "30px",
    [theme.breakpoints.up("sm")]: {
      width: "300px",
    },
    [theme.breakpoints.up("md")]: {
      width: "400px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "555px",
    },
  },
  paragraph: {
    maxWidth: "320px",
    margin: "1rem",
  },
  list: {
    textAlign: "start",
    color: theme.palette.primary.dark,
  },
  error: {
    maxWidth: "370px",
    margin: "1rem 0",
    color: "#E30613",
  },
  title: {
    marginBottom: "20px",
  },
}));

export default function LicenseModal({ isOpen, handleClose }) {
  const classes = useStyles();
  const vet = useSelector(getModel);
  const [values, setValues] = useState({
    address: "",
    license: "",
    name: "",
    phone: "",
  });
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
  });
  function closeAlert() {
    setAlert({ ...alert, open: false });
  }
  function showAlert(text, title, type) {
    setAlert({
      title,
      text,
      open: true,
      type,
    });
  }
  const history = useHistory();

  const licenseChange = (e) => {
    setValues({ ...values, license: e.target.value });
  };
  async function updateLicense(e) {
    e.preventDefault();
    setLoading(true);
    try {
      const { valid } = await UpdateVetInfo(
        values.address,
        values.license,
        values.name,
        values.phone
      );

      if (valid) {
        await GetVetInfo();
        showAlert("Su cédula profesional ha sido modificada exitosamente", "Cédula modificada", "success");
        setSuccess(true);
        setTimeout(()=>{ handleClose();},3000);
      } else {
        setError(true);
      }
    } catch (err) {
      setError(true);
    }
    setLoading(false);
  }

  useEffect(async () => {
    if (vet) {
      setValues({
        address: vet.address,
        license: vet.license,
        name: vet.name,
        phone: vet.phone,
      });
    } else {
      const { info } = await GetVetInfo();
      setValues({
        address: info.address,
        license: info.license,
        name: info.name,
        phone: info.phone,
      });
    }
  }, []);

  useEffect(() => {
    if (values.license.trim() !== "") {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [values.license]);

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <div className={classes.container}>
        <h2 className={classes.title}>No tiene una cédula profesional</h2>
        <p className={classes.paragraph}>
          Para acceder a todos los beneficios de vetech debe ingresar su cédula
          profesional.
        </p>
        <div className={`${classes.paragraph} ${classes.list}`}>
          <ul>
            <li>Crear consultas</li>
            <li>Ver perfil completo de mascotas</li>
            <li>Ver información completa de consultas</li>
          </ul>
        </div>
        <Alert alert={alert} handleClose={closeAlert} />
        {error ? (
          <p className={classes.error}>
            No se pudo actualizar su información en este momento, intente editar
            su cédula profesional desde su perfil.
          </p>
        ) : (
          <InputTextOutlined
            roleUser="vet"
            type="text"
            label="Agregar mi cédula"
            name="license"
            placeholder="Cédula profesional"
            value={values.license}
            onChange={licenseChange}
            widthField="280px"
          />
        )}
        {!error ? (
          <FlatButton
            title="Actualizar cédula"
            widthBtn="270px"
            roleUser="vet"
            onClick={updateLicense}
            loading={loading}
            isDisabled={disabled}
          />
            
        ) : (
          <FlatButton
            title="Editar perfil"
            widthBtn="270px"
            roleUser="vet"
            onClick={() => history.push("/vet/update")}
          />
        )}
        <br />
        <FlatButton
          title={error || success ? "Cerrar" : "En otro momento"}
          roleUser="vet"
          widthBtn="270px"
          onClick={handleClose}
          loading={loading}
        />
      </div>
    </Modal>
  );
}

LicenseModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
