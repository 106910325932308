import {
  RegisterOwnerService,
  GetOwnerInfoService,
  UpdateOwnerInfoService,
} from "./Owner.service";
import OwnerModel from "../Domain/Owner.model";

export async function GetOwnerInfoRepository(id) {
  try {
    const { data } = await GetOwnerInfoService(id);
    return {
      valid: true,
      info: new OwnerModel(
        data.item.id,
        "owner",
        data.item.email,
        data.item.name,
        data.item.profile_photo
      ),
    };
  } catch (error) {
    if (error.response)
      return { valid: false, info: error.response.data.error };
    return { valid: false, info: error.message };
  }
}

export async function RegisterOwnerRepository(ownerModel, password) {
  try {
    const { data } = await RegisterOwnerService(ownerModel, password);
    return {
      valid: true,
      info: `El dueño ${data.item.name} ha sido registrado con éxito`,
    };
  } catch (error) {
    if (error.response)
      return { valid: false, info: error.response.data.error };
    return { valid: false, info: error.message };
  }
}

export async function UpdateOwnerInfoRepository(id, name) {
  try {
    await UpdateOwnerInfoService(id, name);
    return {
      valid: true,
      info: "La información ha sido actualizada con éxito",
    };
  } catch (error) {
    if (error.response)
      return { valid: false, info: error.response.data.error };
    return { valid: false, info: error.message };
  }
}
