import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import BasicForm from "../../DesignSystem/BasicForm";
import FlatButton from "../../DesignSystem/FlatButton";
import InputTextOutlined from "../../DesignSystem/InputTextOutlined";
import FooterImage from "../../DesignSystem/FooterImage";
import footerImage from "../../assets/footer-image1.png";
import footerCat from "../../assets/footerCat.png";

import { RegisterOwner } from "../Infrastructure/Owner.presenter";
import {
  validateEmail,
  validatePassword,
  confirmPassword,
  validateName,
} from "../../User/Domain/User.model";
import Alert from "../../DesignSystem/BasicModal";

const useStyles = makeStyles((theme) => ({
  boxStyle: {
    minWidth: "320px",
    minHeight: "100vh",
    [theme.breakpoints.up("lg")]: {
      backgroundColor: theme.palette.secondary.back,
      display: "grid",
      paddingTop: "50px",
      gridTemplateColumns: "repeat(2, 1fr)",
      justifyItems: "center",
      alignItems: "end",
    },
  },
  titleContainer: {
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
    [theme.breakpoints.up("lg")]: {
      paddingTop: "100px",
      justifySelf: "start",
      alignSelf: "start",
      paddingLeft: "50px",
    },
  },
  footer: {
    [theme.breakpoints.down("md")]: {
      height: "100%",
      clipPath: "polygon(50% 0%, 100% 38%, 100% 100%, 0 100%, 0% 38%)",
      backgroundRepeat: "no-repeat",
    },
    [theme.breakpoints.up("md")]: {
      height: "100%",
      clipPath: "polygon(50% 0%, 100% 38%, 100% 100%, 0 100%, 0% 38%)",
      backgroundRepeat: "no-repeat",
    },
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
}));

export default function OwnerRegisterUI() {
  const [values, setValues] = useState({
    email: "",
    password: "",
    passwordRepeat: "",
    name: "",
  });
  const [errors, setErrors] = useState({
    email: { error: false, helper: "" },
    password: { error: false, helper: "" },
    passwordRepeat: { error: false, helper: "" },
    name: { error: false, helper: "" },
  });
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [breakpoint, setBreakpoint] = React.useState(
    window.innerWidth <= 425 ? footerImage : footerCat
  );
  const history = useHistory();
  const classes = useStyles();

  const handleResizeImage = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 425) {
      setBreakpoint(footerImage);
    } else if (screenWidth > 425) {
      setBreakpoint(footerCat);
    }
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleResizeImage);
    return () => {
      window.removeEventListener("resize", handleResizeImage);
    };
  }, []);

  const [alert, setAlert] = useState({
    open: false,
  });
  function closeAlert() {
    setAlert({ ...alert, open: false });
  }
  function showAlert(text, title = "Ocurrió un error", type = "error") {
    setAlert({
      title,
      text,
      open: true,
      type,
    });
  }

  function titleCase(cadena) {
    const regExpr =
      /(^|[^A-Za-zÁÉÍÓÚÜÑáéíóúüñ])(?:([a-záéíóúüñ])|([A-ZÁÉÍÓÚÜÑ]))|([A-ZÁÉÍÓÚÜÑ]+)/gu;
    return cadena.replace(
      regExpr,
      (m, caracterPrevio, minuscInicial, mayuscInicial, mayuscIntermedias) => {
        const locale = ["es", "gl", "ca", "pt", "en"];
        if (mayuscIntermedias) {
          return mayuscIntermedias.toLocaleLowerCase(locale);
        }
        return (
          caracterPrevio +
          (minuscInicial
            ? minuscInicial.toLocaleUpperCase(locale)
            : mayuscInicial)
        );
      }
    );
  }

  useEffect(() => {
    if (
      values.email.trim() !== "" &&
      values.password !== "" &&
      values.passwordRepeat !== "" &&
      values.name.trim() !== "" &&
      !errors.email.error &&
      !errors.password.error &&
      !errors.passwordRepeat.error &&
      !errors.name.error
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [values]);

  async function handleRegister(e) {
    setLoading(true);
    e.preventDefault();
    try {
      const { valid, info } = await RegisterOwner(
        values.email,
        values.password,
        values.passwordRepeat,
        titleCase(values.name).trim()
      );

      if (valid) {
        history.push("/successful/owner");
      } else {
        showAlert(info?.error?.message || info?.message);
      }
    } catch (error) {
      showAlert("Ocurrió un error al realizar tu registro");
    }
    setLoading(false);
  }

  const handleInputChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });

    switch (e.target.name) {
      case "password": {
        const passwordValidation = validatePassword(e.target.value);
        const passwordRepeatValidation = confirmPassword(
          e.target.value,
          values.passwordRepeat
        );
        setErrors({
          ...errors,
          password: {
            error: !passwordValidation.valid,
            helper: passwordValidation.info,
          },
          passwordRepeat: {
            error: !passwordRepeatValidation.valid,
            helper: passwordRepeatValidation.info,
          },
        });
        break;
      }
      case "passwordRepeat": {
        const { valid, info } = confirmPassword(
          values.password,
          e.target.value
        );
        setErrors({
          ...errors,
          [e.target.name]: { error: !valid, helper: info },
        });
        break;
      }
      case "email": {
        const { valid, info } = validateEmail(e.target.value);
        setErrors({
          ...errors,
          [e.target.name]: { error: !valid, helper: info },
        });
        break;
      }
      case "name": {
        const { valid, info } = validateName(e.target.value);
        setErrors({
          ...errors,
          [e.target.name]: { error: !valid, helper: info },
        });
        break;
      }
      default:
        break;
    }
  };

  return (
    <Box className={classes.boxStyle}>
      <Box className={classes.titleContainer}>
        <Typography variant="h1">¡Regístrate ya!</Typography>
        <Typography variant="subtitle1">
          Para poder disfrutar de nuestro servicio debes crear una cuenta.
        </Typography>
      </Box>
      <BasicForm
        heightDiv="110vh"
        header
        roleUser="owner"
        onSubmit={handleRegister}
      >
        <>
          <Typography variant="h5" color="secondary">
            Regístrate
          </Typography>

          <InputTextOutlined
            roleUser="owner"
            type="text"
            label="*Nombre completo"
            name="name"
            placeholder="Escribe tu nombre"
            variant="standard"
            value={values.name}
            onChange={handleInputChange}
            // error={errors.name.error}
            helperText={errors.name.helper}
          />

          <InputTextOutlined
            roleUser="owner"
            type="text"
            label="*Correo electrónico"
            name="email"
            placeholder="Ingresa tu correo electrónico"
            variant="standard"
            value={values.email}
            onChange={handleInputChange}
            helperText={errors.email.helper}
          />

          <InputTextOutlined
            roleUser="owner"
            type="password"
            label="*Contraseña"
            name="password"
            placeholder="Ingresa tu contraseña"
            variant="standard"
            value={values.password}
            onChange={handleInputChange}
            helperText={errors.password.helper}
          />

          <InputTextOutlined
            roleUser="owner"
            type="password"
            label="*Contraseña"
            name="passwordRepeat"
            placeholder="Confirma tu contraseña"
            variant="standard"
            value={values.passwordRepeat}
            onChange={handleInputChange}
            helperText={errors.passwordRepeat.helper}
          />

          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <FlatButton
              title="Atrás"
              widthBtn="150px"
              roleUser="owner"
              typeButton="button"
              onClick={() => {
                history.push("/welcome");
              }}
              icon={false}
              loading={loading}
            />
            <FlatButton
              title="Registrarse"
              widthBtn="150px"
              roleUser="owner"
              typeButton="submit"
              loading={loading}
              isDisabled={disabled}
            />
          </Box>
        </>
        <Alert alert={alert} handleClose={closeAlert} />
      </BasicForm>
      <div className={classes.footerShadow} />
      <div className={classes.footer}>
        <FooterImage fimage={breakpoint} />
      </div>
    </Box>
  );
}
