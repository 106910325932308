class UserModel {
  constructor(id = "", role, email, name, profilePicture) {
    this.email = email;
    this.id = id;
    this.name = name;
    this.profilePicture = profilePicture;
    this.role = role;
  }
}

export function validatePassword(password) {
  const regularExpression = /(?=^.{8,}$)(?=.*\d)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
 
  if(!regularExpression.test(password)){
    return {
      valid: false,
      info: "La contraseña debe tener al menos 8 caracteres, una mayúscula, un número y una letra minúscula.",
    };
  }
  return { valid: true, info: "" };
}

export function validateEmail(email) {
  const regEx = /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/;

  if (!regEx.test(email)) {
    return { valid: false, info: "Formato de correo inválido." };
  }
  return { valid: true, info: "" };
}

export function confirmPassword(password, passwordRepeat) {
  if (!password || !passwordRepeat || password !== passwordRepeat) {
    return { valid: false, info: "Las contraseñas no coinciden." };
  }
  return { valid: true, info: "" };
}

export function validateName(name) {
  const validExpreOwner = /^[ÁÉÍÓÚA-ZÑ][a-záéíóúñ]+(\s+[ÁÉÍÓÚA-ZÑ]?[a-záéíóúñ-ÿ]+)*$/gi;
  
  if (name.replace(/ /g, "") === "" || !validExpreOwner.test(name)) {
    return {
      valid: false,
      info: "El nombre sólo debe contener letras y por lo menos una sílaba",
    };
  }
  return { valid: true, info: "" };
}

export function validatePhone(phone){
  const validExpr = /^(\(\+?\d{2,3}\)[\s|\-.]?(([\d][\s|\-.]?){8})(([\d][\s|\-.]?){2})?|(\+?[\d][\s|\-.]?){10}(([\d][\s|\-.]?){2}(([\d][\s|\-.]?){2})?)?)$/;
  
  if (!validExpr.test(phone)) {
    return {
      valid: false,
      info: "Número telefónico inválido",
    };
  }
  return { valid: true, info: "" };
}

export function validateCode(code){
  const validExpr = /[0-9]{6}$/;
  if(!validExpr.test(code) || code.length !== 6){
    return{
      valid: false,
      info: "El código debe contener 6 dígitos númericos",
    };
  }
  return { valid: true, info: "" };
} 

// TODO: Hacer validación de teléfono, dirección y cédula profesional

export default UserModel;
