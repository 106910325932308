/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";

import { TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";

import showIcon from "../assets/show.svg";
import hideIcon from "../assets/hide.svg";

const useStyles = makeStyles((theme) => ({
  root: ({ widthField }) => ({
    width: widthField,
    [theme.breakpoints.down("sm")]: {
      width: "300px",
    },
    background: "#fff",
  }),
  showIcon: {
    opacity: 0.4,
    width: "2.5rem",
    height: "1.8rem",
    cursor: "pointer",
    position: "absolute",
    right: "0.8rem",
    top: "2rem",
    "&:hover": {
      opacity: 0.9,
    },
  },
}));

export default function InputTextOutlined({
  roleUser,
  type,
  name,
  label,
  placeholder,
  value,
  onChange,
  required,
  disabled,
  multiline,
  error,
  helperText,
  widthField,
  variant,
}) {
  const classes = useStyles({ widthField });

  const isPassword = useMemo(() => type === "password", [type]);
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword((current) => !current);
  };

  if (isPassword)
    return (
      <div style={{ position: "relative", width: "inherit" }}>
        <TextField
          className={classes.root}
          type={showPassword ? "text" : "password"}
          name={name}
          label={label}
          placeholder={placeholder}
          color={roleUser === "vet" ? "primary" : "secondary"}
          value={value}
          onChange={onChange}
          required={required}
          disabled={disabled}
          multiline={multiline}
          rows={4}
          error={error}
          helperText={helperText}
          variant={variant}
          sx={{
            position: "relative",
            input: { paddingRight: isPassword ? "4rem" : "" },
          }}
        />
        <img
          alt={showPassword ? "hide" : "show"}
          onClick={handleShowPassword}
          src={showPassword ? hideIcon : showIcon}
          className={classes.showIcon}
        />
      </div>
    );

  return (
    <TextField
      className={classes.root}
      type={type}
      name={name}
      label={label}
      placeholder={placeholder}
      color={roleUser === "vet" ? "primary" : "secondary"}
      value={value}
      onChange={onChange}
      required={required}
      disabled={disabled}
      multiline={multiline}
      rows={4}
      error={error}
      helperText={helperText}
      variant={variant}
    />
  );
}

InputTextOutlined.defaultProps = {
  required: false,
  disabled: false,
  multiline: false,
  error: false,
  helperText: "",
  widthField: "100%",
  variant: "outlined",
  onChange: () => null,
};

InputTextOutlined.propTypes = {
  roleUser: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  multiline: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  widthField: PropTypes.string,
  variant: PropTypes.string,
};
