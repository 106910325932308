import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";

import blueDog from "../assets/blue_dog.svg";
import greenDog from "../assets/green_dog.svg";

const useStyles = makeStyles({
  root: ({ roleUser }) => ({
    width: "100%",
    minHeight: "100vh",
    backgroundColor: "#fff",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center top",
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    backgroundImage: `url(${roleUser === "vet" ? blueDog : greenDog})`,
  }),
});

export default function Background({ children }) {
  const classes = useStyles();

  return <div className={classes.root}>{children}</div>;
}

Background.defaultProps = {};

Background.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
