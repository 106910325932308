import React from "react";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";

import greenIcon from "../assets/green_pets.svg";
import blueIcon from "../assets/blue_pets.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "90%",
    maxWidth: 490,
    minHeight: 220,
    height: "auto",
    maxHeight: 380,
    padding: "30px 40px",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "end",
    flexDirection: "column",
    gap: 10,
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
      minHeight: 220,
      height: "auto",
      maxHeight: 550,
    },
    [theme.breakpoints.up("sm")]: {
      padding: "20px 30px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "100%",
    },
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      alignItems: "start",
      flexDirection: "column",
    },
  },
  info: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "30px",
  },
  title: ({ role }) => ({
    marginBottom: "10px",
    fontWeight: 400,
    fontSize: "1.125rem",
    color:
      role === "vet"
        ? theme.palette.primary.main
        : theme.palette.secondary.main,
  }),
  text: {
    fontWeight: 400,
    fontSize: "1.125rem",
    color: "#BABABA",
  },
  logo: {
    width: 55,
    height: 55,
    transition: "0.4s",
    "&:hover": {
      transform: "scale(1.05)",
    },
  },
  goProfileContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "15px",
  },
  goDetailsLink: ({ role }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 8,
    color:
      role === "vet"
        ? theme.palette.primary.main
        : theme.palette.secondary.main,
    fontSize: "1.2rem",
    fontWeight: 700,
    textDecoration: "none",
    background: "none",
    "&:hover": {
      color:
        role === "vet"
          ? theme.palette.primary.dark
          : theme.palette.secondary.dark,
    },
  }),
}));

export default function VaccinationCard({ id, name, date, dosis, petId }) {
  const role = localStorage.getItem("role");
  const classes = useStyles({ role });
  return (
    <Card
      className={classes.root}
      sx={{
        boxShadow:
          "0px 4px 4px rgba(0, 0, 0, 0.25), 4px 0px 5px rgba(0, 0, 0, 0.25)",
        borderRadius: "10px",
      }}
    >
      <div className={classes.container}>
        <div>
          <h3 className={classes.title}>Vacuna</h3>
          <p className={classes.text}>{name}</p>
        </div>
        <div className={classes.info}>
          <div>
            <h3 className={classes.title}>Fecha de aplicación</h3>
            <p className={classes.text}>{date}</p>
          </div>
          <div>
            <h3 className={classes.title}>Dosis</h3>
            <p className={classes.text}>{dosis}</p>
          </div>
        </div>
      </div>
      <div className={classes.goProfileContainer}>
        <Link
          className={classes.goProfileLink}
          to={`/${role}/pets/menu/${petId}`}
        >
          <img
            className={classes.logo}
            src={role === "vet" ? greenIcon : blueIcon}
            alt="icon pets"
          />
        </Link>
        <Link
          className={classes.goDetailsLink}
          to={`/${role}/pets/vaccination/${id}`}
        >
          Más información
          <ArrowRightAltIcon />
        </Link>
      </div>
    </Card>
  );
}

VaccinationCard.propTypes = {
  id: PropTypes.string.isRequired,
  petId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  dosis: PropTypes.string.isRequired,
};
