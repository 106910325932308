import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { useSelector } from "react-redux";
import BasicForm from "../../DesignSystem/BasicForm";
import FlatButton from "../../DesignSystem/FlatButton";
import BasicCard from "../../DesignSystem/BasicCard.ui";
import InputTextOutlined from "../../DesignSystem/InputTextOutlined";
import DatePicker from "../../DesignSystem/DatePicker.ui";
import BasicSelect from "../../DesignSystem/BasicSelect.ui";

import { RegisterConsultation } from "../Infrastructure/Consultation.presenter";
import { getOwnerEmailSearched } from "../../Vet/Infrastructure/Vet.reducer";
import { getPetModel } from "../../Pet/Infrastructure/Pet.reducer";
import Alert from "../../DesignSystem/BasicModal";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    width: "100%",
    [theme.breakpoints.up("lg")]: {
      flexDirection: "row",
    },
  },
  container: {
    width: "320px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.up("lg")]: {
      paddingBottom: "20px",
    },
  },
  flex: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "300px",
  },
}));

function ConsultationRegister() {
  const [values, setValues] = useState({
    nextDate: new Date(),
    nextDateSelect: "No",
    weight: "",
    review: "",
    diagnosis: "",
    treatment: "",
    indications: "",
    temperature: "",
  });
  const [nextDate, setNextDate] = useState({
    size: "300px",
    label: "¿Se requiere siguiente cita?",
    visible: false,
  });
  const [registerDisabled, setRegisterDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const classes = useStyles();
  const ownerEmail = useSelector(getOwnerEmailSearched);
  const pet = useSelector(getPetModel);

  const [alert, setAlert] = useState({
    open: false,
  });
  function closeAlert() {
    setAlert({ ...alert, open: false });
    if (alert.type === "success") {
      history.push(`/vet/pets/menu/${pet.id}`);
    }
  }
  function showAlert(text, title = "Ocurrió un error", type = "error") {
    setAlert({
      title,
      text,
      open: true,
      type,
    });
  }

  async function handleRegister(e) {
    e.preventDefault();
    setLoading(true);
    try {
      const { valid, info } = await RegisterConsultation(
        pet.id,
        ownerEmail,
        values.review,
        values.diagnosis,
        values.treatment,
        values.indications,
        values.weight,
        values.temperature,
        values.nextDateSelect === "No"
          ? ""
          : values.nextDate.toISOString().slice(0, -1)
      );
      if (valid) {
        showAlert(info.message, "Consulta creada", "success");
      } else {
        showAlert(info.message);
      }
    } catch (error) {
      showAlert(error);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (
      values.treatment !== "" &&
      values.diagnosis !== "" &&
      values.review !== ""
    ) {
      setRegisterDisabled(false);
    } else {
      setRegisterDisabled(true);
    }
    if (values.nextDateSelect === "Sí") {
      setNextDate({ size: "120px", label: "Siguiente cita", visible: true });
    } else {
      setNextDate({
        size: "300px",
        label: "¿Se requiere siguiente cita?",
        visible: false,
      });
    }
  }, [values]);

  const handleInputChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleDateChange = (e) => {
    // eslint-disable-next-line no-underscore-dangle
    setValues({ ...values, nextDate: e._d });
  };

  if (!ownerEmail || !pet) {
    history.push("/vet/pets/list");
  }
  return (
    <BasicCard>
      <>
        <Typography
          style={{ textAlign: "center", marginBottom: "20px" }}
          variant="h5"
          color="primary"
        >
          Nueva consulta
        </Typography>
        <div className={classes.mainContainer}>
          <div className={classes.container}>
            <InputTextOutlined
              roleUser="vet"
              type="number"
              label="Peso de la mascota"
              name="weight"
              placeholder="0.0kg"
              value={values.weight}
              onChange={handleInputChange}
              widthField="300px"
            />

            <InputTextOutlined
              roleUser="vet"
              type="text"
              label="*Reseña"
              name="review"
              placeholder="Escribe tu reseña"
              value={values.review}
              onChange={handleInputChange}
              widthField="300px"
              multiline
            />
            <InputTextOutlined
              roleUser="vet"
              type="text"
              label="*Diagnóstico"
              name="diagnosis"
              placeholder="Indica tu diagnóstico"
              value={values.diagnosis}
              onChange={handleInputChange}
              widthField="300px"
              multiline
            />
            <InputTextOutlined
              roleUser="vet"
              type="number"
              label="Temperatura"
              name="temperature"
              placeholder="Temperatura de la mascota °C"
              value={values.temperature}
              onChange={handleInputChange}
              widthField="300px"
            />
          </div>

          <BasicForm roleUser="owner" onSubmit={handleRegister}>
            <>
              <InputTextOutlined
                roleUser="vet"
                type="text"
                label="*Tratamiento"
                name="treatment"
                placeholder="Indica el tratamiento"
                value={values.treatment}
                onChange={handleInputChange}
                widthField="300px"
                multiline
              />
              <InputTextOutlined
                roleUser="vet"
                type="text"
                label="Indicaciones"
                name="indications"
                placeholder="Escribe tus indicaciones"
                value={values.indications}
                onChange={handleInputChange}
                widthField="300px"
                multiline
              />

              <div className={classes.flex}>
                <BasicSelect
                  label={nextDate.label}
                  selectWidth={nextDate.size}
                  name="nextDateSelect"
                  value={values.nextDateSelect}
                  handleChange={handleInputChange}
                  options={["No", "Sí"]}
                />
                <div
                  style={{
                    display: nextDate.visible ? "block" : "none",
                  }}
                >
                  <DatePicker
                    value={values.nextDate}
                    onChange={handleDateChange}
                    width="160px"
                    label="Próxima consulta"
                    disablePast
                  />
                </div>
              </div>
              <br />
              <div className={classes.flex}>
                <FlatButton
                  title="Cancelar"
                  widthBtn="138px"
                  roleUser="vet"
                  loading={loading}
                  onClick={() => history.push("/vet/pets/consultations")}
                />
                <FlatButton
                  title="Guardar"
                  widthBtn="138px"
                  isDisabled={registerDisabled}
                  loading={loading}
                  roleUser="vet"
                  typeButton="submit"
                />
              </div>
            </>
          </BasicForm>
        </div>
        <Alert alert={alert} handleClose={closeAlert} />
      </>
    </BasicCard>
  );
}
export default ConsultationRegister;
