import {
  getManyConsultation,
  getManyVaccinations,
  getOneVaccination,
  postOneVaccination,
} from "../../Provider.new";

// Ver información de una vacuna
export function GetVaccinationInfoService(ownerEmail, petId, vaccinationID) {
  return getOneVaccination(
    "owners",
    ownerEmail,
    petId,
    vaccinationID
  );
}

// Ver lista de vacunas de una mascota como dueño y veterinario
export function GetVaccinationListService(email, petId, filters) {
  return getManyVaccinations("owners", email, petId, filters);
}

// Ver lista de vacunas de una mascota como veterinario
export function GetVaccinationListVetService(email, petId, filters) {
  return getManyConsultation("vets", email, petId, filters);
}

// Crear una vacuna
export function RegisterVaccinationService(
  vaccinationModel,
  vetId,
  petId,
  ownerEmail
) {
  const body = { ...vaccinationModel, next_date: vaccinationModel.nextDate };
  return postOneVaccination(
    "vets",
    body,
    vetId,
    petId,
    ownerEmail,
    "vaccinations"
  );
}
