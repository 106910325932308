import React from "react";
import { useHistory } from "react-router-dom";

import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { getPetModel } from "../Infrastructure/Pet.reducer";

import FlatButton from "../../DesignSystem/FlatButton";
import BasicCard from "../../DesignSystem/BasicCard.ui";

import greenPaw from "../../assets/paw_finger_green.svg";
import bluePaw from "../../assets/paw_finger.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "75%",
    alignItems: "center",
    [theme.breakpoints.up("lg")]: {
      display: "flex",
      columnGap: "30px",
      flexWrap: "wrap",
      justifyContent: "space-around",
    },
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
    },
  },
  photo: {
    height: "220px",
    width: "220px",
    marginTop: 30,
    borderRadius: "100% 50% 100% 50%",
    objectFit: "cover",
    [theme.breakpoints.down("lg")]: {
      height: "178px",
      width: "178px",
      marginLeft: "auto",
      marginRight: "auto",
      borderRadius: "100% 50% 100% 50%",
      display: "flex",
      flexDirection: "column",
    },
  },
  body: {
    margin: "0px 50px",
    [theme.breakpoints.down("lg")]: {
      margin: 0,
    },
  },
  name: ({ roleUser }) => ({
    textAlign: "center",
    color:
      roleUser === "vet"
        ? theme.palette.primary.main
        : theme.palette.secondary.main,
    marginTop: 30,
    marginBottom: 10,
    fontSize: "1.5rem",
  }),
  title: ({ roleUser }) => ({
    color:
      roleUser === "vet"
        ? theme.palette.primary.main
        : theme.palette.secondary.main,
    marginTop: 30,
    marginBottom: 10,
    fontWeight: 400,
  }),
  subtitle: { color: "#BABABA" },
  secondColumn: {
    alignSelf: "end",
    paddingBottom: "5px",
    justifySelf: "start",
  },
}));

export default function PetProfileUI() {
  const roleUser = localStorage.getItem("role");
  const history = useHistory();
  const classes = useStyles({ roleUser });
  const img = roleUser === "vet" ? greenPaw : bluePaw;
  const pet = useSelector(getPetModel);

  if (!pet) {
    history.push(`/${roleUser}/pets/list`);
  }

  return (
    pet && (
      <BasicCard>
        <div className={classes.root}>
          <div>
            <img
              className={classes.photo}
              src={pet.picture === "" ? img : pet.picture}
              alt="photoPet"
            />
            <h3 className={classes.name}>{pet.name}</h3>
          </div>

          <div className={classes.columns}>
            <h4 className={classes.title}>Fecha de nacimiento</h4>
            <p className={classes.subtitle}>{pet.birth}</p>
            <h4 className={classes.title}>Raza</h4>
            <p className={classes.subtitle}>{pet.breed}</p>
            <h4 className={classes.title}>Sexo</h4>
            <p className={classes.subtitle}>{pet.gender}</p>
            <h4 className={classes.title}>Especie</h4>
            <p className={classes.subtitle}>{pet.kind}</p>
          </div>

          <div className={classes.secondColumn}>
            <h4 className={classes.title}>Esterilizado</h4>
            <p className={classes.subtitle}>{pet.neutered ? "Sí" : "No"}</p>
            <h4 className={classes.title}>Alergias</h4>
            <p className={classes.subtitle}>
              {pet.allergies.trim() === "" ? "Sin datos" : pet.allergies}
            </p>
            <h4 className={classes.title}>Cirugías</h4>
            <p className={classes.subtitle}>
              {pet.surgeries.trim() === "" ? "Sin datos" : pet.surgeries}
            </p>
          </div>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              width: "100%",
              marginTop: 3,
              pt: 1,
            }}
          >
            <FlatButton
              title="Atrás"
              widthBtn="120px"
              roleUser={roleUser}
              onClick={() => {
                history.push(`/${roleUser}/pets/menu/${pet.id}`);
              }}
            />
            {roleUser === "owner" && (
              <FlatButton
                className={classes.btnDesktop}
                title="Editar"
                widthBtn="120px"
                roleUser={roleUser}
                onClick={() => {
                  history.push(`/${roleUser}/pets/update`);
                }}
              />
            )}
          </Box>
        </div>
      </BasicCard>
    )
  );
}
