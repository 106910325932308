import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "owner",

  initialState: {
    model: null,
  },

  reducers: {
    setModel: (state, { payload: model }) => ({
      ...state,
      model,
    }),
  },
});

export const { setModel } = slice.actions;

export default slice.reducer;

export const getModel = (store) => store.owner.model;
