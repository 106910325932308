import React from "react";
import { useHistory } from "react-router-dom";

import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import FlatButton from "../../DesignSystem/FlatButton";
import BasicForm from "../../DesignSystem/BasicForm";
import logo from "../../assets/Imagotipo.svg";
import petImage from "../../assets/corto-z.jpg";
import petImg from "../../assets/bulldog.png";
import catDog from "../../assets/catDog.png";
import dogImg from "../../assets/perro2.jpg";
import catImg from "../../assets/gato4.jpg";

const useStyles = makeStyles((theme) => ({
  boxStyle: {
    minWidth: "320px",
    minHeight: "100vh",
    [theme.breakpoints.up("lg")]: {
      backgroundColor: theme.palette.secondary.back,
      display: "grid",
      gridTemplateColumns: "repeat(2, 1fr)",
      paddingTop: "50px",
      justifyItems: "center",
      alignItems: "end",
    },
  },
  titleContainer: {
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
    [theme.breakpoints.up("lg")]: {
      paddingTop: "100px",
      justifySelf: "start",
      alignSelf: "start",
      paddingLeft: "50px",
    },
  },
  logo: {
    width: 150,
    [theme.breakpoints.down("lg")]: {
      maxWidth: 255,
      minWidth: 200,
      minHeight: 158,
      marginBottom: 20,
    },
  },
  welcomeImage: {
    maxWidth: 275,
    justifySelf: "center",
    alignSelf: "center",
    borderRadius: "100% 50% 100% 50%",
    marginLeft: "100px",
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
  },
}));

export default function Index() {
  const history = useHistory();
  const classes = useStyles();

  const images = [petImage, petImg, catDog, dogImg, catImg];
  const index = Math.floor(Math.random() * images.length);

  async function handleSubmit(e) {
    e.preventDefault();
  }

  return (
    <Box className={classes.boxStyle}>
      <Box className={classes.titleContainer}>
        <Typography variant="h1">Bienvenido</Typography>
        <Typography variant="subtitle1">
          El mejor servicio para tu mascota te espera.
        </Typography>
        <img
          className={classes.welcomeImage}
          src={images[index]}
          alt="mascota"
        />
      </Box>
      <BasicForm header onSubmit={handleSubmit}>
        <>
          <img className={classes.logo} src={logo} alt="logo Vetech" />
          <FlatButton
            title="Soy Veterinario"
            widthBtn="200px"
            roleUser="vet"
            onClick={() => {
              history.push("/register/vet");
            }}
          />
          <FlatButton
            title="Soy Dueño"
            widthBtn="200px"
            roleUser="owner"
            onClick={() => {
              history.push("/register/owner");
            }}
          />

          <Typography variant="subtitle2" color="gray">
            ¿Ya tienes cuenta?
          </Typography>
          <FlatButton
            title="Inicia sesión"
            widthBtn="180px"
            roleUser="owner"
            isDisabled={false}
            icon={false}
            onClick={() => {
              history.push("/login");
            }}
          />
        </>
      </BasicForm>
    </Box>
  );
}
