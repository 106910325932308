import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: "30px",
    margin: "70px 0",
    background: "#fff",
    boxShadow:
      "0px 4px 4px rgba(0, 0, 0, 0.25), 4px 0px 5px rgba(0, 0, 0, 0.25);",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: "20px",
    [theme.breakpoints.up("sm")]: {
      width: "333px",
    },
    [theme.breakpoints.up("md")]: {
      width: "478px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "938px",
    },
  },
}));

export default function BasicCard({ children }) {
  const classes = useStyles();

  return <div className={classes.root}>{children}</div>;
}

BasicCard.defaultProps = {};

BasicCard.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
