import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "pet",

  initialState: {
    petModel: null,
  },

  reducers: {
    setPetModel: (state, { payload: petModel }) => ({
      ...state,
      petModel,
    }),
  },
});

export const { setPetModel } = slice.actions;

export default slice.reducer;

export const getPetModel = (store) => store.pet.petModel;
