import UserModel from "../../User/Domain/User.model";

class VetModel extends UserModel {
  constructor(license, address, phone, ...rest) {
    super(...rest);
    this.license = license;
    this.address = address;
    this.phone = phone;
  }
}

export default VetModel;
