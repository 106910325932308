import RegisterVaccinationLogic from "../Application/RegisterVaccination.logic";
import GetVaccinationListLogic from "../Application/VaccinationList.logic";
import GetVaccinationInfoLogic from "../Application/GetVaccinationInfo.logic";
import store from "../../redux/store";
import { setOwnerEmailSearched } from "../../Vet/Infrastructure/Vet.reducer";

export async function RegisterVaccination(
  petId,
  ownerEmail,
  name,
  dosis,
  weight,
  brand,
  serial,
  effects,
  recommendations,
  nextDate
) {
  return RegisterVaccinationLogic(
    localStorage.getItem("id"),
    petId,
    ownerEmail,
    name,
    dosis,
    weight,
    brand,
    serial,
    effects,
    recommendations,
    nextDate
  );
}

export async function GetVaccinationInfo(ownerEmail, petId, vaccinationId) {
  const {valid, info} = await GetVaccinationInfoLogic(ownerEmail, petId, vaccinationId);
  return {valid,info};
}

export default async function GetVaccinationList(email, petId, page = 1) {
  store.dispatch(setOwnerEmailSearched(email));
  return GetVaccinationListLogic(email, petId, page);
}
