import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import BasicForm from "../../DesignSystem/BasicForm";
import FlatButton from "../../DesignSystem/FlatButton";
import BasicCard from "../../DesignSystem/BasicCard.ui";
import InputTextOutlined from "../../DesignSystem/InputTextOutlined";
import DatePicker from "../../DesignSystem/DatePicker.ui";

import { RegisterPet } from "../Infrastructure/Pet.presenter";
import { uploadPhoto } from "../../Provider.new";

import bluePaw from "../../assets/paw_finger.svg";
import blueCamera from "../../assets/blue_camera.svg";
import BasicSelect from "../../DesignSystem/BasicSelect.ui";
import Alert from "../../DesignSystem/BasicModal";
import { MAX_IMAGE_SIZE, MAX_IMAGE_SIZE_TEXT } from "../../constants";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    width: "100%",
    [theme.breakpoints.up("lg")]: {
      flexDirection: "row",
    },
  },
  photoContainer: {
    marginTop: "20px",
    marginBottom: "20px",
    position: "relative",
  },
  photo: {
    width: "220px",
    height: "220px",
    objectFit: "cover",
    borderRadius: "100% 50% 100% 50%",
    [theme.breakpoints.up("lg")]: {
      width: "260px",
      height: "260px",
    },
  },
  iconButton: {
    width: "46px",
    height: "46px",
    position: "absolute",
    bottom: 0,
    left: 0,
    border: "none",
    background: "none",
    borderRadius: "50%",
    opacity: 0.8,
    "&:hover": {
      opacity: 1,
    },
    "&:active": {
      opacity: 0.9,
      transform: "scale(0.95)",
    },
  },
  iconImage: {
    width: "inherit",
    height: "inherit",
  },
  secondPhoto: {
    width: "70px",
    height: "70px",
    objectFit: "cover",
    borderRadius: "100% 50% 100% 50%",
    marginRight: "25px",
    [theme.breakpoints.up("lg")]: {
      marginRight: 0,
      marginBottom: "20px",
      width: "220px",
      height: "220px",
    },
  },
  petName: {
    fontSize: "1.5rem",
    [theme.breakpoints.up("lg")]: {
      fontSize: "2rem",
    },
  },
}));

export default function PetRegisterUI() {
  const [values, setValues] = useState({
    name: "",
    kind: "Perro",
    breed: "",
    birth: new Date(),
    allergies: "",
    neutered: "No",
    gender: "Macho",
    surgeries: "",
  });
  const [nextDisabled, setNextDisabled] = useState(true);
  const [registerDisabled, setRegisterDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [photo, setPhoto] = useState(null);
  const [photoPreview, setPhotoPreview] = useState(bluePaw);
  const [activeStep, setActiveStep] = useState(0);

  const history = useHistory();
  const classes = useStyles();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const [alert, setAlert] = useState({
    open: false,
  });
  function closeAlert() {
    setAlert({ ...alert, open: false });
    if (alert.type === "success") {
      history.push("/owner/pets/list");
    }
  }
  function showAlert(text, title = "Ocurrió un error", type = "error") {
    setAlert({
      title,
      text,
      open: true,
      type,
    });
  }

  async function handleRegister(e) {
    e.preventDefault();
    setLoading(true);
    try {
      const { valid, info } = await RegisterPet(
        values.name,
        values.kind,
        values.breed,
        values.birth.toISOString().slice(0, -1),
        values.allergies,
        values.neutered === "Sí" ? "true" : "false",
        values.gender,
        values.surgeries
      );
      if (valid) {
        try {
          if (photo !== null) {
            await uploadPhoto(
              `owners/${localStorage.getItem("email")}/pets`,
              info.id,
              photo
            );
          }
        } catch (error) {
          showAlert("Ocurrió un error al subir la foto");
        }
        showAlert(info.message, "Mascota registrada", "success");
      } else {
        showAlert(info.message);
      }
    } catch (error) {
      showAlert(error);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (
      values.name.trim() !== "" &&
      values.birth !== "" &&
      values.gender !== ""
    ) {
      setNextDisabled(false);
    } else {
      setNextDisabled(true);
    }
    if (values.breed.trim() !== "") {
      setRegisterDisabled(false);
    } else {
      setRegisterDisabled(true);
    }
  }, [values]);

  const handleInputChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleDateChange = (e) => {
    // eslint-disable-next-line no-underscore-dangle
    setValues({ ...values, birth: e._d });
  };

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    const allowedExtensions = /(.jpg|.jpeg|.png)$/i;
    if (!allowedExtensions.exec(file.name)) {
      showAlert("Elige un formato de imagen válido (.jpg, .png, .jpeg)");
    } else if (file?.size / 1024 > MAX_IMAGE_SIZE) {
      showAlert(MAX_IMAGE_SIZE_TEXT);
    } else {
      setPhoto(file);
      setPhotoPreview(URL.createObjectURL(file));
    }
  };

  function selectPhoto() {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = ".png,.jpg,.jpeg";
    fileInput.addEventListener("change", (event) => {
      handlePhotoChange(event);
    });
    fileInput.click();
    fileInput.remove();
  }

  return (
    <BasicCard>
      <>
        {activeStep === 0 ? (
          <div className={classes.container}>
            <div className={classes.photoContainer}>
              <img className={classes.photo} src={photoPreview} alt="Pet" />
              <button
                className={classes.iconButton}
                type="button"
                onClick={selectPhoto}
              >
                <img className={classes.iconImage} src={blueCamera} alt="" />
              </button>
            </div>

            <div>
              <Typography
                style={{ textAlign: "center" }}
                variant="h5"
                color="secondary"
              >
                Hola {values.name}!
              </Typography>
              <InputTextOutlined
                roleUser="owner"
                type="text"
                label="*Nombre"
                name="name"
                placeholder="Nombre de la mascota"
                value={values.name}
                onChange={handleInputChange}
                widthField="300px"
              />

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "300px",
                  justifyContent: "space-between",
                }}
              >
                <DatePicker
                  disableFuture
                  value={values.birth}
                  onChange={handleDateChange}
                  width="140px"
                  label="Fecha de nacimiento"
                />
                <BasicSelect
                  label="Sexo"
                  selectWidth="140px"
                  name="gender"
                  value={values.gender}
                  handleChange={handleInputChange}
                  options={["Macho", "Hembra"]}
                />
              </div>

              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <FlatButton
                  title="Cancelar"
                  widthBtn="138px"
                  roleUser="owner"
                  onClick={() => {
                    history.push("/owner/pets/list");
                  }}
                />
                <FlatButton
                  title="Siguiente"
                  widthBtn="138px"
                  roleUser="owner"
                  isDisabled={nextDisabled}
                  onClick={handleNext}
                />
              </Box>
            </div>
          </div>
        ) : (
          <div className={classes.container}>
            <div>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "row", lg: "column" },
                  mt: 2,
                  width: { xs: "300px", lg: "100%" },
                  alignItems: "center",
                  mb: { xs: 1, md: 2 },
                }}
              >
                <img
                  className={classes.secondPhoto}
                  src={photoPreview}
                  alt="Pet"
                />
                <Typography className={classes.petName} color="secondary">
                  Hola {values.name}!
                </Typography>
              </Box>
            </div>

            <BasicForm roleUser="owner" onSubmit={handleRegister}>
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "300px",
                    justifyContent: "space-between",
                  }}
                >
                  <BasicSelect
                    label="Especie"
                    selectWidth="140px"
                    name="kind"
                    value={values.kind}
                    handleChange={handleInputChange}
                    options={["Perro", "Gato"]}
                  />
                  <BasicSelect
                    label="Esterilizado"
                    selectWidth="140px"
                    name="neutered"
                    value={values.neutered}
                    handleChange={handleInputChange}
                    options={["Sí", "No"]}
                  />
                </div>

                <InputTextOutlined
                  roleUser="owner"
                  type="text"
                  label="*Raza"
                  name="breed"
                  placeholder="Escribe su raza"
                  value={values.breed}
                  onChange={handleInputChange}
                  widthField="300px"
                />

                <InputTextOutlined
                  roleUser="owner"
                  type="text"
                  label="Alergias"
                  name="allergies"
                  placeholder=""
                  value={values.allergies}
                  onChange={handleInputChange}
                  widthField="300px"
                  multiline
                />

                <InputTextOutlined
                  roleUser="owner"
                  type="text"
                  label="Cirugías"
                  name="surgeries"
                  placeholder=""
                  value={values.surgeries}
                  onChange={handleInputChange}
                  widthField="300px"
                  multiline
                />

                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <FlatButton
                    title="Atrás"
                    widthBtn="138px"
                    roleUser="owner"
                    onClick={handleBack}
                    loading={loading}
                  />
                  <FlatButton
                    title="Registrar"
                    widthBtn="138px"
                    isDisabled={registerDisabled}
                    loading={loading}
                    roleUser="owner"
                    typeButton="submit"
                  />
                </Box>
              </>
            </BasicForm>
          </div>
        )}
        <Alert alert={alert} handleClose={closeAlert} />
      </>
    </BasicCard>
  );
}
