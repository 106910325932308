import moment from "moment";
import PetModel from "../Domain/Pet.model";
import {
  GetPetInfoService,
  GetPetListOwnerService,
  GetPetListVetService,
  RegisterPetService,
  UpdatePetInfoService,
} from "./Pet.service";

export async function GetPetInfoRepository(id, petId) {
  try {
    const { data } = await GetPetInfoService(id, petId);
    return {
      valid: true,
      info: new PetModel(
        data.item.id,
        data.item.name,
        data.item.ownerId,
        data.item.kind,
        data.item.breed,
        moment(new Date(data.item.birth)).format("DD/MM/YYYY"),
        data.item.allergies,
        data.item.photo,
        data.item.neutered,
        data.item.gender,
        data.item.surgeries
      ),
    };
  } catch (error) {
    if (error.response)
      return { valid: false, info: error.response.data.error };
    return { valid: false, info: error.message };
  }
}

export async function GetPetListOwnerRepository(email, filters) {
  try {
    const { data } = await GetPetListOwnerService(email, filters);
    return {
      valid: true,
      info: data,
    };
  } catch (error) {
    if (error.response)
      return { valid: false, info: error.response.data.error };
    return { valid: false, info: error.message };
  }
}

export async function GetPetListVetRepository(id, email) {
  try {
    const { data } = await GetPetListVetService(id, email);
    return {
      valid: true,
      info: data.items,
    };
  } catch (error) {
    if (error.response)
      return { valid: false, info: error.response.data.error };
    return { valid: false, info: error.message };
  }
}

export async function RegisterPetRepository(petModel, ownerId) {
  try {
    const { data } = await RegisterPetService(petModel, ownerId);
    return {
      valid: true,
      info: {
        message: `La mascota ${data.item.name} ha sido registrada con éxito`,
        id: data.item.id,
      },
    };
  } catch (error) {
    if (error.response)
      return { valid: false, info: error.response.data.error };
    return { valid: false, info: error.message };
  }
}

export async function UpdatePetInfoRepository(
  id,
  petId,
  allergies,
  name,
  neutered,
  surgeries
) {
  try {
    await UpdatePetInfoService(id, petId, allergies, name, neutered, surgeries);
    return {
      valid: true,
      info: `La información de su mascota ha sido actualizada con éxito`,
    };
  } catch (error) {
    if (error.response)
      return { valid: false, info: error.response.data.error };
    return { valid: false, info: error.message };
  }
}
