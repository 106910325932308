import React from "react";
import PropTypes from "prop-types";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import FlatButton from "./FlatButton";
import warning from "../assets/warning.svg";
import success from "../assets/checkmark.svg";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minHeight: "300px",
    borderRadius: "30px",
    background: "#fff",
    boxShadow:
      "0px 4px 4px rgba(0, 0, 0, 0.25), 4px 0px 5px rgba(0, 0, 0, 0.25);",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    flexDirection: "column",
    padding: "20px",
    paddingTop: "30px",
    [theme.breakpoints.up("sm")]: {
      width: "300px",
    },
    [theme.breakpoints.up("md")]: {
      width: "400px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "555px",
    },
  },
  title: ({ type }) => ({
    color: type === "error" ? "#F20F0F" : "#419F00",
  }),
  img: {
    width: "75px",
  },
  center: {
    textAlign: "center",
  },
}));

export default function BasicModal({ alert, handleClose }) {
  const role = localStorage.getItem("role");
  const { type, open, title, text } = alert;
  const classes = useStyles({ type });

  return (
    <Modal open={open} onClose={handleClose}>
      <div className={classes.container}>
        <img
          src={type === "error" ? warning : success}
          alt="alert icon"
          className={classes.img}
        />
        <h2 className={classes.title}>{title}</h2>
        <p className={classes.center}>{text}</p>
        <FlatButton
          title="Aceptar"
          roleUser={role}
          widthBtn="180px"
          variant={type}
          onClick={handleClose}
        />
      </div>
    </Modal>
  );
}

BasicModal.propTypes = {
  alert: PropTypes.oneOfType([PropTypes.object]).isRequired,
  handleClose: PropTypes.func.isRequired,
};
