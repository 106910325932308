import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import FlatButton from "../../DesignSystem/FlatButton";
import BasicCard from "../../DesignSystem/BasicCard.ui";
import { getModel } from "../Infrastructure/Owner.reducer";
import img from "../../assets/paw_finger.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "70%",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-around",
  },
  photo: {
    height: "257px",
    width: "257px",
    objectFit: "cover",
    marginTop: 30,
    marginBottom: 30,
    borderRadius: "100% 50% 100% 50%",
    [theme.breakpoints.down("lg")]: {
      marginBottom: 0,
      height: "178px",
      width: "178px",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  photoContainer: {
    [theme.breakpoints.down("lg")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column-reverse",
    },
  },
  name: {
    fontWeight: 600,
    fontSize: "1.5rem",
    textAlign: "center",
    color: theme.palette.secondary.main,
    marginTop: 30,
    marginBottom: 10,
  },
  body: {
    [theme.breakpoints.down("lg")]: { marginBottom: 30 },
  },
  title: {
    color: theme.palette.secondary.main,
    marginTop: 50,
    marginBottom: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  subtitle: {
    color: "#BABABA",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default function OwnerProfileUI() {
  const history = useHistory();
  const classes = useStyles();
  const owner = useSelector(getModel);

  if (!owner) {
    history.push("/");
  }

  return (
    owner && (
      <BasicCard>
        <div className={classes.root}>
          <div className={classes.photoContainer}>
            <img
              className={classes.photo}
              src={owner.profilePicture === "" ? img : owner.profilePicture}
              alt="photoOwner"
            />
          </div>

          <div className={classes.body}>
            <h3 className={classes.name}>
              {" "}
              {owner.name} <br />{" "}
            </h3>
            <h4 className={classes.title}>Correo Electrónico</h4>
            <p className={classes.subtitle}>{owner.email}</p>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                marginTop: 3,
              }}
            >
              <FlatButton
                title="Atrás"
                widthBtn="120px"
                roleUser="owner"
                onClick={() => {
                  history.push("/owner/home");
                }}
              />
              <FlatButton
                title="Editar"
                widthBtn="120px"
                roleUser="owner"
                onClick={() => {
                  history.push("/owner/update");
                }}
              />
            </Box>
          </div>
        </div>
      </BasicCard>
    )
  );
}
