import {
  getMany,
  getManyConsultation,
  getOneHistoryConsultation,
  getOneConsultation,
  postOneConsultation,
} from "../../Provider.new";

// Ver una consulta desde lista de mascotas
export function GetConsultationInfoService(petId, consultationId) {
  return getOneConsultation(
    "owners",
    localStorage.getItem("email"),
    petId,
    consultationId
  );
}

// Ver una consulta como veterinario desde el historial
export function GetConsultationInfoFromHistoryService(id, consultationId) {
  return getOneHistoryConsultation("vets", id, consultationId);
}

// Ver lista de consultas de una mascota
export function GetConsultationListService(email, petId, filters) {
  return getManyConsultation("owners", email, petId, filters, "consultations");
}

// Ver lista de consultas de una mascota como veterinario
export function GetConsultationListVetService(email, petId, filters) {
  return getManyConsultation("vets", email, petId, filters);
}

// Ver historial de consultas del veterinario
export function GetConsultationHistoryService(id, filters) {
  return getMany("vets", id, filters, "consultations");
}

// Crear una consulta
export function RegisterConsultationService(
  consultationModel,
  vetId,
  petId,
  ownerEmail
) {
  const body = { ...consultationModel, next_date: consultationModel.nextDate };
  if ("next_date" in body && !body?.next_date) delete body.next_date;
  return postOneConsultation(
    "vets",
    body,
    vetId,
    petId,
    ownerEmail,
    "consultations"
  );
}
