import { createOne, getOne, updateOne } from "../../Provider.new";

export function DeleteOwnerService(id, password) {
  return updateOne("delete", id, { password });
}

export function ResetOwnerPasswordService(id, password) {
  return updateOne("owners/reset", id, { password });
}

export function UpdateOwnerInfoService(id, name) {
  return updateOne("owners", id, { name });
}

export function GetOwnerInfoService(id) {
  return getOne("owners", id);
}

export function RegisterOwnerService(ownerModel, password) {
  const body = { ...ownerModel, password };
  return createOne("owners", body);
}
