import RegisterConsultationLogic from "../Application/RegisterConsultation.logic";
import {
  GetConsultationInfoFromHistoryLogic,
  GetConsultationInfoLogic,
} from "../Application/GetConsultationInfo.logic";
import GetConsultationListLogic from "../Application/GetConsultationList.logic";
import GetConsultationHistoryLogic from "../Application/GetConsultationHistory.logic";
import store from "../../redux/store";
import { setOwnerEmailSearched } from "../../Vet/Infrastructure/Vet.reducer";

export async function GetConsultationInfoFromHistory(id, consultationId) {
  const { valid, info } = await GetConsultationInfoFromHistoryLogic(
    id,
    consultationId
  );
  return { valid, info };
}

export async function GetConsultationInfo(petId, consultationId) {
  const { valid, info } = await GetConsultationInfoLogic(petId, consultationId);
  return { valid, info };
}

export async function GetConsultationList(email, petId, page = 1) {
  store.dispatch(setOwnerEmailSearched(email));
  return GetConsultationListLogic(email, petId, page);
}

export async function GetConsultationHistory(page = 1) {
  const vetId = localStorage.getItem("id");
  return GetConsultationHistoryLogic(vetId, page);
}

export async function RegisterConsultation(
  petId,
  ownerEmail,
  review,
  diagnosis,
  treatment,
  indications,
  weight,
  temperature,
  nextDate
) {
  return RegisterConsultationLogic(
    localStorage.getItem("id"),
    petId,
    ownerEmail,
    review,
    diagnosis,
    treatment,
    indications,
    weight,
    temperature,
    nextDate
  );
}
