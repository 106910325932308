import LoginLogic from "../Application/Login.logic";
import LogoutLogic from "../Application/Logout.logic";
import ResetPasswordLogic from "../Application/ResetPassword.logic";
import RequestCodeLogic from "../Application/RequestCode.logic";

export async function Login(email, password) {
  const login = await LoginLogic(email, password);
  return login;
}
export function Logout() {
  return LogoutLogic();
}
export async function ResetPassword(code, email, password) {
  return ResetPasswordLogic(code, email, password);
}
export async function RequestCode(email) {
  return RequestCodeLogic(email);
}
