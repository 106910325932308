import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import FlatButton from "../../DesignSystem/FlatButton";
import BasicForm from "../../DesignSystem/BasicForm";
import logo from "../../assets/Imagotipo.svg";
import BlueFace from "../../assets/Cat_face_blue.svg";
import BlueFaceSad from "../../assets/blue_sad.svg";
import GreenFace from "../../assets/Cat_face_green.svg";
import GreenFaceSad from "../../assets/green_sad.svg";
import footerImage from "../../assets/footer-image1.png";
import LoadingSpinner from "../../DesignSystem/LoadingSpinner";
import { verifyAccountService } from "../../Provider.new";

const useStyles = makeStyles((theme) => ({
  back: ({ roleUser }) => ({
    background:
      roleUser === "vet"
        ? theme.palette.primary.back
        : theme.palette.secondary.back,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }),
  boxStyle: ({ roleUser }) => ({
    minWidth: "320px",
    minHeight: "100vh",
    position: "relative",
    [theme.breakpoints.up("lg")]: {
      display: "grid",
      gridTemplateColumns: "repeat(2, 1fr)",
      paddingTop: "50px",
      justifyItems: "center",
      alignItems: "end",
    },
    background:
      roleUser === "vet"
        ? theme.palette.primary.back
        : theme.palette.secondary.back,
  }),
  logo: {
    width: 110,
    position: "absolute",
    top: 20,
    left: 50,
    [theme.breakpoints.down("lg")]: {
      position: "static",
      maxWidth: 235,
      minWidth: 200,
      minHeight: 158,
      marginBottom: 20,
    },
  },
  face: {
    marginBottom: "75%",
  },
  title: {
    marginBottom: 20,
    [theme.breakpoints.up("lg")]: {
      fontSize: "4.5rem",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "3rem",
      textAlign: "center",
    },
  },
  subtitle: {
    fontWeight: 400,
    marginBottom: 40,
    [theme.breakpoints.up("lg")]: {
      fontSize: "2.25rem",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "1.25rem",
      textAlign: "center",
    },
  },
  titleContainer: {
    [theme.breakpoints.down("lg")]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "0.75rem",
      width: "100%",
      paddingTop: "2rem",
      textAlign: "center",
    },
    [theme.breakpoints.up("lg")]: {
      justifySelf: "center",
      alignSelf: "center",
    },
  },
  footerImage: {
    width: "100%",
    [theme.breakpoints.down("lg")]: {
      display: "block",
      position: "absolute",
      bottom: 0,
    },
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
}));

export default function SuccessfulUI() {
  const [role, setRole] = useState("");

  const history = useHistory();
  const classes = useStyles({ roleUser: role });

  async function handleSubmit(e) {
    e.preventDefault();
  }

  // Quey params
  const { search } = useLocation();
  const [paramsData, setParamsData] = useState(null);

  useEffect(() => {
    const params = new URLSearchParams(search);
    const user = params.get("user_name");
    const userRole = params.get("role")?.toLowerCase().includes("vet")
      ? "vet"
      : "owner";
    const code = params.get("confirmation_code");
    const clientID = params.get("client_id");

    setParamsData({ user, code, clientID });
    setRole(userRole);
  }, []);

  // Send request
  const [success, setSuccess] = useState(false);
  const [requestMade, setRequestMade] = useState(false);

  useEffect(() => {
    if (!paramsData) return;

    const verify = async () => {
      setSuccess(false);
      const { code, user } = paramsData;

      try {
        const { data } = await verifyAccountService({
          user,
          code,
        });
        setSuccess(!!data);
        setRequestMade(true);
      } catch (error) {
        setSuccess(false);
      }
      setRequestMade(true);
    };

    verify();
  }, [paramsData]);

  // Loader
  if (!requestMade)
    return (
      <div className={classes.back}>
        <LoadingSpinner roleUser={role} />
      </div>
    );

  // Success / Error screen
  return (
    <Box className={classes.boxStyle}>
      <Box className={classes.titleContainer}>
        <img className={classes.logo} src={logo} alt="logo Vetech" />
        <Typography variant="h1">
          {success ? "¡Muy bien!" : "¡Alto!"}
        </Typography>
        <Typography variant="subtitle1">
          {success
            ? "Tu cuenta se verificó exitosamente."
            : "Tu link no es válido"}
          <br />
          <br />
          {success
            ? "Para iniciar sesión da clic en el siguiente botón:"
            : "Solicita un link nuevamente."}
        </Typography>
        <FlatButton
          title={success ? "Iniciar sesión" : "Solicita link nuevo"}
          widthBtn="200px"
          roleUser={role}
          onClick={() => {
            history.push(success ? "/login" : "/reset");
          }}
        />
      </Box>
      <BasicForm header roleUser={role} onSubmit={handleSubmit}>
        <div className={classes.face}>
          {role === "owner" ? (
            <img src={success ? BlueFace : BlueFaceSad} alt="" />
          ) : (
            <img src={success ? GreenFace : GreenFaceSad} alt="" />
          )}
        </div>
      </BasicForm>
      <img className={classes.footerImage} src={footerImage} alt="mascota" />
    </Box>
  );
}
