import React, { useState, useEffect } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import BasicForm from "../../DesignSystem/BasicForm";
import FlatButton from "../../DesignSystem/FlatButton";
import InputTextOutlined from "../../DesignSystem/InputTextOutlined";
import FooterImage from "../../DesignSystem/FooterImage";
import footerImage from "../../assets/footer-image1.png";
import adorableBulldog from "../../assets/bulldogWhite.png";
import BlueFace from "../../assets/Cat_face_blue.svg";
import Alert from "../../DesignSystem/BasicModal";
import {
  validateEmail,
  validatePassword,
  confirmPassword,
  validateCode,
} from "../../User/Domain/User.model";
import { RequestCode, ResetPassword } from "../Infrastructure/Auth.presenter";

const useStyles = makeStyles((theme) => ({
  boxStyle: {
    minWidth: "320px",
    minHeight: "100vh",
    [theme.breakpoints.up("lg")]: {
      backgroundColor: theme.palette.secondary.back,
      display: "grid",
      paddingTop: "50px",
      gridTemplateColumns: "repeat(2, 1fr)",
      justifyItems: "center",
      alignItems: "end",
    },
  },
  titleContainer: {
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
    [theme.breakpoints.up("lg")]: {
      paddingTop: "100px",
      justifySelf: "start",
      alignSelf: "start",
      paddingLeft: "50px",
    },
  },
  footer: {
    [theme.breakpoints.down("md")]: {
      height: "100%",
      clipPath: "polygon(50% 0%, 100% 38%, 100% 100%, 0 100%, 0% 38%)",
      backgroundRepeat: "no-repeat",
    },
    [theme.breakpoints.up("md")]: {
      height: "100%",
      clipPath: "polygon(50% 0%, 100% 38%, 100% 100%, 0 100%, 0% 38%)",
      backgroundRepeat: "no-repeat",
    },
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  link: {
    color: theme.palette.secondary.main,
    fontWeight: 400,
    textDecoration: "none",
    background: "none",
    "&:hover": {
      color: theme.palette.secondary.dark,
    },
  },
  firstLink: {
    margin: "-60px",
    marginTop: "-50px",
  },
}));

export default function PasswordResetUI() {
  const [values, setValues] = useState({
    code: "",
    email: "",
    password: "",
    passwordRepeat: "",
  });
  const [errors, setErrors] = useState({
    code: { error: false, helper: "" },
    email: { error: false, helper: "" },
    password: { error: false, helper: "" },
    passwordRepeat: { error: false, helper: "" },
  });
  const [sendEmailDisabled, setSendEmailDisabled] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [breakpoint, setBreakpoint] = React.useState(
    window.innerWidth <= 425 ? footerImage : adorableBulldog
  );
  const [alert, setAlert] = useState({
    open: false,
  });
  function closeAlert() {
    setAlert({ ...alert, open: false });
  }
  function showAlertWindow(text, title = "Ocurrió un error", type = "error") {
    setAlert({
      title,
      text,
      open: true,
      type,
    });
  }
  const history = useHistory();
  const classes = useStyles();

  const handleResizeImage = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 425) {
      setBreakpoint(footerImage);
    } else if (screenWidth > 425) {
      setBreakpoint(adorableBulldog);
    }
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleResizeImage);
    return () => {
      window.removeEventListener("resize", handleResizeImage);
    };
  }, []);

  const [activeStep, setActiveStep] = React.useState(0);
  const [fromEmail, setFromEmail] = React.useState(0);
  const [emailSent, setEmailSent] = React.useState(false);

  // Set current step from query params
  const { search } = useLocation();
  useEffect(() => {
    const params = new URLSearchParams(search);
    const step = params.get("step");
    setActiveStep(step === "1" ? 1 : 0);
    setFromEmail(step === "1");
  }, []);

  useEffect(() => {
    if (
      values.code !== "" &&
      values.password !== "" &&
      values.passwordRepeat !== "" &&
      values.email !== "" &&
      !errors.password.error &&
      !errors.passwordRepeat.error &&
      !errors.code.error &&
      !errors.email.error
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
    if (values.email !== "" && !errors.email.error) {
      setSendEmailDisabled(false);
    } else {
      setSendEmailDisabled(true);
    }
  }, [values]);

  async function handleSendEmail(e) {
    e.preventDefault();
    setLoading(true);
    try {
      const { valid, info } = await RequestCode(values.email);
      if (valid) {
        setActiveStep(1);
        setEmailSent(true);
      } else {
        showAlertWindow(info);
      }
    } catch (error) {
      showAlertWindow("Inténtalo mas tarde");
    }
    setLoading(false);
  }

  async function handleResetPassword(e) {
    e.preventDefault();
    setLoading(true);
    try {
      const { valid, info } = await ResetPassword(
        values.code,
        values.email,
        values.password
      );

      if (valid) {
        history.push("/login");
      } else {
        showAlertWindow(info.message);
      }
    } catch (error) {
      showAlertWindow("Inténtalo mas tarde");
    }
    setLoading(false);
  }

  const handleInputChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });

    switch (e.target.name) {
      case "code": {
        const { valid, info } = validateCode(e.target.value);
        setErrors({
          ...errors,
          [e.target.name]: { error: !valid, helper: info },
        });
        break;
      }
      case "password": {
        const passwordValidation = validatePassword(e.target.value);
        const passwordRepeatValidation = confirmPassword(
          e.target.value,
          values.passwordRepeat
        );
        setErrors({
          ...errors,
          password: {
            error: !passwordValidation.valid,
            helper: passwordValidation.info,
          },
          passwordRepeat: {
            error: !passwordRepeatValidation.valid,
            helper: passwordRepeatValidation.info,
          },
        });
        break;
      }
      case "passwordRepeat": {
        const { valid, info } = confirmPassword(
          values.password,
          e.target.value
        );
        setErrors({
          ...errors,
          [e.target.name]: { error: !valid, helper: info },
        });
        break;
      }
      case "email": {
        const { valid, info } = validateEmail(e.target.value);
        setErrors({
          ...errors,
          [e.target.name]: { error: !valid, helper: info },
        });
        break;
      }
      default:
        break;
    }
  };

  return (
    <div className={classes.boxStyle}>
      <div className={classes.titleContainer}>
        <Typography variant="h1">Restablece tu contraseña</Typography>
        {activeStep === 0 ? (
          <Typography variant="subtitle1">
            Para restablecer tu contraseña primero debes introducir el correo
            electrónico con el que te resgitraste.
          </Typography>
        ) : (
          <Typography variant="subtitle1">
            {`Introduce el código de 6 dígitos que ${
              fromEmail ? "llegó" : "te llegará"
            } a tu correo junto
            con tu nueva contraseña.`}
          </Typography>
        )}
      </div>

      {activeStep === 0 ? (
        <BasicForm
          heightDiv="calc(100vh - 50px)"
          header
          onSubmit={handleSendEmail}
        >
          <>
            <img src={BlueFace} alt="Blue Cat Face" />
            <InputTextOutlined
              roleUser="owner"
              type="text"
              label="*Correo electrónico"
              name="email"
              variant="standard"
              placeholder="Ingresa tu correo electrónico"
              value={values.email}
              onChange={handleInputChange}
              helperText={errors.email.helper}
            />
            <FlatButton
              title="Enviar"
              widthBtn="150px"
              roleUser="owner"
              typeButton="submit"
              isDisabled={sendEmailDisabled}
              loading={loading}
            />
            <Link
              className={`${classes.link} ${classes.firstLink}`}
              to="/login"
            >
              Cancelar
            </Link>
            <FlatButton
              title="Ya tengo un código"
              widthBtn="220px"
              roleUser="owner"
              typeButton="button"
              onClick={() => setActiveStep(1)}
            />
          </>
        </BasicForm>
      ) : (
        <BasicForm
          heightDiv="calc(100vh - 50px)"
          header
          onSubmit={handleResetPassword}
        >
          <>
            <Typography variant="h5" color="secondary">
              Llena los campos
            </Typography>

            {!emailSent && (
              <InputTextOutlined
                roleUser="owner"
                type="text"
                label="*Correo electrónico"
                name="email"
                variant="standard"
                placeholder="Ingresa tu correo electrónico"
                value={values.email}
                onChange={handleInputChange}
                helperText={errors.email.helper}
              />
            )}
            <InputTextOutlined
              roleUser="owner"
              type="text"
              label="*Código"
              name="code"
              variant="standard"
              placeholder="Escribe el código de 6 dígitos"
              value={values.code}
              onChange={handleInputChange}
              helperText={errors.code.helper}
            />
            <InputTextOutlined
              roleUser="owner"
              type="password"
              label="*Nueva contraseña"
              name="password"
              variant="standard"
              placeholder="Ingresa tu nueva contraseña"
              value={values.password}
              onChange={handleInputChange}
              helperText={errors.password.helper}
            />
            <InputTextOutlined
              roleUser="owner"
              type="password"
              label="*Confirmar contraseña"
              name="passwordRepeat"
              variant="standard"
              placeholder="Confirma tu contraseña"
              value={values.passwordRepeat}
              onChange={handleInputChange}
              helperText={errors.passwordRepeat.helper}
            />
            <FlatButton
              title="Restablecer"
              widthBtn="150px"
              roleUser="owner"
              isDisabled={disabled}
              loading={loading}
              typeButton="submit"
            />
            <Link className={classes.link} to="/login">
              Cancelar
            </Link>
            <Alert alert={alert} handleClose={closeAlert} />
          </>
        </BasicForm>
      )}

      <div className={classes.footerShadow} />
      <div className={classes.footer}>
        <FooterImage fimage={breakpoint} />
      </div>
    </div>
  );
}
