import React from "react";
import { useHistory, Link } from "react-router-dom";
import Card from "@mui/material/Card";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import greenIcon from "../assets/green_pets.svg";
import blueIcon from "../assets/blue_pets.svg";

import store from "../redux/store";
import { setOwnerEmailSearched } from "../Vet/Infrastructure/Vet.reducer";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "90%",
    maxWidth: 490,
    minHeight: 220,
    height: "auto",
    maxHeight: 380,
    padding: "30px 40px",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "end",
    flexDirection: "column",
    gap: 10,
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
      minHeight: 220,
      height: "auto",
      maxHeight: 550,
    },
    [theme.breakpoints.up("sm")]: {
      padding: "20px 30px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "100%",
    },
  },
  petInfoContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      alignItems: "start",
      flexDirection: "column",
    },
  },
  alignStyles: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "start",
    flexDirection: "column",
  },
  title: ({ role }) => ({
    fontWeight: 400,
    fontSize: "0.875rem",
    color:
      role === "vet"
        ? theme.palette.primary.main
        : theme.palette.secondary.main,
  }),
  textInfo: {
    fontWeight: 400,
    fontSize: "1.125rem",
    color: "#BABABA",
  },
  review: {
    WebkitLineClamp: 3,
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    lineHeight: "1.5rem",
    minHeight: "4.5rem",
  },
  goProfileContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  flex: {
    display: "flex",
  },
  logo: {
    width: 55,
    height: 55,
    transition: "0.4s",
    "&:hover": {
      transform: "scale(1.05)",
    },
  },
  goProfileLink: {
    display: "flex",
    border: "none",
    background: "none",
    cursor: "pointer",
  },
  goDetailsLink: ({ role }) => ({
    display: "flex",
    alignSelf: "center",
    justifyContent: "center",
    alignItems: "center",
    gap: 8,
    color:
      role === "vet"
        ? theme.palette.primary.main
        : theme.palette.secondary.main,
    fontSize: "1.2rem",
    fontWeight: 700,
    textDecoration: "none",
    background: "none",
    "&:hover": {
      color:
        role === "vet"
          ? theme.palette.primary.dark
          : theme.palette.secondary.dark,
    },
  }),
}));

export default function ConsultationCard({
  id,
  date,
  petName,
  petVetName,
  petOwnerName,
  review = "No existe reseña",
  history,
  petId,
  ownerEmail,
}) {
  const role = localStorage.getItem("role");
  const classes = useStyles({ role });
  const routerHistory = useHistory();

  function handlePetMenu() {
    if (ownerEmail !== "") {
      store.dispatch(setOwnerEmailSearched(ownerEmail));
    }
    routerHistory.push(`/${role}/pets/menu/${petId}`);
  }

  return (
    <Card
      className={classes.root}
      sx={{
        boxShadow:
          "0px 4px 4px rgba(0, 0, 0, 0.25), 4px 0px 5px rgba(0, 0, 0, 0.25)",
        borderRadius: "10px",
      }}
    >
      <div className={classes.petInfoContainer}>
        <div>
          <h3 className={classes.title}>Mascota</h3>
          <p className={classes.textInfo}>{petName}</p>
        </div>
        <div>
          <h3 className={classes.title}>Fecha de consulta</h3>
          <p className={classes.textInfo}>{date}</p>
        </div>
      </div>
      <div className={classes.alignStyles}>
        <h3 className={classes.title}>
          {petOwnerName ? "Dueño" : "Veterinario"}
        </h3>
        <p className={classes.textInfo}>{petOwnerName || petVetName}</p>
      </div>
      <div className={classes.alignStyles}>
        <h3 className={classes.title}>Reseña</h3>
        <p className={`${classes.textInfo} ${classes.review}`}>{review}.</p>
      </div>
      <div className={classes.goProfileContainer}>
        <button
          type="button"
          onClick={handlePetMenu}
          className={classes.goProfileLink}
        >
          <img
            className={classes.logo}
            src={role === "vet" ? greenIcon : blueIcon}
            alt="icon pets"
          />
        </button>
        <Link
          className={classes.goDetailsLink}
          to={
            !history
              ? `/${role}/pets/consultation/${id}`
              : `/${role}/pets/vetconsultation/${id}`
          }
        >
          Más información
          <ArrowRightAltIcon />
        </Link>
      </div>
    </Card>
  );
}

ConsultationCard.defaultProps = {
  petVetName: "",
  ownerEmail: "",
  petOwnerName: "",
  history: false,
};

ConsultationCard.propTypes = {
  id: PropTypes.string.isRequired,
  petId: PropTypes.string.isRequired,
  petName: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  petVetName: PropTypes.string,
  petOwnerName: PropTypes.string,
  ownerEmail: PropTypes.string,
  history: PropTypes.bool,
  review: PropTypes.string.isRequired,
};
