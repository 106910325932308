import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { useSelector } from "react-redux";
import { getPetModel } from "../Infrastructure/Pet.reducer";
import { UpdatePetInfo, GetPetInfo } from "../Infrastructure/Pet.presenter";

import FlatButton from "../../DesignSystem/FlatButton";
import BasicForm from "../../DesignSystem/BasicForm";
import BasicCard from "../../DesignSystem/BasicCard.ui";
import BasicSelect from "../../DesignSystem/BasicSelect.ui";
import InputTextOutlined from "../../DesignSystem/InputTextOutlined";
import { uploadPhoto } from "../../Provider.new";
import imgDefault from "../../assets/paw_finger.svg";
import blueCamera from "../../assets/blue_camera.svg";
import Alert from "../../DesignSystem/BasicModal";
import { MAX_IMAGE_SIZE, MAX_IMAGE_SIZE_TEXT } from "../../constants";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    width: "100%",
    [theme.breakpoints.up("lg")]: {
      flexDirection: "row",
      width: "250%",
    },
  },
  containerPhoto: {
    marginTop: "20px",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    alignItems: "center",
  },
  iconButton: {
    width: "60px",
    height: "60px",
    position: "absolute",
    bottom: 5,
    left: 60,
    top: 180,
    border: "none",
    background: "none",
    borderRadius: "50%",
    opacity: 0.8,
    "&:hover": {
      opacity: 1,
    },
    "&:active": {
      opacity: 0.9,
      transform: "scale(0.95)",
    },
  },
  photo: {
    height: "220px",
    width: "220px",
    objectFit: "cover",
    marginLeft: "50px",
    marginRight: "50px",
    marginBottom: "35px",
    borderRadius: "100% 50% 100% 50%",
  },
}));

export default function PetUpdateUI() {
  const classes = useStyles();
  const [values, setValues] = useState({
    name: "",
    allergies: "",
    picture: "",
    neutered: "",
    surgeries: "",
  });

  const [photo, setPhoto] = useState(null);
  const [photoPreview, setPhotoPreview] = useState(imgDefault);
  const [clicked, setClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const history = useHistory();
  const pet = useSelector(getPetModel);

  const [alert, setAlert] = useState({
    open: false,
  });
  function closeAlert() {
    setAlert({ ...alert, open: false });
  }
  function showAlert(text, title = "Ocurrió un error", type = "error") {
    setAlert({
      title,
      text,
      open: true,
      type,
    });
  }

  useEffect(async () => {
    if (pet) {
      setValues({
        allergies: pet.allergies,
        name: pet.name,
        neutered: pet.neutered ? "Sí" : "No",
        surgeries: pet.surgeries,
        picture: pet.picture,
      });
    } else {
      history.push("/owner/pets/list");
    }
  }, []);

  useEffect(() => {
    if (values.name.trim() !== "") {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [values]);

  async function handleUpdate(e) {
    e.preventDefault();
    setLoading(true);
    if (photo != null) {
      try {
        await uploadPhoto(
          `owners/${localStorage.getItem("email")}/pets`,
          pet.id,
          photo
        );
      } catch (err) {
        showAlert("Ocurrió un error al subir la imagen de tu mascota");
      }
    }

    try {
      const neuteredBool = values.neutered === "Sí";
      const { valid, info } = await UpdatePetInfo(
        pet.id,
        values.allergies.trim(),
        values.name.trim(),
        neuteredBool,
        values.surgeries.trim()
      );

      if (valid) {
        await GetPetInfo(localStorage.getItem("email"), pet.id);
        history.push(`/owner/pets/menu/${pet.id}`);
      } else {
        showAlert(info);
      }
    } catch (error) {
      showAlert("Ocurrió un error al crear a tu mascota");
    }
    setLoading(false);
  }

  const handleInputChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  async function handlePhoto(e) {
    const file = e.target.files[0];
    const allowedExtensions = /(.jpg|.jpeg|.png)$/i;
    if (!allowedExtensions.exec(file.name)) {
      showAlert("Elige un formato de imagen válido (.jpg, .png, .jpeg)");
    } else if (file?.size / 1024 > MAX_IMAGE_SIZE) {
      showAlert(MAX_IMAGE_SIZE_TEXT);
    } else {
      setClicked(true);
      setPhoto(file);
      setPhotoPreview(URL.createObjectURL(file));
    }
  }

  function selectPhoto() {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = ".png,.jpg,.jpeg";
    fileInput.addEventListener("change", (event) => {
      handlePhoto(event);
    });
    fileInput.click();
    fileInput.remove();
  }

  return (
    <BasicCard>
      <BasicForm onSubmit={handleUpdate}>
        <>
          <div className={classes.root}>
            <div className={classes.containerPhoto}>
              <img
                className={classes.photo}
                src={
                  clicked || values.picture === ""
                    ? photoPreview
                    : values.picture
                }
                alt="photoPet"
              />
              <button
                className={classes.iconButton}
                type="button"
                onClick={selectPhoto}
              >
                <img src={blueCamera} alt="" />
              </button>
              <InputTextOutlined
                roleUser="owner"
                type="text"
                label="Nombre de tu mascota"
                name="name"
                placeholder="Ingresa el nombre de tu mascota"
                value={values.name}
                onChange={handleInputChange}
                widthField="300px"
              />
            </div>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                pt: 1,
                alignItems: "center",
              }}
            >
              <InputTextOutlined
                roleUser="owner"
                type="text"
                label="Alergias"
                name="allergies"
                placeholder="Escribe sus alergias"
                value={values.allergies}
                onChange={handleInputChange}
                widthField="300px"
              />

              <BasicSelect
                label="Esterilizado"
                selectWidth="300px"
                name="neutered"
                value={values.neutered}
                handleChange={handleInputChange}
                options={["Sí", "No"]}
              />

              <InputTextOutlined
                roleUser="owner"
                type="text"
                label="Cirugías"
                name="surgeries"
                placeholder="Escribe sus cirugías"
                multiline
                value={values.surgeries}
                onChange={handleInputChange}
                widthField="300px"
              />

              <Box sx={{ display: "flex", flexDirection: "row", pt: 1 }}>
                <FlatButton
                  title="Cancelar"
                  widthBtn="140px"
                  roleUser="owner"
                  onClick={() => {
                    history.push("/owner/pets/list");
                  }}
                  loading={loading}
                />
                <FlatButton
                  title="Actualizar"
                  widthBtn="140px"
                  roleUser="owner"
                  typeButton="submit"
                  isDisabled={disabled}
                  loading={loading}
                />
              </Box>
            </Box>
          </div>
        </>
      </BasicForm>
      <Alert alert={alert} handleClose={closeAlert} />
    </BasicCard>
  );
}
