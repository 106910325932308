import { UpdatePetInfoRepository } from "../Infrastructure/Pet.repository";

export default async function UpdatePetInfoLogic(
  id,
  petId,
  allergies,
  name,
  neutered,
  surgeries
) {
  return UpdatePetInfoRepository(
    id,
    petId,
    allergies,
    name,
    neutered,
    surgeries
  );
}
