import React from "react";
import { makeStyles } from "@mui/styles";
import { useHistory } from "react-router-dom";
import FlatButton from "../../DesignSystem/FlatButton";
import FooterImage from "../../DesignSystem/FooterImage";
import petImage from "../../assets/corto-z.jpg";
import petImg from "../../assets/bulldog.png";
import catDog from "../../assets/catDog.png";
import dogImg from "../../assets/perro2.jpg";
import catImg from "../../assets/gato4.jpg";
import logo from "../../assets/Imagotipo.svg";
import backImage from "../../assets/blue_dog.svg";
import footerImage from "../../assets/footer-image1.png";

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "100vh",
    [theme.breakpoints.down("md")]: {
      paddingLeft: 15,
      paddingRight: 15,
      backgroundSize: "cover",
      backgroundPosition: "-220px -90px",
    },
    [theme.breakpoints.down("lg")]: {
      paddingTop: 80,
      backgroundColor: "#FFFFFF",
      backgroundImage: `url(${backImage})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "inherit",
      backgroundAttachment: "fixed",
      backgroundPosition: "-200px -50px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexDirection: "column",
    },
    [theme.breakpoints.up("lg")]: {
      backgroundColor: "#D7F6F9",
      display: "grid",
      gridTemplateColumns: "60% 40%",
      gap: 20,
      padding: "0 50px",
    },
  },
  title: {
    marginBottom: 20,
    [theme.breakpoints.up("lg")]: {
      fontSize: "3.5rem",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "3rem",
      textAlign: "center",
    },
  },
  subtitle: {
    fontWeight: 400,
    marginBottom: 40,
    [theme.breakpoints.up("lg")]: {
      fontSize: "2rem",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "1.25rem",
      textAlign: "center",
    },
  },
  logo: {
    width: 110,
    position: "absolute",
    top: 20,
    left: 50,
    [theme.breakpoints.down("lg")]: {
      position: "static",
      maxWidth: 235,
      minWidth: 200,
      minHeight: 158,
      marginBottom: 20,
    },
  },
  textContainer: {
    [theme.breakpoints.up("lg")]: {
      alignSelf: "center",
      width: "100%",
    },
    [theme.breakpoints.down("lg")]: {
      maxWidth: "306px",
    },
  },
  welcomeImage: {
    maxWidth: 400,
    justifySelf: "center",
    alignSelf: "center",
    borderRadius: "100% 50% 100% 50%",
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
  },
  footer: {
    [theme.breakpoints.up("md")]: {
      height: "100%",
      clipPath: "polygon(50% 0%, 100% 38%, 100% 100%, 0 100%, 0% 38%)",
      backgroundRepeat: "no-repeat",
    },
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
}));

export default function Index() {
  const history = useHistory();
  const classes = useStyles();
  const images = [petImage, petImg, catDog, dogImg, catImg];
  const index = Math.floor(Math.random() * images.length);

  return (
    <div className={classes.container}>
      <img className={classes.logo} src={logo} alt="logo Vetech" />
      <div className={classes.textContainer}>
        <h2 className={classes.title}>¡Bienvenido a Vetech!</h2>
        <h4 className={classes.subtitle}>
          La plataforma web al servicio de las mascotas
        </h4>
        <FlatButton
          style={{ border: "1px solid red" }}
          title="Siguiente"
          widthBtn="306px "
          heightBtn="40px"
          roleUser="owner"
          onClick={() => {
            history.push("/login");
          }}
        />
      </div>
      <img className={classes.welcomeImage} src={images[index]} alt="mascota" />
      <FooterImage fimage={footerImage} />
    </div>
  );
}
