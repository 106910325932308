import { RegisterVetRepository } from "../Infrastructure/Vet.repository";
import VetModel from "../Domain/Vet.model";
// TODO ver dónde deberían ir estas funciones
import {
  confirmPassword,
  validatePassword,
  validateEmail,
  validateName,
} from "../../User/Domain/User.model";

export default async function RegisterVetLogic(
  email,
  password,
  passwordRepeat,
  name,
  license,
  address,
  phone
) {
  const objectEmail = validateEmail(email);
  if (!objectEmail.valid) return objectEmail;

  const objectPassword = validatePassword(password);
  if (!objectPassword.valid) return objectPassword;

  const objectPasswordRepeat = confirmPassword(password, passwordRepeat);
  if (!objectPasswordRepeat.valid) return objectPasswordRepeat;

  const objectName = validateName(name, "vet");
  if (!objectName.valid) return objectName;

  const vet = new VetModel(license, address, phone, "", "vet", email, name, "");
  return RegisterVetRepository(vet, password);
}
