import React from "react";
import PropTypes from "prop-types";

import LoadingButton from "@mui/lab/LoadingButton";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: ({ widthBtn, icon, roleUser, heightBtn }) => ({
    width: widthBtn,
    height: heightBtn,
    paddingLeft: icon ? "75px" : "25px",
    "&:hover": {
      background: roleUser === "vet" ? "primary.dark" : "secondary.dark",
    },
  }),
  contained: {},
  iconRoot: {
    marginLeft: "30px",
  },
});

/**
 * variants => contained, error, success
 */

export default function FlatButton({
  title,
  onClick,
  roleUser,
  widthBtn,
  icon,
  typeButton,
  isDisabled,
  heightBtn,
  loading,
  variant,
}) {
  const classes = useStyles({ widthBtn, icon, roleUser, heightBtn });

  if (isDisabled === true) {
    return (
      <LoadingButton
        className={classes.root}
        variant="contained"
        loading={loading}
        disabled
      >
        {title}
      </LoadingButton>
    );
  }
  if (icon === true) {
    return (
      <LoadingButton
        className={`${classes.root} ${classes.contained}`}
        color={roleUser === "vet" ? "primary" : "secondary"}
        variant="contained"
        type={typeButton}
        endIcon={<ArrowForwardIosIcon className={classes.iconRoot} />}
        onClick={onClick}
        loading={loading}
      >
        {title}
      </LoadingButton>
    );
  }
  return (
    <LoadingButton
      className={classes.root}
      color={roleUser === "vet" ? "primary" : "secondary"}
      variant={variant}
      type={typeButton}
      onClick={onClick}
      loading={loading}
    >
      {title}
    </LoadingButton>
  );
}

FlatButton.defaultProps = {
  variant: "contained",
  roleUser: "owner",
  onClick: () => null,
  isDisabled: false,
  icon: false,
  heightBtn: "20px",
  typeButton: "button",
  loading: false,
};

FlatButton.propTypes = {
  roleUser: PropTypes.string,
  widthBtn: PropTypes.string.isRequired,
  heightBtn: PropTypes.string,
  typeButton: PropTypes.string,
  title: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  loading: PropTypes.bool,
  icon: PropTypes.bool,
  onClick: PropTypes.func,
  variant: PropTypes.string,
};
