import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import whitePaw from "../assets/white_paw.svg";

const useStyles = makeStyles({
  vetColor:{
    stroke: "#71E4D1",
    animation: "$imagotipoBackgroundAnimationVet 2.7s cubic-bezier(0.67, 0.59, 0.7, 0.93)  0s infinite normal forwards",
  },
  ownerColor:{
    stroke: "#00B1E0",
    animation: "$imagotipoBackgroundAnimationOwner 2.7s cubic-bezier(0.67, 0.59, 0.7, 0.93)  0s infinite normal forwards",
  },
  imagotipoBackground:{
    fill: "none",
    transformOrigin: "center",
    strokeLinecap: "round",
    strokeWidth: 25,
    strokeDasharray: 350,
  },
  imagotipoImage:{
    transformOrigin: "center",
    transform: "scale(0)",
    animation: "$imagotipoImageAnimation 2.7s cubic-bezier(0.76, -0.04, 0.34, 0.88) 0s infinite normal forwards",
  },
  "@keyframes imagotipoBackgroundAnimationVet": {
    "0%":{
      strokeDashoffset: "350",
      transform: "rotate(-120deg)",
    },
    "25%":{
      strokeDashoffset: "0",
      transform: "rotate(0deg) scale(1)",
    },
    "50%":{
      transform: "scale(0.03)",
    },
    "75%":{
      transform: "scale(1.3)",
    },
    "100%":{
      fill: "#71E4D1 ",
      transform: "scale(1.15)",
    }
  },
  "@keyframes imagotipoBackgroundAnimationOwner": {
    "0%":{
      strokeDashoffset: "350",
      transform: "rotate(-120deg)",
    },
    "25%":{
      strokeDashoffset: "0",
      transform: "rotate(0deg) scale(1)",
    },
    "50%":{
      transform: "scale(0.03)",
    },
    "75%":{
      transform: "scale(1.3)",
    },
    "100%":{
      fill: "#00B1E0",
      transform: "scale(1.15)",
    }
  },
  "@keyframes imagotipoImageAnimation":{
    "50%":{
      transform: "scale(0.01)",
    },
    "75%":{
      transform: "scale(1.5)",
    },
    "100%":{
      transform: "scale(1.2)"
    },
  },
});

export default function LoadingSpinner({roleUser}) {
  const classes = useStyles();

  return (
    <>
      <svg
        width="200"
        height="200"
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
        viewBox="0 0 200 200"
      >
        <circle className={`${classes.imagotipoBackground} ${ roleUser=== "vet" ? classes.vetColor : classes.ownerColor}`} cx="100" cy="100" r="50" />
        <image
          className={classes.imagotipoImage}
          xlinkHref={whitePaw}
          x="75"
          y="75"
          width="50"
          height="50"
        />
        Tu navegador no es compatible con SVG, por favor actualizalo a su
        versión más reciente.
      </svg>
    </>
  );
}

LoadingSpinner.propTypes = {
  roleUser: PropTypes.string.isRequired,
};
