import { createTheme } from "@mui/material/styles";
import ThemePalette from "./theme.palette";
import ThemeComponents from "./theme.components";

const theme = createTheme({
  palette: ThemePalette,
  components: ThemeComponents,
  breakpoints: {
    values: {
      xs: 0,
      sm: 375,
      md: 768,
      lg: 1280,
    },
  },
  typography: {
    h1: {
      fontSize: "4.5rem",
      fontWeight: "700",
      marginBottom: "1rem",
      marginTop: "1rem",
    },
    h5: {
      fontSize: "1.5rem",
      fontWeight: "500",
      marginTop: "1rem",
    },
    subtitle1: {
      fontSize: "1.5rem",
      fontWeight: "400",
      marginBottom: "1rem",
      marginTop: "1rem",
    },
  },
});

export default theme;
