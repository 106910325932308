import RegisterOwnerLogic from "../Application/RegisterOwner.logic";
import GetOwnerInfoLogic from "../Application/GetOwnerInfo.logic";
import UpdateOwnerInfoLogic from "../Application/UpdateOwnerInfo.logic";

import store from "../../redux/store";
import { setModel } from "./Owner.reducer";

export async function RegisterOwner(email, password, passwordRepeat, name) {
  return RegisterOwnerLogic(email, password, passwordRepeat, name);
}

export async function GetOwnerInfo() {
  const { valid, info } = await GetOwnerInfoLogic(localStorage.getItem("id"));
  if (valid) {
    localStorage.setItem("email", info.email);
    store.dispatch(setModel(info));
  }

  return { valid, info };
}

export async function UpdateOwnerInfo(name) {
  return UpdateOwnerInfoLogic(localStorage.getItem("id"), name);
}
