import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { useSelector } from "react-redux";
import { getModel } from "../Infrastructure/Owner.reducer";
import {
  UpdateOwnerInfo,
  GetOwnerInfo,
} from "../Infrastructure/Owner.presenter";

import FlatButton from "../../DesignSystem/FlatButton";
import BasicForm from "../../DesignSystem/BasicForm";
import BasicCard from "../../DesignSystem/BasicCard.ui";
import InputTextOutlined from "../../DesignSystem/InputTextOutlined";
import { uploadPhoto } from "../../Provider.new";
import img from "../../assets/paw_finger.svg";
import blueCamera from "../../assets/blue_camera.svg";

import { validateName } from "../../User/Domain/User.model";
import Alert from "../../DesignSystem/BasicModal";
import { MAX_IMAGE_SIZE, MAX_IMAGE_SIZE_TEXT } from "../../constants";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    width: "100%",
  },
  containerPhoto: {
    marginTop: "20px",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    alignItems: "center",
  },
  iconButton: {
    width: "60px",
    height: "60px",
    position: "absolute",
    bottom: 5,
    left: 60,
    top: 180,
    border: "none",
    background: "none",
    borderRadius: "50%",
    opacity: 0.8,
    "&:hover": {
      opacity: 1,
    },
    "&:active": {
      opacity: 0.9,
      transform: "scale(0.95)",
    },
  },
  ownerPhoto: {
    height: "220px",
    width: "220px",
    objectFit: "cover",
    marginLeft: "50px",
    marginRight: "50px",
    marginBottom: "35px",
    borderRadius: "100% 50% 100% 50%",
  },
});

export default function OwnerUpdateUI() {
  const classes = useStyles();
  const [values, setValues] = useState({
    name: "",
    profilePicture: "",
  });
  const [photo, setPhoto] = useState(null);
  const [photoPreview, setPhotoPreview] = useState(img);
  const [clicked, setClicked] = useState(false);
  const [errors, setErrors] = useState({
    name: { error: false, helper: "" },
  });
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const history = useHistory();
  const owner = useSelector(getModel);

  const [alert, setAlert] = useState({
    open: false,
  });
  function closeAlert() {
    setAlert({ ...alert, open: false });
  }
  function showAlert(text, title = "Ocurrió un error", type = "error") {
    setAlert({
      title,
      text,
      open: true,
      type,
    });
  }

  function titleCase(cadena) {
    const regExpr =
      /(^|[^A-Za-zÁÉÍÓÚÜÑáéíóúüñ])(?:([a-záéíóúüñ])|([A-ZÁÉÍÓÚÜÑ]))|([A-ZÁÉÍÓÚÜÑ]+)/gu;
    return cadena.replace(
      regExpr,
      (m, caracterPrevio, minuscInicial, mayuscInicial, mayuscIntermedias) => {
        const locale = ["es", "gl", "ca", "pt", "en"];
        if (mayuscIntermedias) {
          return mayuscIntermedias.toLocaleLowerCase(locale);
        }
        return (
          caracterPrevio +
          (minuscInicial
            ? minuscInicial.toLocaleUpperCase(locale)
            : mayuscInicial)
        );
      }
    );
  }

  useEffect(async () => {
    if (owner) {
      setValues({
        name: owner.name,
        profilePicture: owner.profilePicture,
      });
    } else {
      history.push("/owner/profile");
    }
  }, []);

  useEffect(() => {
    if (values.name.trim() !== "" && !errors.name.error) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [values]);

  async function handleUpdate(e) {
    e.preventDefault();
    setLoading(true);
    if (photo != null) {
      try {
        await uploadPhoto("owners", localStorage.getItem("id"), photo);
      } catch (err) {
        showAlert("Ocurrió un error al actualizar la foto");
      }
    }
    try {
      const { valid, info } = await UpdateOwnerInfo(
        titleCase(values.name).trim()
      );
      if (valid) {
        await GetOwnerInfo();
      } else {
        showAlert(info?.error?.message || info.message);
      }
      history.push("/owner/profile");
    } catch (error) {
      showAlert("Ocurrió un error al actualizar tu información");
    }
    setLoading(false);
  }

  const handleInputChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    const { valid, info } = validateName(e.target.value);
    setErrors({
      ...errors,
      name: { error: !valid, helper: info },
    });
  };

  async function handlePhoto(e) {
    const file = e.target.files[0];
    const allowedExtensions = /(.jpg|.jpeg|.png)$/i;
    if (!allowedExtensions.exec(file.name)) {
      showAlert("Elige un formato de imagen válido (.jpg, .png, .jpeg)");
    } else if (file?.size / 1024 > MAX_IMAGE_SIZE) {
      showAlert(MAX_IMAGE_SIZE_TEXT);
    } else {
      setClicked(true);
      setPhoto(file);
      setPhotoPreview(URL.createObjectURL(file));
    }
  }

  function selectPhoto() {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = ".png,.jpg,.jpeg";
    fileInput.addEventListener("change", (event) => {
      handlePhoto(event);
    });
    fileInput.click();
    fileInput.remove();
  }

  return (
    <BasicCard>
      <BasicForm onSubmit={handleUpdate}>
        <div className={classes.root}>
          <div className={classes.containerPhoto}>
            <img
              className={classes.ownerPhoto}
              src={
                clicked || values.profilePicture === ""
                  ? photoPreview
                  : values.profilePicture
              }
              alt="photoOwner"
            />
            <button
              className={classes.iconButton}
              type="button"
              onClick={selectPhoto}
            >
              <img src={blueCamera} alt="" />
            </button>
          </div>
          <InputTextOutlined
            roleUser="owner"
            type="text"
            label="Nombre"
            name="name"
            placeholder="Ingresa tu nombre"
            value={values.name}
            onChange={handleInputChange}
            widthField="300px"
            helperText={errors.name.helper}
          />
          <Box sx={{ display: "flex", flexDirection: "row", pt: 1 }}>
            <FlatButton
              title="Cancelar"
              widthBtn="140px"
              roleUser="owner"
              onClick={() => {
                history.push("/owner/profile");
              }}
              loading={loading}
            />
            <FlatButton
              title="Actualizar"
              widthBtn="140px"
              roleUser="owner"
              typeButton="submit"
              isDisabled={disabled}
              loading={loading}
            />
          </Box>
        </div>
        <Alert alert={alert} handleClose={closeAlert} />
      </BasicForm>
    </BasicCard>
  );
}
