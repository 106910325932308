import { RegisterOwnerRepository } from "../Infrastructure/Owner.repository";
import OwnerModel from "../Domain/Owner.model";
// TODO ver dónde deberían ir estas funciones
import {
  confirmPassword,
  validatePassword,
  validateEmail,
  validateName,
} from "../../User/Domain/User.model";

export default async function RegisterOwnerLogic(
  email,
  password,
  passwordRepeat,
  name
) {
  const objectEmail = validateEmail(email);
  if (!objectEmail.valid) return objectEmail;

  const objectPassword = validatePassword(password);
  if (!objectPassword.valid) return objectPassword;

  const objectPasswordRepeat = confirmPassword(password, passwordRepeat);
  if (!objectPasswordRepeat.valid) return objectPasswordRepeat;

  const objectName = validateName(name);
  if (!objectName.valid) return objectName;

  const owner = new OwnerModel("", "owner", email, name, "");
  return RegisterOwnerRepository(owner, password);
}
