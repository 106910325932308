import React from "react";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import Avatar from "@mui/material/Avatar";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import PetsIcon from "@mui/icons-material/Pets";
import greenIcon from "../assets/green_pets.svg";
import blueIcon from "../assets/blue_pets.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "90%",
    maxWidth: 400,
    minHeight: 250,
    height: "auto",
    maxHeight: 300,
    padding: 15,
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.up("lg")]: {
      width: "100%",
    },
  },
  infoContainer: {
    display: "flex",
    justifyContent: "space-around",
    width: "100%",
  },
  colorDefault: ({ role }) => ({
    background:
      role === "vet"
        ? theme.palette.primary.main
        : theme.palette.secondary.main,
  }),
  textInfo: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: 10,
  },
  goProfileContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  // eslint-disable-next-line no-unused-vars
  logo: ({ role }) => ({
    width: 55,
    height: 55,
    transition: "0.4s",
    "&:hover": {
      transform: "scale(1.05)",
    },
  }),
  goProfileLink: ({ role }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 8,
    color:
      role === "vet"
        ? theme.palette.primary.main
        : theme.palette.secondary.main,
    fontSize: "1.2rem",
    fontWeight: 700,
    textDecoration: "none",
    background: "none",
    "&:hover": {
      fontWeight: 900,
      color:
        role === "vet"
          ? theme.palette.primary.dark
          : theme.palette.secondary.dark,
    },
  }),
}));

export default function PetCard({
  id,
  name,
  kind,
  breed = "Sin especificar",
  profileImage,
}) {
  const role = localStorage.getItem("role");
  const classes = useStyles({ role });

  return (
    <Card
      className={classes.root}
      sx={{
        boxShadow:
          "0px 4px 4px rgba(0, 0, 0, 0.25), 4px 0px 5px rgba(0, 0, 0, 0.25)",
        borderRadius: "10px",
      }}
    >
      <div className={classes.infoContainer}>
        <Avatar
          className={classes.colorDefault}
          alt="Foto mascota"
          src={profileImage}
          sx={{
            width: 86,
            height: 86,
            bgcolor: role === "vet" ? "#71E4D1" : "#00B1E0",
          }}
        >
          <PetsIcon />
        </Avatar>
        <div className={classes.textInfo}>
          <h2 style={{ fontWeight: 700, textAlign: "center" }}>{name}</h2>
          <h4
            style={{ fontWeight: 400, textAlign: "center" }}
          >{`${kind} / ${breed}`}</h4>
        </div>
      </div>
      <div className={classes.goProfileContainer}>
        <Link className={classes.goProfileLink} to={`/${role}/pets/menu/${id}`}>
          <img
            className={classes.logo}
            src={role === "vet" ? greenIcon : blueIcon}
            alt="icon pets"
          />
        </Link>
        <Link className={classes.goProfileLink} to={`/${role}/pets/menu/${id}`}>
          Abrir perfil
          <ArrowRightAltIcon />
        </Link>
      </div>
    </Card>
  );
}

PetCard.defaultProps = {
  profileImage: { PetsIcon },
};

PetCard.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  kind: PropTypes.string.isRequired,
  breed: PropTypes.string.isRequired,
  profileImage: PropTypes.string,
};
