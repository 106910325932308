import { RegisterConsultationRepository } from "../Infrastructure/Consultation.repository";
import ConsultationModel from "../Domain/Consultation.model";

export default async function RegisterConsultationLogic(
  vetId,
  petId,
  ownerEmail,
  review,
  diagnosis,
  treatment,
  indications,
  weight,
  temperature,
  nextDate
) {
  const consultation = new ConsultationModel(
    "",
    petId,
    "",
    "",
    "",
    "",
    weight,
    temperature,
    review,
    diagnosis,
    treatment,
    indications,
    nextDate
  );
  return RegisterConsultationRepository(consultation, vetId, petId, ownerEmail);
}
