/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import DateAdapter from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { makeStyles } from "@mui/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const useStyles = makeStyles({
  picker: ({ width }) => ({
    width,
  }),
});

const dateTheme = createTheme({
  palette: {
    primary:
      localStorage.getItem("role") === "vet"
        ? {
            main: "#71E4D1",
            light: "#E3FAF6",
            dark: "#55D7D5",
            contrastText: "#fff",
          }
        : {
            main: "#00B1E0",
            light: "#D7F6F9",
            dark: "#38D3E3",
            contrastText: "#fff",
          },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          marginTop: "20px",
        },
      },
    },
  },
});

export default function DatePicker({
  value,
  onChange,
  width,
  label,
  disableFuture,
  disablePast,
}) {
  const classes = useStyles({ width });
  return (
    <ThemeProvider theme={dateTheme}>
      <LocalizationProvider dateAdapter={DateAdapter}>
        <MobileDatePicker
          label={label}
          value={value}
          inputFormat="DD/MMM/yyyy"
          onChange={onChange}
          disableFuture={disableFuture}
          disablePast={disablePast}
          renderInput={(params) => (
            <TextField className={classes.picker} color="primary" {...params} />
          )}
        />
      </LocalizationProvider>
    </ThemeProvider>
  );
}
DatePicker.defaultProps = {
  width: "300px",
  label: "Fecha",
  disableFuture: false,
  disablePast: false,
};
DatePicker.propTypes = {
  value: PropTypes.instanceOf(Date).isRequired,
  width: PropTypes.string,
  label: PropTypes.string,
  disableFuture: PropTypes.bool,
  disablePast: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};
