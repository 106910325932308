import axios from "axios";
import { url } from "./constants";

function getConfig() {
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: token,
    },
  };
  return config;
}

async function verifyAccountService(body) {
  const config = getConfig();
  const route = `${url}/verification`;
  return axios.post(route, body, config);
}

async function createOne(resource, body) {
  const config = getConfig();
  const route = `${url}/${resource}`;
  return axios.post(route, body, config);
}

async function postOne(resource, body, id, endpoint) {
  const config = getConfig();
  const route = `${url}/${resource}/${id}/${endpoint}`;
  return axios.post(route, body, config);
}

async function postOneConsultation(resource, body, id, petId, email) {
  const config = getConfig();
  const route = `${url}/${resource}/${id}/owners/${email}/pets/${petId}/consultations`;
  return axios.post(route, body, config);
}

async function postOneVaccination(resource, body, id, petId, email) {
  const config = getConfig();
  const route = `${url}/${resource}/${id}/owners/${email}/pets/${petId}/vaccines`;
  return axios.post(route, body, config);
}

async function getOne(resource, id) {
  const config = getConfig();
  const route = `${url}/${resource}/${id}`;
  return axios.get(route, config);
}

async function getOnePet(resource, id, petId) {
  const config = getConfig();
  const route = `${url}/${resource}/${id}/pets/${petId}`;
  return axios.get(route, config);
}

async function getOneHistoryConsultation(resource, id, consultationId) {
  const config = getConfig();
  const route = `${url}/${resource}/${id}/consultations/${consultationId}`;
  return axios.get(route, config);
}

async function getOneConsultation(resource, email, petId, consultationId) {
  const config = getConfig();
  const route = `${url}/${resource}/${email}/pets/${petId}/consultations/${consultationId}`;
  return axios.get(route, config);
}

async function getOneVaccination(resource, email, petId, vaccinationId) {
  const config = getConfig();
  const route = `${url}/${resource}/${email}/pets/${petId}/vaccines/${vaccinationId}`;
  return axios.get(route, config);
}

async function getMany(resource, email, filters, endpoint) {
  const config = getConfig();
  const route = `${url}/${resource}/${email}/${endpoint}${
    filters ? `?${filters}` : ""
  }`;
  return axios.get(route, config);
}

async function getManyConsultation(resource, email, petId, filters) {
  const config = getConfig();
  const route = `${url}/${resource}/${email}/pets/${petId}/consultations${
    filters ? `?${filters}` : ""
  }`;
  return axios.get(route, config);
}

async function getManyVaccinations(resource, email, petId, filters) {
  const config = getConfig();
  const route = `${url}/${resource}/${email}/pets/${petId}/vaccines${
    filters ? `?${filters}` : ""
  }`;
  return axios.get(route, config);
}

async function updateOne(resource, id, body) {
  const config = getConfig();
  const route = `${url}/${resource}/${id}`;
  return axios.put(route, body, config);
}

async function updateOnePet(resource, id, petId, body) {
  const config = getConfig();
  const route = `${url}/${resource}/${id}/pets/${petId}`;
  return axios.put(route, body, config);
}

async function uploadPhoto(resource, id, body) {
  const token = localStorage.getItem("token");
  const route = `${url}/${resource}/${id}/images`;
  const formData = new FormData();
  formData.append("image", body);

  return axios.put(route, formData, {
    headers: {
      Authorization: token,
      "Content-Type": "multipart/form-data",
    },
  });
}

async function deleteOne(resource, id) {
  const config = getConfig();
  const route = `${url}/${resource}/${id}`;
  return axios.delete(route, config);
}

export {
  createOne,
  deleteOne,
  getOne,
  getOnePet,
  getOneConsultation,
  getOneVaccination,
  getOneHistoryConsultation,
  getMany,
  getManyConsultation,
  getManyVaccinations,
  updateOne,
  uploadPhoto,
  postOne,
  postOneConsultation,
  updateOnePet,
  postOneVaccination,
  verifyAccountService,
};
