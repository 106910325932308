import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import FlatButton from "./FlatButton";
import BasicForm from "./BasicForm";
import logo from "../assets/Imagotipo.svg";
import BlueFace from "../assets/blue_sad.svg";
import GreenFace from "../assets/green_sad.svg";

const useStyles = makeStyles((theme) => ({
  boxStyle: ({ roleUser }) => ({
    minWidth: "320px",
    minHeight: "100vh",
    [theme.breakpoints.up("lg")]: {
      display: "grid",
      gridTemplateColumns: "repeat(2, 1fr)",
      paddingTop: "50px",
      justifyItems: "center",
      alignItems: "end",
    },
    background:
      roleUser === "vet"
        ? theme.palette.primary.back
        : theme.palette.secondary.back,
  }),
  logo: {
    width: 110,
    position: "absolute",
    top: 20,
    left: 50,
    [theme.breakpoints.down("lg")]: {
      position: "static",
      maxWidth: 235,
      minWidth: 200,
      minHeight: 158,
      marginBottom: 20,
    },
  },
  div: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  title: {
    marginBottom: 20,
    [theme.breakpoints.up("lg")]: {
      fontSize: "4.5rem",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "3rem",
      textAlign: "center",
    },
  },
  subtitle: {
    fontWeight: 400,
    marginBottom: 40,
    [theme.breakpoints.up("lg")]: {
      fontSize: "2.25rem",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "1.25rem",
      textAlign: "center",
    },
  },
  titleContainer: {
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
    [theme.breakpoints.up("lg")]: {
      paddingTop: "100px",
      justifySelf: "start",
      alignSelf: "start",
      paddingLeft: "50px",
    },
  },
  footerImage: {
    width: "100%",
    [theme.breakpoints.down("lg")]: {
      display: "block",
    },
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
}));

export default function NotFoundPage() {
  const roleUser = localStorage.getItem("role");
  const history = useHistory();
  const classes = useStyles({ roleUser });
  const [divHeight, setDivHeight] = useState("100%");

  const handleResizeImage = () => {
    const screenWidth = window.innerWidth;

    if (screenWidth <= 1280) {
      setDivHeight("100vh");
    } else if (screenWidth > 1280) {
      setDivHeight("100%");
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResizeImage);
    return () => {
      window.removeEventListener("resize", handleResizeImage);
    };
  }, []);

  return (
    <Box className={classes.boxStyle}>
      <Box className={classes.titleContainer}>
        <img className={classes.logo} src={logo} alt="logo Vetech" />
        <Typography variant="h1">Error 404</Typography>
        <Typography variant="subtitle1">Página no encontrada</Typography>
        <FlatButton
          title="Regresar al inicio"
          widthBtn="180px"
          roleUser={roleUser}
          onClick={() => {
            if (roleUser) {
              history.push(`/${roleUser}/home`);
            } else {
              history.push(`/index`);
            }
          }}
        />
      </Box>
      <BasicForm header heightDiv={divHeight} roleUser={roleUser}>
        <>
          {roleUser === "owner" ? (
            <img src={BlueFace} alt="BlueFace" />
          ) : (
            <img src={GreenFace} alt="GreenFace" />
          )}
          <div className={classes.div}>
            <h1 className={classes.title}>Error 404</h1>
            <h3 className={classes.subtitle}>Página no encontrada</h3>
            <FlatButton
              className={classes.btn}
              title="Regresar al inicio"
              widthBtn="180px"
              roleUser={roleUser}
              onClick={() => {
                history.push(`/${roleUser}/home`);
              }}
            />
          </div>
        </>
      </BasicForm>
    </Box>
  );
}
