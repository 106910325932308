import React from "react";
import { useHistory } from "react-router-dom";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import FlatButton from "../../DesignSystem/FlatButton";
import BasicForm from "../../DesignSystem/BasicForm";
import logo from "../../assets/Imagotipo.svg";
import BlueFace from "../../assets/Cat_face_blue.svg";
import GreenFace from "../../assets/Cat_face_green.svg";
import footerImage from "../../assets/footer-image1.png";

const useStyles = makeStyles((theme) => ({
  boxStyle: ({ roleUser }) => ({
    minWidth: "320px",
    minHeight: "100vh",
    [theme.breakpoints.up("lg")]: {
      display: "grid",
      gridTemplateColumns: "repeat(2, 1fr)",
      paddingTop: "50px",
      justifyItems: "center",
      alignItems: "end",
    },
    background:
      roleUser === "vet"
        ? theme.palette.primary.back
        : theme.palette.secondary.back,
  }),
  logo: {
    width: 110,
    position: "absolute",
    top: 20,
    left: 50,
    [theme.breakpoints.down("lg")]: {
      position: "static",
      maxWidth: 235,
      minWidth: 200,
      minHeight: 158,
      marginBottom: 20,
    },
  },
  div: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  title: {
    marginBottom: 20,
    [theme.breakpoints.up("lg")]: {
      fontSize: "4.5rem",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "3rem",
      textAlign: "center",
    },
  },
  subtitle: {
    fontWeight: 400,
    marginBottom: 40,
    [theme.breakpoints.up("lg")]: {
      fontSize: "2.25rem",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "1.25rem",
      textAlign: "center",
    },
  },
  titleContainer: {
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
    [theme.breakpoints.up("lg")]: {
      paddingTop: "100px",
      justifySelf: "start",
      alignSelf: "start",
      paddingLeft: "50px",
    },
  },
  footerImage: {
    width: "100%",
    [theme.breakpoints.down("lg")]: {
      display: "block",
    },
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
}));

export default function SuccessfulUI({ roleUser }) {
  const history = useHistory();
  const classes = useStyles({ roleUser });

  async function handleSubmit(e) {
    e.preventDefault();
  }

  return (
    <Box className={classes.boxStyle}>
      <Box className={classes.titleContainer}>
        <img className={classes.logo} src={logo} alt="logo Vetech" />
        <Typography variant="h1">¡Enhorabuena!</Typography>
        <Typography variant="subtitle1">
          Tu registro se ha concluido exitosamente.
          <br />
          <br />
          En breve recibirás un enlace para la verficación de tu correo que
          estará disponible durante 24 horas, asegúrate de revisar incluso
          dentro de la carpeta spam.
        </Typography>
        <FlatButton
          title="Continuar"
          widthBtn="180px"
          roleUser={roleUser}
          onClick={() => {
            history.push("/login");
          }}
        />
      </Box>
      <BasicForm header roleUser={roleUser} onSubmit={handleSubmit}>
        <>
          {roleUser === "owner" ? (
            <img src={BlueFace} alt="BlueFace" />
          ) : (
            <img src={GreenFace} alt="GreenFace" />
          )}
          <div className={classes.div}>
            <h1 className={classes.title}>¡Enhorabuena!</h1>
            <h3 className={classes.subtitle}>
              En breve recibirás un enlace para la verficación de tu correo que
              estará disponible durante 24 horas, asegúrate de revisar incluso
              dentro de la carpeta spam.
            </h3>
            <FlatButton
              className={classes.btn}
              title="Continuar"
              widthBtn="180px"
              roleUser={roleUser}
              onClick={() => {
                history.push("/login");
              }}
            />
          </div>
        </>
      </BasicForm>
      <img className={classes.footerImage} src={footerImage} alt="mascota" />
    </Box>
  );
}

SuccessfulUI.defaultProps = {
  roleUser: "owner",
};

SuccessfulUI.propTypes = {
  roleUser: PropTypes.string,
};
