import VetModel from "../Domain/Vet.model";
import {
  RegisterVetService,
  DeleteVetService,
  UpdateVetInfoService,
  GetVetInfoService,
  ResetVetPasswordService,
} from "./Vet.service";

export async function DeleteVetRepository(id, password) {
  return DeleteVetService(id, password);
}

export async function GetVetInfoRepository(id) {
  try {
    const { data } = await GetVetInfoService(id);
    return {
      valid: true,
      info: new VetModel(
        data.item.license,
        data.item.address,
        data.item.phone,
        data.item.id,
        "vet",
        data.item.email,
        data.item.name,
        data.item.profile_photo
      ),
    };
  } catch (error) {
    if (error.response)
      return { valid: false, info: error.response.data.error };
    return { valid: false, info: error.message };
  }
}

export async function RegisterVetRepository(vetModel, password) {
  try {
    const { data } = await RegisterVetService(vetModel, password);
    return {
      valid: true,
      info: `El veterinario ${data.item.name} ha sido registrado con éxito`,
    };
  } catch (error) {
    if (error.response)
      return { valid: false, info: error.response.data.error };
    return { valid: false, info: error.message };
  }
}

export async function ResetVetPasswordRepository(id, password) {
  return ResetVetPasswordService(id, password);
}

export async function UpdateVetInfoRepository(
  id,
  address,
  license,
  name,
  phone
) {
  try {
    await UpdateVetInfoService(id, address, license, name, phone);
    return {
      valid: true,
      info: `La información ha sido actualizada con éxito`,
    };
  } catch (error) {
    if (error.response)
      return { valid: false, info: error.response.data.error };
    return { valid: false, info: error.message };
  }
}
