import { LoginRepository } from "../Infrastructure/Auth.repository";

export default async function LoginLogic(email, password) {
  const authTokenModel = await LoginRepository({ email, password });

  const { id, role, token } = authTokenModel;

  localStorage.setItem("token", token);
  localStorage.setItem("id", id);
  localStorage.setItem("role", role);

  return role;
}
