import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { getModel } from "../Infrastructure/Vet.reducer";
import FlatButton from "../../DesignSystem/FlatButton";
import BasicCard from "../../DesignSystem/BasicCard.ui";
import img from "../../assets/paw_finger_green.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "70%",
    gap: "80px",
    alignItems: "center",
    justifyContent: "space-around",
    [theme.breakpoints.between("xs", "lg")]: {
      width: "100%",
      flexDirection: "column",
      gap: 20,
    },
  },
  photo: {
    height: "257px",
    width: "257px",
    objectFit: "cover",
    borderRadius: "100% 50% 100% 50%",
    [theme.breakpoints.down("lg")]: {
      marginTop: 30,
      height: "178px",
      width: "178px",
      marginLeft: "auto",
      marginRight: "auto",
      borderRadius: "100% 50% 100% 50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
  },
  photoContainer: {
    [theme.breakpoints.down("lg")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column-reverse",
    },
  },
  name: {
    fontWeight: 600,
    fontSize: "1.5rem",
    textAlign: "center",
    color: theme.palette.primary.main,
    marginTop: 30,
    marginBottom: 10,
    [theme.breakpoints.down("lg")]: {
      width: "100%",
    },
  },
  infoContainer: {
    [theme.breakpoints.between("xs", "md")]: {
      alignSelf: "stretch",
      paddingLeft: 15,
    },
    [theme.breakpoints.between("md", "lg")]: {
      alignSelf: "stretch",
      paddingLeft: 40,
    },
  },
  title: {
    fontWeight: 400,
    fontSize: "1rem",
    color: theme.palette.primary.main,
    marginTop: 30,
    marginBottom: 10,
  },
  subtitle: {
    color: "#BABABA",
    fontSize: "1.125rem",
  },
}));

export default function VetProfileUI() {
  const history = useHistory();
  const classes = useStyles();
  const vet = useSelector(getModel);

  if (!vet) {
    history.push("/");
  }

  return (
    vet && (
      <BasicCard>
        <>
        <div className={classes.root}>
          <div className={classes.photoContainer}>
            <h3 className={classes.name}>
              Dr(a). <br /> {vet.name}
            </h3>
            <img
              className={classes.photo}
              src={vet.profilePicture === "" ? img : vet.profilePicture}
              alt="photoVet"
            />
          </div>
          <div className={classes.infoContainer}>
            <h4 className={classes.title}>Cédula profesional</h4>
            <p className={classes.subtitle}>{vet.license || "Sin datos"}</p>
            <h4 className={classes.title}>Correo Electrónico</h4>
            <p className={classes.subtitle}>{vet.email}</p>
            <h4 className={classes.title}>Teléfono</h4>
            <p className={classes.subtitle}>{vet.phone || "Sin datos"}</p>
            <h4 className={classes.title}>Dirección</h4>
            <p className={classes.subtitle}>{vet.address || "Sin datos"}</p>
          </div>
        </div>
        <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              marginTop: 3,
              pt: 1,
            }}
          >
            <FlatButton
              title="Atrás"
              widthBtn="120px"
              roleUser="vet"
              onClick={() => {
                history.push("/vet/home");
              }}
            />
            <FlatButton
              title="Editar"
              widthBtn="120px"
              roleUser="vet"
              onClick={() => {
                history.push("/vet/update");
              }}
            />
          </Box>
        </>
      </BasicCard>
    )
  );
}
