class VaccinationModel {
  constructor(
    id,
    name,
    date,
    nextDate,
    dosis,
    weight,
    brand,
    serial,
    effects,
    recommendations
  ) {
    this.id = id;
    this.name = name;
    this.date = date;
    this.nextDate = nextDate;
    this.dosis = dosis;
    this.weight = weight;
    this.brand = brand;
    this.serial = serial;
    this.effects = effects;
    this.recommendations = recommendations;
  }
}

export default VaccinationModel;
