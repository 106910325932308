import { RegisterPetRepository } from "../Infrastructure/Pet.repository";
import PetModel from "../Domain/Pet.model";

export default async function RegisterPetLogic(
  ownerId,
  name,
  kind,
  breed,
  birth,
  allergies,
  neutered,
  gender,
  surgeries
) {
  const pet = new PetModel(
    "",
    name,
    ownerId,
    kind,
    breed,
    birth,
    allergies,
    "",
    neutered,
    gender,
    surgeries
  );
  return RegisterPetRepository(pet, ownerId);
}
