import axios from "axios";
import { url } from "../../constants";

export function LoginService(loginModel) {
  return axios.post(`${url}/login`, loginModel);
}

export function RequestCodeService(email) {
  return axios.post(`${url}/forgotpassword`, { email });
}

export function ResetPasswordService(code, email, password) {
  return axios.put(`${url}/confirmforgotpassword`, {
    code,
    email,
    password,
  });
}
