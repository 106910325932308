import { getMany, updateOnePet, postOne, getOnePet } from "../../Provider.new";

export function GetPetInfoService(id, petId) {
  return getOnePet("owners", id, petId);
}

export function GetPetInfoVetService(id, petId) {
  return getOnePet("vets", id, petId);
}

export function GetPetListOwnerService(email, filters) {
  return getMany("owners", email, filters, "pets");
}

export function GetPetListVetService(id, email) {
  return getMany("pets", id, { email }, "pets");
}

export function RegisterPetService(petModel, ownerId) {
  return postOne("owners", petModel, ownerId, "pets");
}

export function UpdatePetInfoService(
  id,
  petId,
  allergies,
  name,
  neutered,
  surgeries
) {
  const body = { name, allergies, neutered, surgeries };
  return updateOnePet("owners", id, petId, body);
}
