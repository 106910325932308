import store from "../../redux/store";
import { setModel as setOwner } from "../../Owner/Infrastructure/Owner.reducer";
import {
  setModel as setVet,
  setOwnerEmailSearched,
} from "../../Vet/Infrastructure/Vet.reducer";
import { setPetModel } from "../../Pet/Infrastructure/Pet.reducer";

export default function LogoutLogic() {
  localStorage.removeItem("token");
  localStorage.removeItem("id");
  localStorage.removeItem("role");
  localStorage.removeItem("email");

  store.dispatch(setOwner({}));
  store.dispatch(setVet({}));
  store.dispatch(setPetModel({}));
  store.dispatch(setOwnerEmailSearched({}));
}
