import DeleteVetLogic from "../Application/DeleteVet.logic";
import RegisterVetLogic from "../Application/RegisterVet.logic";
import GetVetInfoLogic from "../Application/GetVetInfo.logic";
import UpdateVetInfoLogic from "../Application/UpdateVetInfo.logic";

import store from "../../redux/store";
import { setModel } from "./Vet.reducer";

export async function DeleteVet(password) {
  return DeleteVetLogic(localStorage.getItem("id"), password);
}

export async function GetVetInfo() {
  const { valid, info } = await GetVetInfoLogic(localStorage.getItem("id"));
  localStorage.setItem("email", info.email);
  if (valid) {
    store.dispatch(setModel(info));
  }
  return { valid, info };
}

export async function RegisterVet(
  email,
  password,
  passwordRepeat,
  name,
  license,
  address,
  phone
) {
  return RegisterVetLogic(
    email,
    password,
    passwordRepeat,
    name,
    license,
    address,
    phone
  );
}

export async function UpdateVetInfo(address, license, name, phone) {
  return UpdateVetInfoLogic(
    localStorage.getItem("id"),
    address,
    license,
    name,
    phone
  );
}
