import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { useSelector } from "react-redux";
import { GetPetInfo } from "../Infrastructure/Pet.presenter";
import { getPetModel } from "../Infrastructure/Pet.reducer";
import { getOwnerEmailSearched } from "../../Vet/Infrastructure/Vet.reducer";

import BasicCard from "../../DesignSystem/BasicCard.ui";
import FlatButton from "../../DesignSystem/FlatButton";
import MenuButton from "../../DesignSystem/MenuButton";
import LoadingSpinner from "../../DesignSystem/LoadingSpinner";

import greenPaw from "../../assets/paw_finger_green.svg";
import bluePaw from "../../assets/paw_finger.svg";
import blueVaccine from "../../assets/blue_vaccine.svg";
import blueInfo from "../../assets/blue_info.svg";
import blueCalendar from "../../assets/blue_calendar.svg";
import greenVaccine from "../../assets/green_vaccine.svg";
import greenInfo from "../../assets/green_info.svg";
import greenCalendar from "../../assets/green_calendar.svg";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    width: "100%",
    [theme.breakpoints.up("lg")]: {
      flexDirection: "row",
      height: "495px",
    },
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    [theme.breakpoints.down("lg")]: {
      gap: 30,
    },
    [theme.breakpoints.up("lg")]: {
      gap: 50,
    },
  },
  photoContainer: {
    marginTop: "20px",
    marginBottom: "20px",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  photo: {
    width: "220px",
    height: "220px",
    objectFit: "cover",
    borderRadius: "100% 50% 100% 50%",
  },
  petName: {
    padding: 20,
    [theme.breakpoints.down("lg")]: {
      paddingBottom: 0,
    },
  },
  date: {
    [theme.breakpoints.down("lg")]: {
      paddingBottom: 10,
    },
  },
}));

export default function PetMenu() {
  const role = localStorage.getItem("role");
  const { petId } = useParams();
  const photo = role === "vet" ? greenPaw : bluePaw;
  const history = useHistory();
  const classes = useStyles({ role });
  const pet = useSelector(getPetModel);
  const ownerEmail = useSelector(getOwnerEmailSearched);
  const [loading, setLoading] = useState(true);

  useEffect(async () => {
    if (role === "vet" && !ownerEmail) history.push("/vet/pets/list");
    setLoading(true);
    if (role === "vet") {
      await GetPetInfo(ownerEmail, petId);
    } else {
      await GetPetInfo(localStorage.getItem("email"), petId);
    }
    setLoading(false);
  }, []);

  if (loading) {
    return <LoadingSpinner roleUser={role} />;
  }

  return pet ? (
    <BasicCard>
      <div className={classes.container}>
        <div className={classes.photoContainer}>
          <img
            className={classes.photo}
            src={pet.picture !== "" ? pet.picture : photo}
            alt="Pet"
          />
          <Typography
            className={classes.petName}
            variant="h5"
            color={role === "vet" ? "primary" : "secondary"}
          >
            {pet.name}
          </Typography>
          <Typography className={classes.date} color="gray" variant="subtitle">
            {pet.birth}
          </Typography>
        </div>

        <div className={classes.buttonsContainer}>
          <MenuButton
            title="Información"
            roleUser={role}
            typeButton="button"
            onClick={() => history.push(`/${role}/pets/profile`)}
            srcImg={role === "vet" ? greenInfo : blueInfo}
            altText="Icono información"
          />

          <MenuButton
            title="Vacunas"
            roleUser={role}
            typeButton="button"
            onClick={() => history.push(`/${role}/pets/vaccinations`)}
            srcImg={role === "vet" ? greenVaccine : blueVaccine}
            altText="Icono información"
          />

          <MenuButton
            title="Consultas"
            roleUser={role}
            typeButton="button"
            onClick={() => {
              history.push(`/${role}/pets/consultations`);
            }}
            srcImg={role === "vet" ? greenCalendar : blueCalendar}
            altText="Icono información"
          />

          <FlatButton
            title="Atrás"
            widthBtn="138px"
            roleUser={role}
            onClick={() => {
              history.push(`/${role}/pets/list`);
            }}
          />
        </div>
      </div>
    </BasicCard>
  ) : (
    <></>
  );
}
