/* eslint-disable react/prop-types */
import React from "react";

import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

import NotFoundPage from "./DesignSystem/NotFoundPage.ui";
import Home from "./User/UI/Home.ui";
import LoginUI from "./Auth/UI/Login.ui";
import PasswordResetUI from "./Auth/UI/PasswordReset.ui";
import Index from "./User/UI/Index.ui";
import Register from "./User/UI/Register.ui";
import SuccessfulUI from "./User/UI/RegisterSuccessful.ui";
import ConfirmUser from "./User/UI/ConfirmUser";

import VetRegisterUI from "./Vet/UI/VetRegister.ui";
import OwnerRegisterUI from "./Owner/UI/OwnerRegister.ui";

import VetProfileUI from "./Vet/UI/VetProfile.ui";
import OwnerProfileUI from "./Owner/UI/OwnerProfile.ui";
import VetUpdateUI from "./Vet/UI/VetUpdate.ui";
import OwnerUpdateUI from "./Owner/UI/OwnerUpdate.ui";

import Background from "./DesignSystem/Background.ui";
import Navbar from "./DesignSystem/Navbar.ui";

import PetListUI from "./Pet/UI/PetList.ui";
import PetRegisterUI from "./Pet/UI/PetRegister.ui";
import PetMenuUI from "./Pet/UI/PetMenu.ui";
import PetProfileUI from "./Pet/UI/PetProfile.ui";
import PetUpdateUI from "./Pet/UI/PetUpdate.ui";

import ConsultationRegister from "./Consultation/UI/ConsultationRegister.ui";
import ConsultationPetList from "./Consultation/UI/ConsultationPetList.ui";
import ConsultationInfo from "./Consultation/UI/ConsultationInfo.ui";
import ConsultationInfoByVet from "./Consultation/UI/ConsultationInfoByVet.ui";
import ConsultationHistory from "./Consultation/UI/ConsultationHistory.ui";

import VaccinationRegister from "./Vaccination/UI/VaccinationRegister.ui";
import VaccinationListUI from "./Vaccination/UI/VaccinationList.ui";
import VaccinationInfoUI from "./Vaccination/UI/VaccinationInfo.ui";


function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Redirect to="/index" />
        </Route>

        <PrivateRoute exact path="/index">
          <Index />
        </PrivateRoute>
        <PrivateRoute exact path="/login">
          <LoginUI />
        </PrivateRoute>
        <PrivateRoute exact path="/reset">
          <PasswordResetUI />
        </PrivateRoute>
        <PrivateRoute exact path="/welcome">
          <Register />
        </PrivateRoute>

        <Route exact path="/register/vet">
          <VetRegisterUI />
        </Route>
        <Route exact path="/register/owner">
          <OwnerRegisterUI />
        </Route>

        <Route exact path="/successful/vet">
          <SuccessfulUI header roleUser="vet" />
        </Route>
        <Route exact path="/successful/owner">
          <SuccessfulUI header roleUser="owner" />
        </Route>
        <Route exact path="/confirmUser">
          <ConfirmUser />
        </Route>

        <PrivateVetRoute exact path="/vet/home">
          <Home />
        </PrivateVetRoute>
        <PrivateOwnerRoute exact path="/owner/home">
          <Home />
        </PrivateOwnerRoute>

        <PrivateVetRoute exact path="/vet/profile">
          <VetProfileUI />
        </PrivateVetRoute>
        <PrivateOwnerRoute exact path="/owner/profile">
          <OwnerProfileUI />
        </PrivateOwnerRoute>

        <PrivateVetRoute exact path="/vet/update">
          <VetUpdateUI />
        </PrivateVetRoute>
        <PrivateOwnerRoute exact path="/owner/update">
          <OwnerUpdateUI />
        </PrivateOwnerRoute>

        <PrivateOwnerRoute exact path="/owner/pets/list">
          <PetListUI />
        </PrivateOwnerRoute>
        <PrivateOwnerRoute exact path="/owner/pets/register">
          <PetRegisterUI />
        </PrivateOwnerRoute>
        <PrivateOwnerRoute exact path="/owner/pets/menu/:petId">
          <PetMenuUI />
        </PrivateOwnerRoute>
        <PrivateOwnerRoute exact path="/owner/pets/profile">
          <PetProfileUI />
        </PrivateOwnerRoute>
        <PrivateOwnerRoute exact path="/owner/pets/update">
          <PetUpdateUI />
        </PrivateOwnerRoute>
        <PrivateOwnerRoute exact path="/owner/pets/consultations">
          <ConsultationPetList />
        </PrivateOwnerRoute>
        <PrivateOwnerRoute exact path="/owner/pets/consultation/:id">
          <ConsultationInfo />
        </PrivateOwnerRoute>
        <PrivateOwnerRoute exact path="/owner/pets/vaccinations">
          <VaccinationListUI />
        </PrivateOwnerRoute>
        <PrivateOwnerRoute exact path="/owner/pets/vaccination/:id">
          <VaccinationInfoUI />
        </PrivateOwnerRoute>

        <PrivateVetRoute exact path="/vet/pets/list">
          <PetListUI />
        </PrivateVetRoute>
        <PrivateVetRoute exact path="/vet/pets/menu/:petId">
          <PetMenuUI />
        </PrivateVetRoute>
        <PrivateVetRoute exact path="/vet/pets/profile">
          <PetProfileUI />
        </PrivateVetRoute>
        <PrivateVetRoute exact path="/vet/pets/consultations">
          <ConsultationPetList />
        </PrivateVetRoute>
        <PrivateVetRoute exact path="/vet/pets/consultations/register">
          <ConsultationRegister />
        </PrivateVetRoute>
        <PrivateVetRoute exact path="/vet/pets/consultation/:id">
          <ConsultationInfo />
        </PrivateVetRoute>
        <PrivateVetRoute exact path="/vet/pets/vetconsultation/:id">
          <ConsultationInfoByVet />
        </PrivateVetRoute>
        <PrivateVetRoute exact path="/vet/consultations">
          <ConsultationHistory />
        </PrivateVetRoute>
        <PrivateVetRoute exact path="/vet/pets/vaccinations">
          <VaccinationListUI />
        </PrivateVetRoute>
        <PrivateVetRoute exact path="/vet/pets/vaccinations/register">
          <VaccinationRegister />
        </PrivateVetRoute>
        <PrivateVetRoute exact path="/vet/pets/vaccination/:id">
          <VaccinationInfoUI />
        </PrivateVetRoute>
        <Route component={NotFoundPage} />
      </Switch>
    </Router>
  );
}

export default App;

export function PrivateVetRoute({ children, ...rest }) {
  return (
    <Route
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      render={({ location }) =>
        localStorage.getItem("role") === "vet" ? (
          <Background>
            <>
              <Navbar />
              {children}
            </>
          </Background>
        ) : (
          <Redirect
            to={{
              pathname: localStorage.getItem("token")
                ? "/owner/home"
                : "/index",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export function PrivateOwnerRoute({ children, ...rest }) {
  return (
    <Route
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      render={({ location }) =>
        localStorage.getItem("role") === "owner" ? (
          <Background>
            <>
              <Navbar />
              {children}
            </>
          </Background>
        ) : (
          <Redirect
            to={{
              pathname: localStorage.getItem("token") ? "/vet/home" : "/index",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export function PrivateRoute({ children }) {
  return (
    <Route
      render={({ location }) =>
        !localStorage.getItem("token") ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: `/${localStorage.getItem("role")}/home`,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
