import RegisterPetLogic from "../Application/RegisterPet.logic";
import GetPetInfoLogic from "../Application/GetPetInfo.logic";
import { GetPetListOwnerLogic } from "../Application/GetPetList.logic";
import UpdatePetInfoLogic from "../Application/UpdatePetInfo.logic";
import store from "../../redux/store";
import { setPetModel } from "./Pet.reducer";
import { setOwnerEmailSearched } from "../../Vet/Infrastructure/Vet.reducer";

export async function GetPetInfo(email, petId) {
  const { valid, info } = await GetPetInfoLogic(email, petId);
  if (valid) {
    store.dispatch(setPetModel(info));
  }
  return { valid, info };
}

export async function GetPetListOwner(email, page = 1) {
  const {valid, info} = await GetPetListOwnerLogic(email, page);
  if (valid) {
    store.dispatch(setOwnerEmailSearched(email));
  }
  return {valid, info};
}

export async function RegisterPet(
  name,
  kind,
  breed,
  birth,
  allergies,
  neutered,
  gender,
  surgeries
) {
  return RegisterPetLogic(
    localStorage.getItem("id"),
    name,
    kind,
    breed,
    birth,
    allergies,
    neutered,
    gender,
    surgeries
  );
}

export async function UpdatePetInfo(
  petId,
  allergies,
  name,
  neutered,
  surgeries
) {
  return UpdatePetInfoLogic(
    localStorage.getItem("email"),
    petId,
    allergies,
    name,
    neutered,
    surgeries
  );
}
