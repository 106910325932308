import moment from "moment";
import {
  RegisterVaccinationService,
  GetVaccinationInfoService,
  GetVaccinationListService,
} from "./Vaccination.service";

export async function RegisterVaccinationRepository(
  vaccinationModel,
  vetId,
  petId,
  ownerEmail
) {
  try {
    const { data } = await RegisterVaccinationService(
      vaccinationModel,
      vetId,
      petId,
      ownerEmail
    );
    return {
      valid: true,
      info: {
        message: `La vacuna ha sido registrada de manera exitosa`,
        id: data.item.id,
      },
    };
  } catch (error) {
    if (error.response)
      return { valid: false, info: error.response.data.error };
    return { valid: false, info: error.message };
  }
}

export async function GetVaccinationListRepository(email, petId, filters) {
  try {
    const { data } = await GetVaccinationListService(email, petId, filters);
    if (data.items) {
      const cleanData = data.items.map((item) => {
        const newDate = moment(new Date(item.date)).format("DD/MM/YYYY");
        return { ...item, date: newDate };
      });
      data.items = cleanData;
    }
    return {
      valid: true,
      info: data,
    };
  } catch (error) {
    if (error.response)
      return { valid: false, info: error.response.data.error };
    return { valid: false, info: error.message };
  }
}

export async function GetVaccinationInfoRepository(ownerEmail, petId, vaccinationId) {
  try {
    const { data } = await GetVaccinationInfoService(ownerEmail, petId, vaccinationId);
    const format = "DD/MM/YYYY";
    data.item.date = moment(new Date(data.item.date)).format(format);
    data.item.next_date = moment(new Date(data.item.next_date)).format(
      format
    );
    return {
      valid: true,
      info: data.item,
    };
  } catch (error) {
    if (error.response)
      return { valid: false, info: error.response.data.error };
    return { valid: false, info: error.message };
  }
}
