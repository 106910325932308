import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: ({ heightDiv }) => ({
    display: "flex",
    flexDirection: "column",
    background: "#fff",
    height: heightDiv,
    [theme.breakpoints.down("lg")]: {
      minWidth: "100%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "420px",
    },
  }),
  header: ({ roleUser }) => ({
    height: "100px",
    transform: "translateY(-1px)",
    clipPath: "polygon(20% 100%, 80% 100%, 100% 0, 0 0)",
    background:
      roleUser === "vet"
        ? theme.palette.primary.back
        : theme.palette.secondary.back,
  }),
  root: {
    alignSelf: "center",
    width: "320px",
    [theme.breakpoints.up("md")]: {
      width: "420px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "320px",
    },
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    background: "#fff",
    paddingBottom: "20px",
  },
}));

export default function BasicForm({
  onSubmit,
  children,
  header,
  roleUser,
  heightDiv,
}) {
  const classes = useStyles({ roleUser, heightDiv });

  if (header) {
    return (
      <div className={classes.container}>
        <div className={classes.header} />
        <form className={classes.root} onSubmit={onSubmit}>
          {children}
        </form>
      </div>
    );
  }
  return (
    <form className={classes.root} onSubmit={onSubmit}>
      {children}
    </form>
  );
}

BasicForm.defaultProps = {
  header: false,
  roleUser: "owner",
  heightDiv: "90vh",
};

BasicForm.propTypes = {
  roleUser: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  header: PropTypes.bool,
  heightDiv: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
