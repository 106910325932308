import React from "react";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  select: ({ selectWidth }) => ({
    width: selectWidth,
  }),
});

export default function BasicSelect({
  label,
  selectWidth,
  value,
  options,
  handleChange,
  name,
}) {
  const role = localStorage.getItem("role");
  const classes = useStyles({ selectWidth, role });
  return (
    <div>
      <FormControl style={{ marginTop: "20px" }}>
        <InputLabel
          color={role === "vet" ? "primary" : "secondary"}
          id="select"
        >
          {label}
        </InputLabel>
        <Select
          color={role === "vet" ? "primary" : "secondary"}
          labelId="select"
          label={label}
          value={value}
          name={name}
          onChange={handleChange}
          className={classes.select}
        >
          {options.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

BasicSelect.defaultProps = {
  selectWidth: "300px",
};

BasicSelect.propTypes = {
  label: PropTypes.string.isRequired,
  selectWidth: PropTypes.string,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(Object).isRequired,
};
