import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import Pagination from "@mui/material/Pagination";
import { GetConsultationHistory } from "../Infrastructure/Consultation.presenter";
import ConsultationCard from "../../DesignSystem/ConsultationCard";
import LoadingSpinner from "../../DesignSystem/LoadingSpinner";
import sadFace from "../../assets/green_sad.svg";
import greenSearchIcon from "../../assets/greenSearchIcon.svg";
import MenuButton from "../../DesignSystem/MenuButton";

const useStyles = makeStyles((theme) => ({
  consultationListContainer: {
    display: "grid",
    placeItems: "center",
    gap: 35,
    width: "100%",
    height: "auto",
    paddingLeft: 24,
    paddingRight: 24,
    [theme.breakpoints.down("md")]: {
      paddingTop: 30,
      paddingBottom: 30,
      gridTemplateColumns: "1fr",
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: 50,
      paddingBottom: 50,
      gridTemplateColumns: "1fr 1fr",
    },
    [theme.breakpoints.up("lg")]: {
      paddingTop: 30,
      paddingBottom: 30,
      gridTemplateColumns: "repeat(3, 1fr)",
    },
  },
  headerContainer: {
    color: theme.palette.primary.main,
    fontSize: "1.5rem",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      gridColumn: "1/-1",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      gap: 15,
    },
    [theme.breakpoints.up("md")]: {
      gridColumn: "1/-1",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      fontSize: "2.25rem",
      padding: "0 50px",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "0 24px",
    },
  },
  pagination: {
    gridColumn: "1/-1",
    display: "flex",
    justifyContent: "center",
    paddingBottom: 10,
  },
  btnStyles: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 15,
    background: "#fff",
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 30,
    width: 275,
    height: 50,
    color: theme.palette.primary.main,
    fontWeight: 500,
    fontSize: "1.25rem",
    "&:hover": {
      cursor: "pointer",
      background: theme.palette.primary.light,
    },
  },
  emptyConsultations: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: 40,
    padding: 10,
  },
  h3Style: {
    textAlign: "center",
    color: theme.palette.primary.main,
    fontSize: "2.3rem",
  },
  paragraph: {
    color: theme.palette.grey[100],
  },
}));

export default function ConsultationHistory() {
  const classes = useStyles();
  const [consultations, setConsultations] = useState({});
  const [page, setPage] = useState(1);
  const history = useHistory();

  useEffect(async () => {
    const { valid, info } = await GetConsultationHistory();
    if (valid) {
      setConsultations(info);
    } else {
      setConsultations({ items: null });
    }
    setPage(1);
  }, []);

  const handlePaginationChange = async (event, numberPage) => {
    const { info } = await GetConsultationHistory(numberPage);
    setConsultations(info);
    setPage(numberPage);
  };

  if (consultations.items === null) {
    return (
      <div className={classes.emptyConsultations}>
        <img src={sadFace} alt="sad cat face" />
        <div className={classes.emptyConsultations}>
          <h3 className={classes.h3Style}>
            Aún no has consultado ninguna mascota
          </h3>
          <p>
            Para agregar consultas primero busca a un usuario, selecciona una
            mascota y agrega una consulta.
          </p>
          <MenuButton
            title="Buscar"
            roleUser="vet"
            typeButton="button"
            onClick={() => history.push("/vet/pets/list")}
            srcImg={greenSearchIcon}
            altText="Icon search"
          />
          <p className={classes.paragraph}>
            Recuerda que para crear consultas necesitas una cédula profesional
          </p>
        </div>
      </div>
    );
  }

  if (Object.entries(consultations).length === 0) {
    return <LoadingSpinner roleUser="vet" />;
  }

  return (
    <div style={{ width: "100%", minHeight: "100vh", paddingTop: 40 }}>
      <div className={classes.headerContainer}>
        <h3 className={classes.title}>Historial de consultas</h3>
      </div>
      <div className={classes.consultationListContainer}>
        {consultations.items.map((consultation) => (
          <ConsultationCard
            id={consultation.id}
            key={consultation.id}
            petId={consultation.pet.id}
            ownerEmail={consultation.owner.email}
            date={consultation.date}
            petName={consultation.pet.name}
            petOwnerName={consultation.owner.name}
            review={consultation.review}
            history
          />
        ))}
        <Pagination
          className={classes.pagination}
          color="primary"
          count={consultations.total_pages}
          page={page}
          onChange={handlePaginationChange}
        />
      </div>
    </div>
  );
}
