import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Login } from "../Infrastructure/Auth.presenter";
import InputTextOutlined from "../../DesignSystem/InputTextOutlined";
import FlatButton from "../../DesignSystem/FlatButton";
import BasicForm from "../../DesignSystem/BasicForm";
import petImage from "../../assets/catDog.png";
import FooterImage from "../../DesignSystem/FooterImage";
import footerImage from "../../assets/footer-image1.png";
import adorableBulldog from "../../assets/bulldogWhite.png";
import Alert from "../../DesignSystem/BasicModal";

const useStyles = makeStyles((theme) => ({
  boxStyle: {
    minWidth: "320px",
    minHeight: "100vh",
    [theme.breakpoints.up("lg")]: {
      backgroundColor: theme.palette.secondary.back,
      display: "grid",
      gridTemplateColumns: "repeat(2, 1fr)",
      paddingTop: "65px",
      justifyItems: "center",
      alignItems: "end",
    },
  },
  titleContainer: {
    alignSelf: "start",
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
    [theme.breakpoints.up("lg")]: {
      paddingTop: "90px",
      justifySelf: "start",
      paddingLeft: "50px",
      width: "700px",
    },
  },
  divider: {
    width: "260px",
    border: "none",
    height: "1px",
    background: theme.palette.secondary.dark,
  },
  loginImage: {
    maxWidth: 275,
    justifySelf: "center",
    alignSelf: "center",
    borderRadius: "100% 50% 100% 50%",
    marginLeft: "100px",
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
  },
  footer: {
    [theme.breakpoints.down("md")]: {
      height: "100%",
      clipPath: "polygon(50% 0%, 100% 38%, 100% 100%, 0 100%, 0% 38%)",
      backgroundRepeat: "no-repeat",
    },
    [theme.breakpoints.up("md")]: {
      height: "100%",
      clipPath: "polygon(50% 0%, 100% 38%, 100% 100%, 0 100%, 0% 38%)",
      backgroundRepeat: "no-repeat",
    },
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  link: {
    color: theme.palette.secondary.main,
    fontWeight: 400,
    textDecoration: "none",
    background: "none",
    "&:hover": {
      color: theme.palette.secondary.dark,
    },
  },
}));

export default function LoginUI() {
  const [values, setValues] = useState({ email: "", password: "" });
  const [loading, setLoading] = useState(false);
  const [loginDisabled, setLoginDisabled] = useState(true);
  const [breakpoint, setBreakpoint] = React.useState(
    window.innerWidth <= 425 ? footerImage : adorableBulldog
  );

  const history = useHistory();
  const classes = useStyles();

  const handleResizeImage = () => {
    const screenWidth = window.innerWidth;

    if (screenWidth <= 425) {
      setBreakpoint(footerImage);
    } else if (screenWidth >= 768) {
      setBreakpoint(adorableBulldog);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResizeImage);
    window.addEventListener("load", handleResizeImage);
    return () => {
      window.removeEventListener("resize", handleResizeImage);
      window.addEventListener("load", handleResizeImage);
    };
  }, []);

  useEffect(() => {
    if (values.email !== "" && values.password !== "") {
      setLoginDisabled(false);
    } else {
      setLoginDisabled(true);
    }
  }, [values]);

  const [alert, setAlert] = useState({
    open: false,
  });
  function closeAlert() {
    setAlert({ ...alert, open: false });
  }
  function showAlert(text, title = "Ocurrió un error", type = "error") {
    setAlert({
      title,
      text,
      open: true,
      type,
    });
  }

  async function handleLogin(e) {
    e.preventDefault();
    setLoading(true);
    try {
      const role = await Login(values.email, values.password);
      if (role === "vet") {
        history.push("/vet/home");
      } else if (role === "owner") {
        history.push("/owner/home");
      }
    } catch (error) {
      showAlert(error.response.data.error.message);
      setLoading(false);
    }
  }

  const handleInputChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  return (
    <Box className={classes.boxStyle}>
      <Box className={classes.titleContainer}>
        <Typography variant="h1">Bienvenido</Typography>
        <Typography variant="subtitle1">
          El mejor servicio para tu mascota te espera.
        </Typography>
        <img className={classes.loginImage} src={petImage} alt="mascota" />
      </Box>
      <BasicForm header onSubmit={handleLogin}>
        <>
          <Typography variant="h5" color="secondary">
            Inicia sesión
          </Typography>

          <InputTextOutlined
            roleUser="owner"
            type="text"
            label="Correo electrónico"
            name="email"
            placeholder="Ingresa tu correro electrónico"
            value={values.email}
            onChange={handleInputChange}
          />

          <InputTextOutlined
            roleUser="owner"
            type="password"
            label="Contraseña"
            name="password"
            placeholder="Ingresa tu contraseña"
            value={values.password}
            onChange={handleInputChange}
          />

          <FlatButton
            title="Ingresa"
            loading={loading}
            widthBtn="180px"
            typeButton="submit"
            isDisabled={loginDisabled}
          />
          <Link className={classes.link} to="/reset">
            ¿Olvidaste tu contraseña?
          </Link>
          <hr className={classes.divider} />
          <Typography variant="subtitle2" color="gray">
            ¿Eres un usuario nuevo?
          </Typography>

          <FlatButton
            title="Regístrate"
            widthBtn="180px"
            onClick={() => {
              history.push("/welcome");
            }}
          />
        </>
      </BasicForm>
      <div className={classes.footer}>
        <FooterImage fimage={breakpoint} />
      </div>
      <Alert alert={alert} handleClose={closeAlert} />
    </Box>
  );
}
