import AuthTokenModel from "../Domain/AuthToken.model";
import LoginModel from "../Domain/Login.model";
import {
  LoginService,
  ResetPasswordService,
  RequestCodeService,
} from "./Auth.service";

export async function LoginRepository(credentials) {
  const loginModel = new LoginModel(credentials);

  const { data } = await LoginService(loginModel);

  return new AuthTokenModel(data.item);
}

export function LogoutRepository() {}

export async function ResetPasswordRepository(code, email, password) {
  try {
    const { data } = await ResetPasswordService(code, email, password);
    return {
      valid: true,
      info: data,
    };
  } catch (error) {
    if (error.response)
      return { valid: false, info: error.response.data.error };
    return { valid: false, info: error.message };
  }
}

export async function RequestCodeRepository(email) {
  try {
    const { data } = await RequestCodeService(email);
    return {
      valid: true,
      info: data,
    };
  } catch (error) {
    if (error.response)
      return { valid: false, info: error.response.data.error };
    return { valid: false, info: error.message };
  }
}
