import moment from "moment";
import {
  GetConsultationInfoFromHistoryService,
  GetConsultationInfoService,
  GetConsultationListService,
  GetConsultationHistoryService,
  RegisterConsultationService,
} from "./Consultation.service";

export async function GetConsultationInfoFromHistoryRepository(
  id,
  consultationId
) {
  try {
    const { data } = await GetConsultationInfoFromHistoryService(
      id,
      consultationId
    );
    const format = "DD/MM/YYYY";
    data.item[0].date = moment(new Date(data.item[0].date)).format(format);
    data.item[0].pet.birth = moment(new Date(data.item[0].pet.birth)).format(
      format
    );
    data.item[0].next_date = moment(new Date(data.item[0].next_date)).format(
      format
    );
    return {
      valid: true,
      info: data.item[0],
    };
  } catch (error) {
    if (error.response)
      return { valid: false, info: error.response.data.error };
    return { valid: false, info: error.message };
  }
}
export async function GetConsultationInfoRepository(petId, consultationId) {
  try {
    const { data } = await GetConsultationInfoService(petId, consultationId);
    const format = "DD/MM/YYYY";
    data.item[0].date = moment(new Date(data.item[0].date)).format(format);
    data.item[0].pet.birth = moment(new Date(data.item[0].pet.birth)).format(
      format
    );
    data.item[0].next_date = moment(new Date(data.item[0].next_date)).format(
      format
    );
    return {
      valid: true,
      info: data.item[0],
    };
  } catch (error) {
    if (error.response)
      return { valid: false, info: error.response.data.error };
    return { valid: false, info: error.message };
  }
}

export async function GetConsultationListRepository(email, petId, filters) {
  try {
    const { data } = await GetConsultationListService(email, petId, filters);
    if (data.items) {
      const cleanData = data.items.map((item) => {
        const newDate = moment(new Date(item.date)).format("DD/MM/YYYY");
        return { ...item, date: newDate };
      });
      data.items = cleanData;
    }
    return {
      valid: true,
      info: data,
    };
  } catch (error) {
    if (error.response)
      return { valid: false, info: error.response.data.error };
    return { valid: false, info: error.message };
  }
}

export async function RegisterConsultationRepository(
  consultationModel,
  vetId,
  petId,
  ownerEmail
) {
  try {
    const { data } = await RegisterConsultationService(
      consultationModel,
      vetId,
      petId,
      ownerEmail
    );
    return {
      valid: true,
      info: {
        message: `La consulta médica ha sido registrada de manera exitosa`,
        id: data.item.id,
      },
    };
  } catch (error) {
    if (error.response)
      return { valid: false, info: error.response.data.error };
    return { valid: false, info: error.message };
  }
}

export async function GetConsultationHistoryRepository(id, filters) {
  try {
    const { data } = await GetConsultationHistoryService(id, filters);
    if (data.items) {
      const cleanData = data.items.map((item) => {
        const newDate = moment(new Date(item.date)).format("DD/MM/YYYY");
        return { ...item, date: newDate };
      });
      data.items = cleanData;
    }

    return {
      valid: true,
      info: data,
    };
  } catch (error) {
    if (error.response)
      return { valid: false, info: error.response.data.error };
    return { valid: false, info: error.message };
  }
}
