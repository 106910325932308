import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { useSelector } from "react-redux";
import BasicForm from "../../DesignSystem/BasicForm";
import FlatButton from "../../DesignSystem/FlatButton";
import BasicCard from "../../DesignSystem/BasicCard.ui";
import InputTextOutlined from "../../DesignSystem/InputTextOutlined";
import DatePicker from "../../DesignSystem/DatePicker.ui";
import BasicSelect from "../../DesignSystem/BasicSelect.ui";

import { RegisterVaccination } from "../Infrastructure/Vaccination.presenter";
import { getOwnerEmailSearched } from "../../Vet/Infrastructure/Vet.reducer";
import { getPetModel } from "../../Pet/Infrastructure/Pet.reducer";
import Alert from "../../DesignSystem/BasicModal";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    [theme.breakpoints.up("lg")]: {
      flexDirection: "row",
      gap: 30,
    },
  },
  container: {
    width: "320px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.up("lg")]: {
      paddingBottom: "20px",
    },
  },
  flex: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "300px",
  },
}));

function VaccinationRegister() {
  const [values, setValues] = useState({
    nextDate: new Date(),
    nextDateSelect: "No",
    name: "",
    dosis: "1 de 1",
    weight: "",
    brand: "",
    serial: "",
    effects: "",
    recommendations: "",
  });
  const [nextDate, setNextDate] = useState({
    size: "300px",
    label: "¿Se requiere una siguiente fecha?",
    visible: false,
  });
  const [registerDisabled, setRegisterDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const classes = useStyles();
  const ownerEmail = useSelector(getOwnerEmailSearched);
  const pet = useSelector(getPetModel);

  const [alert, setAlert] = useState({
    open: false,
  });
  function closeAlert() {
    setAlert({ ...alert, open: false });
    if (alert.type === "success") {
      history.push(`/vet/pets/menu/${pet.id}`);
    }
  }
  function showAlert(text, title = "Ocurrió un eror", type = "error") {
    setAlert({
      title,
      text,
      open: true,
      type,
    });
  }

  if (!ownerEmail || !pet) {
    history.push("/vet/pets/list");
  }

  async function handleRegister(e) {
    e.preventDefault();
    setLoading(true);
    try {
      const { valid, info } = await RegisterVaccination(
        pet.id,
        ownerEmail,
        values.name,
        values.dosis,
        values.weight,
        values.brand,
        values.serial,
        values.effects,
        values.recommendations,
        values.nextDateSelect === "No"
          ? ""
          : values.nextDate.toISOString().slice(0, -1)
      );
      if (valid) {
        showAlert(info.message, "Vacuna creada", "success");
      } else {
        showAlert(info);
      }
    } catch (error) {
      showAlert(error);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (values.name !== "" && values.weight !== "") {
      setRegisterDisabled(false);
    } else {
      setRegisterDisabled(true);
    }
    if (values.nextDateSelect === "Sí") {
      setNextDate({ size: "120px", label: "Siguiente vacuna", visible: true });
    } else {
      setNextDate({
        size: "300px",
        label: "¿Se requiere una siguiente fecha?",
        visible: false,
      });
    }
  }, [values]);

  const handleInputChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleDateChange = (e) => {
    // eslint-disable-next-line no-underscore-dangle
    setValues({ ...values, nextDate: e._d });
  };

  return (
    <BasicCard>
      <>
        <Typography
          style={{ textAlign: "center", marginBottom: "20px" }}
          variant="h5"
          color="primary"
        >
          Nueva vacuna
        </Typography>
        <BasicForm onSubmit={handleRegister}>
          <>
            <div className={classes.mainContainer}>
              <div className={classes.container}>
                <InputTextOutlined
                  roleUser="vet"
                  type="text"
                  label="*Nombre de la vacuna"
                  name="name"
                  placeholder="Escribe el nombre de la vacuna"
                  value={values.name}
                  onChange={handleInputChange}
                  widthField="300px"
                />
                <InputTextOutlined
                  roleUser="vet"
                  type="number"
                  label="*Peso de la mascota"
                  name="weight"
                  placeholder="0.0kg"
                  value={values.weight}
                  onChange={handleInputChange}
                  widthField="300px"
                />
                <BasicSelect
                  label="Dosis"
                  selectWidth="300px"
                  name="dosis"
                  value={values.dosis}
                  handleChange={handleInputChange}
                  options={["1 de 1", "1 de 2", "2 de 2"]}
                />
                <InputTextOutlined
                  roleUser="vet"
                  type="text"
                  label="Recomendaciones"
                  name="recommendations"
                  placeholder="Escribe las recomendaciones"
                  value={values.recommendations}
                  onChange={handleInputChange}
                  widthField="300px"
                  multiline
                />
              </div>
              <div className={classes.container}>
                <InputTextOutlined
                  roleUser="vet"
                  type="text"
                  label="Marca"
                  name="brand"
                  placeholder="Escribe la marca de la vacuna"
                  value={values.brand}
                  onChange={handleInputChange}
                  widthField="300px"
                />
                <InputTextOutlined
                  roleUser="vet"
                  type="text"
                  label="Serie"
                  name="serial"
                  placeholder="Escribe el número de serie"
                  value={values.serial}
                  onChange={handleInputChange}
                  widthField="300px"
                />
                <div className={classes.flex}>
                  <BasicSelect
                    label={nextDate.label}
                    selectWidth={nextDate.size}
                    name="nextDateSelect"
                    value={values.nextDateSelect}
                    handleChange={handleInputChange}
                    options={["No", "Sí"]}
                  />
                  <div
                    style={{
                      display: nextDate.visible ? "block" : "none",
                    }}
                  >
                    <DatePicker
                      value={values.nextDate}
                      onChange={handleDateChange}
                      width="160px"
                      label="Fecha vacuna"
                      disablePast
                    />
                  </div>
                </div>
                <InputTextOutlined
                  roleUser="vet"
                  type="text"
                  label="Efectos secundarios"
                  name="effects"
                  placeholder="Escribe los posibles efectos secundarios"
                  value={values.effects}
                  onChange={handleInputChange}
                  widthField="300px"
                  multiline
                />
              </div>
            </div>
            <div className={classes.flex}>
              <FlatButton
                title="Cancelar"
                widthBtn="138px"
                roleUser="vet"
                loading={loading}
                onClick={() => history.push("/vet/pets/vaccinations")}
              />
              <FlatButton
                title="Guardar"
                widthBtn="138px"
                isDisabled={registerDisabled}
                loading={loading}
                roleUser="vet"
                typeButton="submit"
              />
            </div>
          </>
        </BasicForm>
        <Alert alert={alert} handleClose={closeAlert} />
      </>
    </BasicCard>
  );
}
export default VaccinationRegister;
